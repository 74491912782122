import Postcode from 'postcode';
import moment from 'moment';
import momentTz from 'moment-timezone';
import queryString from 'query-string';
import {Base64} from 'js-base64';

import {
  ADYEN_ENV,
  AX_ERRORS,
  BRANCH_MAPPING,
  BRANCH_NAME_MAPPING,
  DATE_FORMAT,
  DEFAULT_BRANCH,
  FILTERS_NAME,
  FORM_ERRORS,
  FULFILLMENT_SUCCESS_FORMAT,
  H_MM_A_FORMAT,
  HTTPS_REGEX,
  JJ_DOMAIN,
  INVALID_SEARCH_SUGGESTION_KEYWORD,
  JJ_LOCAL_STORAGE,
  LONG_CATEGORY_LIST_FILTER_LENGTH,
  MISSING_ITEM,
  ORDER_HISTORY_OUT_DATE_FORMAT,
  ORDER_SERVICE_DATE_FORMAT,
  ORDER_TRACKING,
  PAYMENT_TYPES,
  PROMO_TAGS_MAPPING,
  REGISTER_TEL_REGEX,
  SEARCH_SORT_TYPE,
  SEARCH_SUGGESTION_MAX_LENGTH,
  SEARCH_TITLE,
  SERVICE_TYPE,
  SETTINGS,
  SLOT_DATE_FORMAT,
  SLOT_FULFILMENT_STATUSES,
  STANDARD_FORMAT,
  STOCK_CHECK,
  TIME_ZONE,
  URL,
  STRAPI_CMS_URL,
} from '../constants/constants';
import {
  getDataFromLocalStorage,
  persistDataToLocalStorage,
} from './localStorage.helper';
import {reduceGroupImageSize, reduceImageSize} from './image.helper';
import {
  SEARCH_SUGGESTIONS_DEFAULT_LINKS,
  SEARCH_SUGGESTIONS_DEFAULT_KEYWORDS,
  SEARCH_SUGGESTIONS_DEFAULT_SUGGESTIONS,
  SAVE_TO_SETTINGS_NUMBER,
} from '../constants/searchSuggestions.constants';
import {isBasketSynced, isServiceItem} from './basket.helper';
import {captureException} from '@sentry/browser';
import {
  CATEGORY_DESCRIPTION,
  OWN_BRAND_DESCRIPTION,
} from '../constants/category.constants';
import {YOUTUBE_URL} from '../constants/wix.constants';
import {getPriceMulti} from './search.helper';

const getSelectedCategoryName = categories => {
  if (
    categories[0] &&
    !categories[0].selected &&
    categories[0].counts &&
    categories[0].counts.length > 0
  ) {
    return getSelectedCategoryName(categories[0].counts);
  }
  return categories[0].name;
};

export const trimComma = string => string.replace(/,/gi, ' ');

export const titleCase = str => {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const getDisplayName = (params, aggression, isAdvanced) => {
  const {
    q,
    category,
    categoryName,
    category1Name,
    category2Name,
    category3Name,
    categoryNames,
    brand,
    productFeatures,
    origin,
    isNew,
    adTitle,
  } = params;
  if (adTitle) {
    return adTitle;
  }
  if (q && q !== '*') {
    return `"${q}"`;
  }

  if (isAdvanced) {
    if (isNew === 'true') {
      return SEARCH_TITLE.NEW;
    }
    if (!category) {
      if (categoryName) {
        return titleCase(trimComma(categoryName));
      }
      if (category3Name) {
        return titleCase(trimComma(category3Name));
      }
      if (category2Name) {
        return titleCase(trimComma(category2Name));
      }
      if (category1Name) {
        return titleCase(trimComma(category1Name));
      }
      if (categoryNames) {
        return titleCase(
          trimComma(getCategoryName(categoryNames).replaceAll('-', ' '))
        );
      }
      if (brand) {
        return titleCase(trimComma(brand));
      }
      if (productFeatures) {
        return trimComma(productFeatures);
      }
      if (origin) {
        return trimComma(origin);
      }
    }
  }
  if (
    aggression &&
    aggression[0] &&
    aggression[0].counts &&
    aggression[0].counts.length > 0
  ) {
    return getSelectedCategoryName(aggression[0].counts);
  }
  if (
    aggression &&
    aggression[0] &&
    aggression[0].data &&
    aggression[0].data.length > 0
  ) {
    return getSelectedCategoryName(aggression[0].data);
  }
};
const getNestCategory = categories => {
  if (!categories.length) {
    return [];
  }
  let breadCrumb = [
    {
      id: categories[0].id,
      name: categories[0].name,
      query: categories[0].query,
      selected: categories[0].selected,
    },
  ];
  if (categories[0] && categories[0].counts) {
    const next = getNestCategory(categories[0].counts);
    if (next) {
      return breadCrumb.concat(next);
    }
    return breadCrumb;
  }
  return breadCrumb;
};

const splitSubArray = array => {
  let index;
  for (let i = 0; i < array.length; i++) {
    if (array[i].selected) {
      index = i;
    }
  }
  if (!index) {
    return array;
  }
  return array.slice(0, index + 1);
};

export const getBreadCrumbLinks = (isSearchAll, aggression) => {
  if (!isSearchAll) {
    return `"${isSearchAll}"`;
  }
  if (
    aggression &&
    aggression[0] &&
    aggression[0].counts &&
    aggression[0].counts.length > 0
  ) {
    const breadCrumb = getNestCategory(aggression[0].counts);
    return splitSubArray(breadCrumb);
  }
  if (
    aggression &&
    aggression[0] &&
    aggression[0].data &&
    aggression[0].data.length > 0
  ) {
    const breadCrumb = getNestCategory(aggression[0].data);
    return splitSubArray(breadCrumb);
  }
};

export const createHashMap = array => {
  if (!array || array.length === 0) {
    return null;
  }
  const hashMap = {};
  for (let i = 0; i < array.length; i++) {
    if (array[i] && array[i].itemId) {
      hashMap[array[i].itemId] = {
        quantity: array[i].quantity,
        basketItemUuid: array[i].uuid,
        stockCheck: array[i].stockCheck,
        unitPrice: array[i].unitPrice,
      };
    }
  }
  return hashMap;
};

export const validatePostcode = postcode => {
  if (typeof postcode !== 'string') {
    return;
  }
  const ps = new Postcode(postcode);
  return ps.valid();
};

export const getPartialPostcode = postcode => {
  if (typeof postcode !== 'string') {
    return;
  }
  if (Postcode.validOutcode(postcode)) {
    return postcode;
  }
  const ps = new Postcode(postcode);
  if (!ps.valid()) {
    return;
  }
  return ps.outcode();
};

export const enrichBasketItems = (items, array) => {
  const hashMap = {};
  for (let i = 0; i < array.length; i++) {
    if (array[i] && array[i].itemId) {
      hashMap[array[i].itemId] = array[i];
    }
  }
  return items.map(item => {
    if (item && item.itemId && hashMap[item.itemId]) {
      return Object.assign({}, item, {extra: hashMap[item.itemId]});
    }
    return item;
  });
};

const containMessage = (a, b) => {
  const key = 'messages';
  let comparison = 0;
  if (a[key] && !b[key]) {
    comparison = -1;
  } else if (b[key] && !a[key]) {
    comparison = 1;
  }
  return comparison;
};

export const enrichBasketItemsWithMessages = (items, messages) => {
  let inStock = [];
  let outOfStock = [];
  if (items.inStock) {
    inStock = enrichItemsWithMessages(items.inStock, messages);
  }
  if (items.outOfStock) {
    outOfStock = enrichItemsWithMessages(items.outOfStock, messages);
  }
  return {
    inStock,
    outOfStock,
  };
};

const enrichItemsWithMessages = (items, messages) => {
  const hashMap = {};
  let hasMessage = false;
  for (let i = 0; i < messages.length; i++) {
    if (messages[i] && messages[i].params && messages[i].params.itemId) {
      hashMap[messages[i].params.itemId] = messages[i];
    }
  }
  const enriched = items.map(item => {
    if (hashMap[item.code]) {
      hasMessage = true;
      return Object.assign({}, item, {messages: hashMap[item.code]});
    }
    return item;
  });
  if (!hasMessage) {
    return items;
  }
  return enriched.sort(containMessage);
};

export const getCurrentUTCTime = () => moment.utc(new Date()).valueOf();

export const isExpiredDate = date =>
  moment().diff(moment(date, 'DD-MM-YYYY'), 'days') > 0;

export const getAccountById = (accounts, accountNumber) => {
  const result = accounts.filter(account => account.id === accountNumber);
  return result && result.length > 0 ? result[0] : null;
};

export const handleBasketItemMessage = message => {
  let result = '';
  switch (message.code) {
    case AX_ERRORS.QUANTITY_UNAVAILABLE: {
      result = `Sorry, we can only supply ${(message.params &&
        message.params.available) ||
        1}`;
      break;
    }
    case AX_ERRORS.OUT_OF_STOCK: {
      result = `Sorry, this product is out of stock`;
      break;
    }
    case AX_ERRORS.UNAVAILABLE: {
      result = `Sorry, this product is unavailable`;
      break;
    }
  }
  return result;
};

export const handleStockCheckMessage = (stockCheck, quantity) => {
  let result = '';
  switch (stockCheck.result) {
    case STOCK_CHECK.PARTIAL_STOCK: {
      if (stockCheck.quantityAsked !== quantity) {
        result = `Sorry, you added ${stockCheck.quantityAsked} but we can only supply ${quantity}`;
      }
      break;
    }
    case STOCK_CHECK.DAILY_LIMIT: {
      if (stockCheck.quantityAsked !== quantity) {
        result = `Sorry, You asked for ${stockCheck.quantityAsked}, but daily limit is ${quantity}`;
      }
      break;
    }
    case AX_ERRORS.OUT_OF_STOCK: {
      result = `Sorry, this product is out of stock}`;
      break;
    }
  }
  return result;
};

export const sortBasketItems = items => {
  if (!Array.isArray(items)) {
    return items;
  }
  let inStock = [];
  const outOfStock = [];
  let sameDayDeliveryItems = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i] && items[i].stockCheck) {
      const isSERItem = isServiceItem(items[i]);
      if (
        (items[i].stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
          items[i].stockCheck.result === STOCK_CHECK.DISCONTINUED ||
          items[i].stockCheck.result === STOCK_CHECK.NOT_ALLOWED) &&
        !isSERItem
      ) {
        outOfStock.push(items[i]);
      } else {
        if (isSERItem) {
          sameDayDeliveryItems.push(items[i]);
        } else {
          inStock.push(items[i]);
        }
      }
    }
  }
  if (sameDayDeliveryItems.length > 0) {
    inStock = [...sameDayDeliveryItems, ...inStock];
  }
  inStock = inStock.filter(item => item !== undefined);
  return {
    inStock,
    outOfStock,
  };
};

export const hasMinDeliveryOrderNotMet = (basket, isCheckoutPage) => {
  if (!basket) {
    return false;
  }
  if (basket.fulfillmentType !== SERVICE_TYPE.DELIVERY) {
    return false;
  }
  if (!isBasketSynced(basket.syncStatus) && !isCheckoutPage) {
    return false;
  }
  if (isBasketSynced(basket.syncStatus)) {
    if (basket.minimumOrderAmount === 0) {
      return false;
    } else if (
      basket.price &&
      basket.minimumOrderAmount > 0 &&
      basket.price.total &&
      basket.price.total < basket.minimumOrderAmount
    ) {
      return true;
    }
  }
  return false;
};

export const saveTokenValidTime = expires_in => {
  const time = moment(new Date()).add(expires_in, 'seconds');
  persistDataToLocalStorage(JJ_LOCAL_STORAGE.TOKEN_VALID_UNTIL, time.valueOf());
};

export const getPartAccountsDetails = accounts => {
  return accounts.map(account => ({
    id: account.id,
    active: account.active,
    addressLine: account.addressLine,
    branchId: account.branchId,
    branchName: account.branchName,
    businessName: account.businessName,
    customerTypeCode: account.customerTypeCode,
    minimumOrderAmount: account.minimumOrderAmount,
    name: account.name,
    phoneNumber: account.phoneNumber,
    postcode: account.postcode,
    tradingName: account.tradingName,
  }));
};

const getItemIndexByUuid = (items, uuid) => {
  let index = -1;
  for (let i = 0; i < items.length; i++) {
    if (items[i].uuid === uuid) {
      index = i;
      break;
    }
  }
  return index;
};

export const updateCurrentBasket = (isRemove, basket, newBasket, uuid) => {
  const newBasketItems = newBasket.items;
  const index = getItemIndexByUuid(basket.items, uuid);
  let newItemIndex;
  if (!isRemove) {
    newItemIndex = getItemIndexByUuid(newBasketItems, uuid);
  }
  if ((newItemIndex && newItemIndex < 0) || index < 0) {
    return newBasket;
  }
  let updatedItem;
  let updatedItems;
  if (!isRemove) {
    const newBasketItem = newBasketItems[newItemIndex];
    const price = newBasketItem.price;
    const quantity = newBasketItem.quantity;
    const stockCheck = newBasketItem.stockCheck;
    updatedItem = Object.assign({}, basket.items[index], {
      price,
      quantity,
      stockCheck,
    });
    updatedItems = [
      ...basket.items.slice(0, index),
      updatedItem,
      ...basket.items.slice(index + 1),
    ];
  } else {
    updatedItems = [
      ...basket.items.slice(0, index),
      ...basket.items.slice(index + 1),
    ];
  }
  return Object.assign({}, basket, {
    price: newBasket.price,
    items: updatedItems,
    messages: newBasket.messages,
    synced: newBasket.synced,
  });
};

export const getAlternativeGroup = items => {
  const alternativeGroupIds = [];
  const oosIds = [];
  if (items.length === 0) {
    return null;
  }
  for (let i = 0; i < items.length; i++) {
    if (
      items[i] &&
      items[i].stockCheck &&
      items[i].stockCheck.result === STOCK_CHECK.OUT_OF_STOCK &&
      items[i].product &&
      items[i].product.altItemGroupId &&
      items[i].itemId
    ) {
      alternativeGroupIds.push(items[i].product.altItemGroupId);
      oosIds.push(items[i].itemId);
    }
  }
  if (alternativeGroupIds.length === 0) {
    return null;
  }
  return {
    alternativeGroupIds,
    oosIds,
  };
};

export const groupSubstitutesItems = items => {
  const sub = {};
  for (let i = 0; i < items.length; i++) {
    if (
      items[i] &&
      items[i].alternativeItemFor &&
      !!items[i].alternativeItemFor.length
    ) {
      for (let j = 0; j < items[i].alternativeItemFor.length; j++) {
        if (!sub[items[i].alternativeItemFor[j]]) {
          sub[items[i].alternativeItemFor[j]] = [];
        }
        if (
          items[i].itemId &&
          sub[items[i].alternativeItemFor[j]].find(
            obj => obj.itemId === items[i].itemId
          )
        ) {
          return;
        }
        sub[items[i].alternativeItemFor[j]].push(items[i]);
      }
    }
  }
  return sub;
};

export const generateNewProductsLink = (
  branch,
  deliveryDate,
  account,
  isForRouterLink
) => {
  let params;
  params = {
    advanced: true,
    b: branch,
    isNew: true,
    q: '*',
    deliveryDate: getValidDeliveryDate(deliveryDate),
    sortType: SEARCH_SORT_TYPE.OFFER,
  };
  if (account) {
    params = {...params, ...{account}};
  }
  if (isForRouterLink) {
    return {
      pathname: '/search',
      search: `?${queryString.stringify(params)}`,
    };
  }
  return `/search?${queryString.stringify(params)}`;
};

const getEnabledMessage = messages =>
  messages.filter(message => message.enabled === 'true');

const isValidMessage = message => {
  if (!message) {
    return false;
  }
  if (!message.validTo) {
    return true;
  }
  const GB = '[GB]';
  const validFrom = message.vaildFrom.replace(GB, '');
  const validTo = message.vaildTo.replace(GB, '');
  return moment().isBetween(validFrom, validTo);
};

export const getFilteredSystemMessage = messages => {
  const validMessages = [];
  if (!Array.isArray(messages)) {
    return messages;
  }
  messages.forEach(msg => {
    if (msg.enabled === true && isValidMessage(msg)) {
      validMessages.push(msg);
    }
  });
  return validMessages;
};

export const shouldReFetchFullBasket = basketItems => {
  if (!basketItems) {
    return false;
  }
  return (
    (basketItems.inStock &&
      basketItems.inStock[0] &&
      !basketItems.inStock[0].product) ||
    (basketItems.outOfStock &&
      basketItems.outOfStock[0] &&
      !basketItems.outOfStock[0].product) ||
    false
  );
};

export const processedSearchResults = searchResults => {
  let processedSearchResults = [];
  if (searchResults._embedded && searchResults._embedded.products) {
    processedSearchResults = searchResults._embedded.products.map(p => {
      if (p && p.imageLinks && p.imageLinks.length > 0) {
        return Object.assign({}, p, {
          imageLinks: reduceGroupImageSize(p.imageLinks),
        });
      }
      return p;
    });
  }
  return processedSearchResults;
};

export const processedGQLSearchResults = searchResults => {
  let processedSearchResults = [];
  if (searchResults) {
    processedSearchResults = searchResults.map(p => {
      if (
        (p && p.imageLinks && p.imageLinks.length > 0) ||
        (p.prices && p.prices.length > 0)
      ) {
        let imageLinks;
        let collectionMulti;
        let deliveryMulti;
        if (p && p.imageLinks && p.imageLinks.length > 0) {
          imageLinks = reduceGroupImageSize(p.imageLinks);
        }
        if (p.collectionMultiBuy && p.collectionMultiBuy.length > 0) {
          collectionMulti = getPriceMulti(p.collectionMultiBuy);
        }
        if (p.deliveryMultiBuy && p.deliveryMultiBuy.length > 0) {
          deliveryMulti = getPriceMulti(p.deliveryMultiBuy);
        }
        return Object.assign(
          {},
          p,
          imageLinks && {imageLinks},
          collectionMulti && {collectionMulti},
          deliveryMulti && {deliveryMulti}
        );
      }
      return p;
    });
  }
  return processedSearchResults;
};

export const processedProductDetail = product => {
  const result = processedGQLSearchResults([product]);
  return !!(result && result[0]) ? result[0] : product;
};

export const syncItemsPriceAndQty = (syncedItem, originItems) => {
  return syncedItem
    .map(item => {
      const index = originItems.findIndex(
        ori => item && ori && ori.itemId === item.itemId
      );
      if (index > -1) {
        return Object.assign({}, item, {
          product: originItems[index].product,
        });
      }
    })
    .filter(item => item !== undefined);
};

export const getTodayDate = () =>
  moment(new Date()).format(ORDER_SERVICE_DATE_FORMAT);

export const getTomorrowDate = () =>
  moment(new Date())
    .add(1, 'days')
    .format(ORDER_SERVICE_DATE_FORMAT);

export const getSlotDisplayDate = dateTimeToCutOff => {
  let cutOffInfo;
  const dateMom = moment(dateTimeToCutOff);
  const cutOffDate = dateMom.format(ORDER_SERVICE_DATE_FORMAT);
  if (cutOffDate === getTodayDate()) {
    cutOffInfo = 'today';
  } else if (cutOffDate === getTomorrowDate()) {
    cutOffInfo = 'tomorrow';
  } else {
    cutOffInfo = 'on ' + dateMom.format(FULFILLMENT_SUCCESS_FORMAT);
  }
  return cutOffInfo;
};

const checkInvalidSlot = slot => !!(!slot || (slot && !slot.charge));

export const ifAfterChargePeriod = (slot, isToday) => {
  let chargeTime;
  if (isToday && slot && slot.availability && slot.availability[0]) {
    chargeTime = slot.availability[0].timeStampToCutOffUTC * 1000;
  } else {
    if (checkInvalidSlot(slot)) {
      return false;
    }
    const charge = slot.charge.find(data => !!data.isActive);
    if (!charge) {
      return false;
    }
    chargeTime = moment.utc(charge.chargeWindowTo).valueOf();
  }
  return moment.utc().valueOf() > chargeTime;
};

export const getDDTime = (slot, isToday) => {
  let chargeTime;
  if (isToday && slot && slot.availability && slot.availability[0]) {
    chargeTime = slot.availability[0].dateTimeToCutOff;
  } else {
    if (checkInvalidSlot(slot)) {
      return '';
    }
    const charge = slot.charge.find(data => !!data.isActive);
    if (!charge) {
      return '';
    }
    chargeTime = charge.chargeWindowFrom;
  }
  return momentTz.tz(chargeTime, TIME_ZONE).format(H_MM_A_FORMAT);
};

export const hasChargeFee = slot => {
  if (checkInvalidSlot(slot)) {
    return false;
  }
  const charge = slot.charge.find(data => !!data.isActive);
  return !!charge || (charge && charge.chargeValue > 0);
};

export const getSlotByTime = (slot, from, to) =>
  slot.find(data => data.from === from && data.to === to);

export const getDateParams = async (basket, settings) => {
  const fulfillmentDetailsFromLocal = await getDataFromLocalStorage(
    JJ_LOCAL_STORAGE.FULFILLMENT
  );
  const isSlotSet =
    basket &&
    basket.fulfillAtSlot &&
    basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT;
  const fulfillment =
    settings && settings.fulfillment && settings.fulfillment.toUpperCase();
  const days = !isSlotSet && fulfillment === SERVICE_TYPE.DELIVERY ? 1 : 0;
  let todayOrTomorrow = moment(new Date())
    .add(days, 'days')
    .format(ORDER_SERVICE_DATE_FORMAT);
  if (
    fulfillmentDetailsFromLocal &&
    fulfillmentDetailsFromLocal.date &&
    !isExpiredDate(fulfillmentDetailsFromLocal.date)
  ) {
    todayOrTomorrow = fulfillmentDetailsFromLocal.date;
  }
  return {
    deliveryDate: todayOrTomorrow,
  };
};

const getCategoriesCounts = categories => {
  let count = 0;
  categories.forEach(subCategories => {
    if (subCategories.counts) {
      count += getCategoriesCounts(subCategories.counts);
    }
    count += subCategories.counts.length;
  });
  return count;
};

export const getCategoriesSize = filters => {
  let counts = 0;
  if (filters && filters.length > 0) {
    counts += 1;
    counts += getCategoriesCounts(filters);
  }
  return counts;
};

export const checkHasMatchingBranch = (branches, branchId) => {
  if (!branches) {
    return true;
  }
  return branches.some(option => option && option.Value === branchId);
};

export const filterValidContent = (data, branchId) => {
  const today = moment(new Date());
  return data.filter(item => {
    const startDate = moment(item.startDate, DATE_FORMAT);
    const endDate = moment(item.endDate, DATE_FORMAT);
    const isValid =
      today.isBetween(startDate, endDate) ||
      today.isSame(startDate) ||
      today.isSame(endDate) ||
      (item.endDate === '' &&
        (today.isAfter(startDate) || item.startDate === ''));
    return (
      ((item.linkUrl && item.tabTitle) || item.fileLink) &&
      isValid &&
      checkHasMatchingBranch(item.branch, branchId)
    );
  });
};

export const chooseColumnsStyle = isMobile =>
  isMobile ? 'columns-1' : 'columns-2';

export const getSelectedBranchData = (branchList, branchLocator) => {
  if (
    branchLocator &&
    branchLocator.inventLocationId &&
    branchList &&
    branchList[branchLocator.inventLocationId]
  ) {
    return Object.assign({}, branchList[branchLocator.inventLocationId], {
      latitude: branchLocator.latitude,
      longitude: branchLocator.longitude,
      address: branchLocator.address,
      DeliveryOpeningTime: branchLocator.deliveryDays,
      Contact: branchLocator.contact,
    });
  }
  return null;
};

export const checkHasCardPayment = paymentOptions => {
  if (paymentOptions && paymentOptions.length === 0) {
    return false;
  }
  return !!paymentOptions.find(
    option =>
      option.mode === PAYMENT_TYPES.CDC.mode ||
      option.mode === PAYMENT_TYPES.BCDC.mode
  );
};

export const getDeliveryHoursFromSlot = times => {
  const fromTime = times.find(time => !!time.isAvailable);
  const toTime = times.reverse().find(time => !!time.isAvailable);
  if (fromTime && toTime) {
    return `${fromTime.from} - ${toTime.to}`;
  }
  return null;
};

export const getUserSearchSuggestions = (action, auth, settings) => {
  // get keywords from settings and sort based on how many times user searched for that keyword
  const parsedKeywords = getSearchKeywords(settings);
  const keywordsFromSettings = Object.entries(parsedKeywords)
    .filter(
      keyword =>
        SEARCH_SUGGESTIONS_DEFAULT_KEYWORDS.indexOf(keyword[0]) === -1 &&
        keyword[0] !== '' &&
        keyword[1] >= 2
    )
    .sort((a, b) => b[1] - a[1])
    .map(keyword => {
      return {
        searchField: keyword[0],
        type: 'keyword',
        urlParams: `?q=${keyword[0]}&sortType=search`,
      };
    });

  // if user has more than 5 searches, show them, otherwise, show top searches
  const localSuggestions = [
    ...keywordsFromSettings,
    ...SEARCH_SUGGESTIONS_DEFAULT_SUGGESTIONS,
  ];

  const suggestions = localSuggestions.filter(
    (item, index, self) =>
      index === self.findIndex(t => t.searchField === item.searchField)
  );

  // add favourites and recent items at the top
  const results = [
    ...(auth ? SEARCH_SUGGESTIONS_DEFAULT_LINKS : []),
    ...suggestions,
  ].slice(0, 13);

  return results;
};

export const getSearchKeywords = settings => {
  if (settings && settings[SETTINGS.USER_SEARCH_KEYWORDS]) {
    return JSON.parse(Base64.decode(settings[SETTINGS.USER_SEARCH_KEYWORDS]));
  }
  return {};
};

const maximizeStringLength = (inputString, maxLength) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    const words = inputString.split(' ');
    let result = '';
    let currentLength = 0;

    for (const word of words) {
      if (currentLength + word.length <= maxLength) {
        result += `${word} `;
        currentLength += word.length + 1; // Add 1 for the space between words
      } else {
        // Check if cutting the word would exceed the soft maximum length
        if (currentLength === 0) {
          // If the first word already exceeds the soft maximum, cut it
          result = result.slice(0, -1); // Remove the trailing space
          return result.slice(0, maxLength);
        } else {
          // If cutting the word would exceed the soft maximum, include the whole word
          result += word;
          break;
        }
      }
    }

    // Remove the trailing space before returning
    return result.trim();
  }
};

export const incrementSearchKeyword = (keyword, parsedKeywords) => {
  // Check if the keyword is a valid keyword and exclude if not to protect
  if (INVALID_SEARCH_SUGGESTION_KEYWORD.test(keyword)) {
    return {...parsedKeywords};
  }
  const shortKeyword = maximizeStringLength(
    keyword,
    SEARCH_SUGGESTION_MAX_LENGTH
  );
  // increment keyword if found, add if not exists yet
  return {
    ...parsedKeywords,
    [shortKeyword]: (parsedKeywords[shortKeyword] || 0) + 1,
  };
};

const getTotalNumberOfSearch = searchObj => {
  const searchItems = Object.keys(searchObj || {}) || [];
  return searchItems.map(item => searchObj[item]).reduce((a, b) => a + b, 0);
};

export const getSaveSearchKeywords = async (
  auth,
  keyword,
  settings,
  isFirstSearch
) => {
  const settingsKeywords = getSearchKeywords(settings);
  const localKeywords = await getDataFromLocalStorage(
    SETTINGS.USER_SEARCH_KEYWORDS
  );
  // merge local keywords and keywords from settings
  const allKeywords = Object.assign({}, settingsKeywords, localKeywords);

  // update keyword count or add a new keyword
  const updatedKeywords = auth
    ? incrementSearchKeyword(keyword, allKeywords)
    : {};

  // save "temporary" keywords to LS
  persistDataToLocalStorage(SETTINGS.USER_SEARCH_KEYWORDS, updatedKeywords);

  const totalLocalSearchCount = getTotalNumberOfSearch(localKeywords);
  const totalSettingsSearchCount = getTotalNumberOfSearch(settingsKeywords);
  const isSave =
    totalLocalSearchCount - totalSettingsSearchCount >=
    SAVE_TO_SETTINGS_NUMBER - 1;

  // clear LS for "temporary" keywords when saving
  if (isSave) {
    persistDataToLocalStorage(SETTINGS.USER_SEARCH_KEYWORDS, {});
  }
  return {
    save: isFirstSearch || isSave,
    keywords: updatedKeywords,
  };
};

export const getKeyCaseInsensitive = (obj, prop) => {
  if (!obj || !prop) {
    return null;
  }
  try {
    return obj[
      Object.keys(obj).find(key => key.toLowerCase() === prop.toLowerCase())
    ];
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const getBranchFromUrl = match =>
  (match &&
    match.params &&
    match.params.branchName &&
    getKeyCaseInsensitive(BRANCH_NAME_MAPPING, match.params.branchName)) ||
  DEFAULT_BRANCH;

export const getCategoryNamesFromUrl = (
  category1Name,
  category2Name,
  category3Name
) => {
  const checkCategory1Name = checkIfStringIsDefined(category1Name);
  const checkCategory2Name = checkIfStringIsDefined(category2Name);
  const checkCategory3Name = checkIfStringIsDefined(category3Name);
  const categoryNamesArray = [];
  if (checkCategory1Name) {
    categoryNamesArray.push(decodeURIComponent(category1Name));
  }
  if (checkCategory2Name) {
    categoryNamesArray.push(decodeURIComponent(category2Name));
  }
  if (checkCategory3Name) {
    categoryNamesArray.push(decodeURIComponent(category3Name));
  }
  return categoryNamesArray;
};

export const getMergedData = formValues => {
  if (!formValues) {
    return;
  }
  const name =
    formValues.name &&
    formValues.name
      .split(/\s+/)
      .join(' ')
      .trim();
  const names = (name && name.split(' ')) || [];
  const fname = names[0];
  const lname = names[1] || '-';
  return {
    FNAME: formValues.firstname || fname,
    LNAME: formValues.surname || lname,
    MMERGE6:
      formValues.businessName ||
      formValues.firstname ||
      fname + ' ' + formValues.surname ||
      lname,
    MMERGE4: formValues.businessType,
    MMERGE3: formValues.address.street || formValues.address,
    MMERGE10: formValues.phone1,
    MMERGE5: formValues.postcode,
    REFERRALSO: formValues.whereDidYouHearAboutUs,
  };
};

export const checkIfToday = dateString =>
  moment(0, 'HH').isSame(moment(dateString, SLOT_DATE_FORMAT), 'day');

export const checkIfTomorrow = dateString => {
  const refDate = moment(0, 'HH');
  const tomorrow = refDate.add(1, 'days').startOf('day');
  return tomorrow.isSame(moment(dateString, SLOT_DATE_FORMAT), 'd');
};

export const checkIfStringIsDefined = str => str && str !== 'undefined';

export const getAddressLine = address => {
  const {street, city, postcode} = address;
  return `${street} ${city ? `, ${city}` : ''} ${
    postcode ? `, ${postcode}` : ''
  }`;
};

export const checkAvailableForBranch = (product, branchId, auth) => {
  if (!product || (product && !product.branches) || !branchId) {
    return {
      available: false,
    };
  }
  const result = product.branches.filter(
    branch => branch.locationId === branchId
  );
  if (
    result.length > 0 ||
    (product.branches.length > 0 && !auth && !branchId)
  ) {
    return {
      available: true,
    };
  }
  const branchName = BRANCH_MAPPING[branchId];
  return {
    available: false,
    message: `Sorry, this product is currently not available at ${
      branchId ? branchName : 'this'
    } branch`,
  };
};

export const getValidDeliveryDate = date => {
  const today = moment();
  if (!date || moment(date, SLOT_DATE_FORMAT).isBefore(today)) {
    return today.format(SLOT_DATE_FORMAT);
  }
  return date;
};

export const getValidUSDate = date => {
  const today = moment();
  if (!date || moment(date, SLOT_DATE_FORMAT).isBefore(today)) {
    return today.format(STANDARD_FORMAT);
  }
  return moment(date, SLOT_DATE_FORMAT).format(STANDARD_FORMAT);
};

export const getInvalidNumberError = number => {
  if (!number) {
    return FORM_ERRORS.REQUIRED;
  }
  if (!REGISTER_TEL_REGEX.test(number)) {
    return FORM_ERRORS.INVALID;
  }
  return null;
};

export const getProductUrl = (branch, sku, productName) => {
  if (sku === productName) {
    return `/product/${branch}/${sku}`;
  }

  return `/product/${branch}/${sku}/${productName}`;
};

export const getRandomDigit = exponent => {
  const times = Math.pow(10, exponent);
  return Math.floor(Math.random() * 9 * times) + times;
};

export const getOrderStatus = eta => {
  const state = eta.toUpperCase();
  return !!ORDER_TRACKING[state]
    ? {status: ORDER_TRACKING[state]}
    : {status: ORDER_TRACKING.DELIVERING, eta};
};

export const getAlternativeGroupId = (itemId, searchResults, productDetail) => {
  // get altItemGroupId from productDetail if itemId matching
  if (productDetail && productDetail.itemId === itemId) {
    return productDetail.altItemGroupId;
  }
  // if not, get it from search results
  const searchResultsaltItem = searchResults.filter(
    item => item.itemId === itemId
  )[0];
  return searchResultsaltItem && searchResultsaltItem.altItemGroupId;
};

export const getNextSlotInfo = (
  firstDay,
  slots,
  isTodaySlot,
  isTomorrowSlot
) => {
  if (isTomorrowSlot) {
    return 'Tomorrow';
  }
  if (isTodaySlot) {
    const timeRanges = slots[firstDay].availability[0];
    return `Today ${timeRanges.from} to ${timeRanges.to}`;
  }
  return moment(firstDay, SLOT_DATE_FORMAT).format(
    ORDER_HISTORY_OUT_DATE_FORMAT
  );
};

export const getOwnBrandDescription = title =>
  getKeyCaseInsensitive(OWN_BRAND_DESCRIPTION, title);

export const getCategoryName = (categoryNames, currentIndex) => {
  if (!categoryNames) {
    return '';
  }
  currentIndex = currentIndex || categoryNames.length - 1;
  if (typeof categoryNames === 'string') {
    return categoryNames.replaceAll('-', ' ');
  }
  if (currentIndex >= categoryNames.length) {
    return '';
  }
  const currentItem = categoryNames[currentIndex];
  if (currentItem === undefined || currentItem === null || currentItem === '') {
    return getCategoryName(categoryNames, currentIndex - 1);
  }
  return currentItem;
};

export const getCategoryDescription = (category, categoryNames) => {
  const categories = category && category.split(',');
  const isRootCategory =
    (categories && categories.length === 1) ||
    (categoryNames && categoryNames.length === 1) ||
    typeof categoryNames === 'string';
  const lookupCategory = getCategoryName(categoryNames) || category;
  return (
    isRootCategory &&
    getKeyCaseInsensitive(CATEGORY_DESCRIPTION, lookupCategory)
  );
};

export const getHashMapFromList = (lists, itemId) => {
  if (!lists || (lists && lists.length === 0) || !itemId) {
    return null;
  }
  const hashMap = {};
  for (let i = 0; i < lists.length; i++) {
    if (lists[i] && lists[i].items && !!lists[i].items.length) {
      for (let j = 0; j < lists[i].items.length; j++) {
        if (lists[i].items[j].itemId === itemId) {
          hashMap[lists[i].id] = true;
        }
      }
    }
  }
  return hashMap;
};

export const checkProductDetails = product =>
  !product ||
  (product && !product.data) ||
  (product && product.data && !product.data.searchProductsByItemId) ||
  (product &&
    product.data &&
    product.data.searchProductsByItemId &&
    !product.data.searchProductsByItemId.results) ||
  (product &&
    product.data &&
    product.data.searchProductsByItemId &&
    product.data.searchProductsByItemId.results.length === 0);

export const isReadyToRefund = refundItem =>
  !!refundItem &&
  ((refundItem.photo && !!refundItem.reason) ||
    refundItem.reason === MISSING_ITEM);

export const getJJDeviceIdCookieValue = () =>
  document.cookie.replace(
    /(?:(?:^|.*;s*)JJ.deviceIds*=s*([^;]*).*$)|^.*$/,
    '$1'
  );

export const checkHasPbbPayment = paymentOptions => {
  if (!paymentOptions) {
    return false;
  }
  return paymentOptions.some(
    option => option && option.mode === PAYMENT_TYPES.PBB.mode
  );
};

export const getPromoPrice = promoDisAmt => {
  if (promoDisAmt > 0) {
    return promoDisAmt >= 1
      ? '£' + (promoDisAmt % 1 > 0 ? promoDisAmt.toFixed(2) : promoDisAmt)
      : promoDisAmt * 100 + 'p';
  }
  return '';
};

export const getFromPrice = (price, discount) => {
  if (!price || !discount) {
    return null;
  }
  return 'From £' + (price - discount).toFixed(2);
};

export const getPromoString = (promoTagId, promoDisAmt) => {
  let priceText = '';
  if (promoDisAmt > 0) {
    priceText =
      promoDisAmt >= 1 ? '£' + promoDisAmt.toFixed(2) : promoDisAmt * 100 + 'p';
  }
  if (PROMO_TAGS_MAPPING[promoTagId]) {
    return {
      prom1: `Extra ${priceText} off `,
      prom2: PROMO_TAGS_MAPPING[promoTagId],
    };
  }
  return null;
};

export const videoUrlConvert = url => {
  if (
    url.indexOf(YOUTUBE_URL.SHORT) > -1 ||
    url.indexOf(YOUTUBE_URL.SHORT) < 0
  ) {
    return url.replace(YOUTUBE_URL.SHORT, YOUTUBE_URL.EMBED);
  }
  if (url.indexOf(YOUTUBE_URL.FULL) > -1 || url.indexOf(YOUTUBE_URL.FULL) < 0) {
    return url.replace(YOUTUBE_URL.FULL, YOUTUBE_URL.EMBED);
  }
  return url;
};

export const linkUrlConvert = (linkUrl, isJJLink) => {
  // change the url to relative url if its jj url
  const url = isJJLink
    ? linkUrl.replace('https://' + JJ_DOMAIN.PROD, '')
    : linkUrl;
  if (
    url.charAt(0) !== '/' &&
    !HTTPS_REGEX.test(url) &&
    url.indexOf('mailto:') !== 0 &&
    url.indexOf('tel:') !== 0
  ) {
    return 'https://' + url;
  }
  return url;
};

export const getTotalCount = items => {
  let total = items.length;
  if (total === 0) {
    return 0;
  }
  items.map(item => {
    if (item.counts && !!item.counts.length) {
      total += item.counts.length;
      item.counts.map(subitem => {
        if (subitem.counts && !!subitem.counts.length) {
          total += subitem.counts.length;
        }
      });
    }
  });
  return total;
};

export const buildOpenStatus = (
  filters,
  closedByDefault,
  totalCategoryListCount
) => {
  const openStatus = {};
  filters.forEach(filter => {
    openStatus[filter.name] =
      !closedByDefault &&
      filter.name === FILTERS_NAME.categoryList &&
      totalCategoryListCount < LONG_CATEGORY_LIST_FILTER_LENGTH;
  });
  return openStatus;
};
export const checkIsDev = () =>
  !!(
    window &&
    window.location &&
    window.location.host.indexOf(URL.JJS_PROD) === -1
  )
    ? ADYEN_ENV.DEV
    : ADYEN_ENV.PROD;

export const addTwoValues = (val1, val2) => {
  return Math.round((parseFloat(val1) + parseFloat(val2)) * 100) / 100;
};

export const replaceContentUploadUrls = content => {
  const isDev =
    typeof window !== 'undefined' &&
    window.location.host.indexOf(URL.JJS_PROD) === -1;
  return (
    content &&
    content.replace(
      /(?<!https:\/\/[a-zA-Z0-9.-]+\.jjfoodservice\.com)\/uploads\//g,
      `${isDev ? STRAPI_CMS_URL.DEV : STRAPI_CMS_URL.PROD}/uploads/`
    )
  );
};

export const addBannerIndex = banners =>
  banners.map((b, idx) => {
    b.index = idx + 1;
    return b;
  });
