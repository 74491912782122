import React from 'react';
import {ExtraGrid} from './MarketingGrids.style';
import {generateNewProductsLink} from '../../helpers/data.helper';
import ImageComponent from '../Image/Image';

export const ExtraGrids = props => {
  const {callback, grid, isMobile, index, bannerIndex} = props;
  const onGridClick = e => {
    e && e.preventDefault();
    callback(grid.url, bannerIndex);
  };
  if (!grid) {
    return null;
  }
  return (
    <ExtraGrid
      href={grid.url}
      grid={grid}
      noMarginLeft={index % 4 === 0}
      onClick={onGridClick}
      alt={grid.name}
    >
      <ImageComponent
        data-src={grid.url}
        isLazyLoad={true}
        url={grid.image}
        alt={grid.name}
      />
    </ExtraGrid>
  );
};
