import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {media} from '../../styles/components/media-queries';

export const SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-size: 2rem;
  font-weight: bold;
  padding-top: 4rem;
  padding-bottom: 2rem;
  ${media.medium`
    padding: 0 2rem;
  `};
`;

export const SectionText = styled.div`
  color: ${p => (p.info ? p.theme.primaryColor : p.theme.textColor)};
  margin-bottom: ${p => (p.extraMargin ? '3rem' : 0)};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const SectionTextCaution = styled(SectionText)`
  font-weight: bold;
  padding-bottom: 2rem;
`;

export const SectionWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  ${media.medium`
    padding: 1rem 2rem;
  `};
  padding-bottom: 2rem;
`;

export const TheLink = styled(Link)`
  font-size: 1.6rem;
  color: ${p => p.theme.primaryColor};
`;

export const Tel = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;

export const SubmitTakingTooLong = styled.p`
  text-align: center;
  color: ${p => p.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
