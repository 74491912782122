import {put, call, takeLatest, select, retry} from 'redux-saga/effects';
import basketApi from '../api/basket.api';
import * as ActionTypes from '../actions/types.action';
import {
  AX_BASKET_ERRORS,
  BASKET_ERRORS_TYPE,
  ERROR_HTTP_STATUS,
  ERRORS,
  MAX_RETRY,
  PAYMENT_TYPES,
  RETRY_TIMEOUT,
  ROUTES,
  UPDATING_TYPES,
} from '../constants/constants';
import {
  createHashMap,
  getAlternativeGroup,
  groupSubstitutesItems,
  sortBasketItems,
  syncItemsPriceAndQty,
  updateCurrentBasket,
} from '../helpers/data.helper';
import {getBearerHeader} from '../helpers/api.helper';
import substitutesApi from '../api/substitutes.api';
import {getBasketItemsIds} from '../helpers/array.helper';
import {reduceBasketItemsImageSize} from '../helpers/image.helper';
import {
  getBasketItemsCodes,
  getItemsNotInFirstArray,
  getNormalItems,
  getSpecialItems,
  isBasketSynced,
  replaceOosItemsBySub,
  syncBasketItems,
  updateBasketAfterSubstitutions,
} from '../helpers/basket.helper';

export function* getSubstitutes(basket, headers) {
  const alternativeGroupIds = getAlternativeGroup(basket.items);
  if (!!alternativeGroupIds && basket.items && basket.items.length > 0) {
    try {
      const filter = getBasketItemsCodes(basket.items);
      const subParams = {
        branch: basket.branchId,
        filter,
        deliveryDate: (basket.fulfillAtSlot && basket.fulfillAtSlot.day) || '',
      };
      const basketItemsIds = getBasketItemsIds(basket.items);
      const altGroups = alternativeGroupIds.alternativeGroupIds.join(',');
      const substitutesItems = yield call(
        substitutesApi.getSubstitutes,
        altGroups,
        basketItemsIds.join(','),
        subParams,
        headers
      );
      if (
        substitutesItems &&
        substitutesItems._embedded &&
        substitutesItems._embedded.products &&
        !!substitutesItems._embedded.products.length
      ) {
        const substitutes = groupSubstitutesItems(
          substitutesItems._embedded.products
        );
        yield put({
          type: ActionTypes.GET_SUBSTITUTES_SUCCEED,
          substitutes,
        });
      } else {
        yield put({
          type: ActionTypes.GET_SUBSTITUTES_SUCCEED,
          substitutes: null,
        });
      }
    } catch (e) {
      yield put({type: ActionTypes.GET_SUBSTITUTES_FAILED});
    }
  }
}

function* getBasket(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    let basket;
    if (action.withProductDetails) {
      basket = yield call(basketApi.getBasketWithProductDetails, headers);
      if (basket && basket.items) {
        yield getSubstitutes(basket, headers);
      }
      basket = Object.assign({}, basket, {
        items: reduceBasketItemsImageSize(basket.items),
      });
    } else {
      basket = yield call(basketApi.getBasket, headers);
    }
    yield put({type: ActionTypes.GET_BASKET_SUCCEEDED, basket});
    yield put({
      type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
      basketHashMap: createHashMap(basket.items),
    });
    if (action.withProductDetails) {
      const basketItems = sortBasketItems(basket.items);
      yield put({
        type: ActionTypes.GET_PRODUCTS_BY_IDS_SUCCESS,
        basketItems,
        paymentMethod: basket.paymentMethod,
      });
    }
    yield put({
      type: ActionTypes.SET_BRANCH_SUCCESS,
      branch: basket.branchId,
    });
  } catch (e) {
    const {response} = e;
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else if (e.error === ERRORS.FATAL_SERVER_ERROR) {
      yield put({type: ActionTypes.INVALID_BASKET_UUID_ERROR});
    } else if (
      response &&
      response.data &&
      response.data.code === ERRORS.PICKED
    ) {
      yield put({type: ActionTypes.AX_BASKET_ALREADY_PICKED});
    } else if (
      response &&
      response.data &&
      response.data.code === ERRORS.AX_BASKET_NOT_FOUND
    ) {
      if (response.data.params && response.data.params.expiredEdit) {
        yield put({
          type: ActionTypes.AX_BASKET_ALREADY_PICKED,
          message: AX_BASKET_ERRORS.STARTED_PICKING,
        });
      } else {
        yield put({type: ActionTypes.AX_BASKET_NOT_FOUND});
      }
    } else if (
      e.code === ERRORS.NULL ||
      (response &&
        response.data &&
        (response.data.error === ERRORS.NOT_FOUND ||
          (response.data.code === ERRORS.BASKET &&
            response.data.params &&
            response.data.params.httpStatus === ERROR_HTTP_STATUS.NOT_FOUND)))
    ) {
      yield put({type: ActionTypes.INVALID_BASKET_UUID_ERROR});
    }
    yield put({
      type: ActionTypes.GET_BASKET_FAILED,
      message: e.message,
      response: e && e.response,
    });
    yield put({
      type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
      basketHashMap: null,
    });
  }
}

function* checkBasketSyncStatus(action) {
  try {
    const getAuth = state => state.auth;
    const getBasket = state => state.basket;
    const getBasketItems = state => state.basketItems;
    const storeToken = yield select(getAuth);
    const prevBasket = yield select(getBasket);
    const basketItems = yield select(getBasketItems);
    const headers = getBearerHeader(action.jjToken, storeToken);
    if (!headers) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
      return;
    }
    const basket = yield call(basketApi.getBasket, headers);
    if (isBasketSynced(basket.syncStatus)) {
      let syncedItems = syncItemsPriceAndQty(basket.items, prevBasket.items);
      const specialItems = getSpecialItems(basket.items);
      const prevSpecialItems = getSpecialItems(prevBasket.items);
      // add special service item if it was not in basket, otherwise remove it
      if (specialItems.length > 0 && prevSpecialItems.length === 0) {
        syncedItems = [...specialItems, ...syncedItems];
      } else if (specialItems.length === 0 && prevSpecialItems.length > 0) {
        syncedItems = getNormalItems(syncedItems);
      } else if (specialItems.length > 0 && prevSpecialItems.length > 0) {
        const newSpecialItems = getItemsNotInFirstArray(
          prevSpecialItems,
          specialItems
        );
        syncedItems = [...newSpecialItems, ...syncedItems];
      }
      // update stored basket after sync
      const syncedBasket = Object.assign({}, prevBasket, {
        price: basket.price,
        synced: basket.synced,
        syncStatus: basket.syncStatus,
        items: syncedItems,
        minimumOrderAmount: basket.minimumOrderAmount,
        cutOffDateTime: basket.cutOffDateTime,
        paymentMethod: basket.paymentMethod,
      });
      yield put({
        type: ActionTypes.CHECK_BASKET_SYNC_SUCCEEDED,
        basket: syncedBasket,
      });
      yield put({
        type: ActionTypes.SYNC_BASKET_ITEMS_SUCCESS,
        basketItems: syncBasketItems(basketItems, syncedBasket.items),
      });
      yield getSubstitutes(syncedBasket, headers);
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    }
    yield put({
      type: ActionTypes.CHECK_BASKET_SYNC_FAILED,
    });
  }
}

function* removeBasketItem(action) {
  try {
    const getBasket = state => state.basket;
    const getAuth = state => state.auth;
    const storeBasket = yield select(getBasket);
    const storeToken = yield select(getAuth);
    const getRoute = state => state.route;
    const currentRoute = yield select(getRoute);
    const isBasketPage = currentRoute === ROUTES.BASKET;
    const headers = getBearerHeader(action.jjToken, storeToken);
    const basket = yield retry(
      MAX_RETRY,
      RETRY_TIMEOUT,
      basketApi.removeBasketItem,
      action.uuid,
      headers
    );
    if (isBasketPage) {
      const isRemove = true;
      const newBasket = updateCurrentBasket(
        isRemove,
        storeBasket,
        basket,
        action.uuid
      );
      yield put({
        type: ActionTypes.UPDATE_BASKET_ITEM_SUCCEEDED,
        basket: newBasket,
      });
      yield put({
        type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
        basketHashMap: createHashMap(newBasket.items),
      });
      yield put({
        type: ActionTypes.GET_PRODUCTS_BY_IDS_SUCCESS,
        basketItems: sortBasketItems(newBasket.items),
        paymentMethod: newBasket.paymentMethod,
      });
    } else {
      yield put({type: ActionTypes.REMOVE_BASKET_ITEM_SUCCEEDED, basket});
      yield put({
        type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
        basketHashMap: createHashMap(basket.items),
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({
        type: ActionTypes.ERROR_LOGIN_EXPIRED,
        error: {
          type: UPDATING_TYPES.ITEM,
          uuid: action.uuid,
        },
      });
    }
    yield put({
      type: ActionTypes.REMOVE_BASKET_ITEM_FAILED,
      message: e.response && e.response.data && e.response.data.message,
      code: e.response && e.response.data && e.response.data.code,
      issue: BASKET_ERRORS_TYPE.REMOVE,
    });
  }
}

function* setFulfillment(action) {
  try {
    const getBasket = state => state.basket;
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const basket = yield call(
      basketApi.setFulfillment,
      action.fulfillment,
      headers
    );
    if (basket) {
      yield put({type: ActionTypes.SET_FULFILLMENT_SUCCESS, basket});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    }
    yield put({
      type: ActionTypes.SET_FULFILLMENT_FAILED,
      message: e.message,
    });
  }
}

function* setPaymentType(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    let data = {
      paymentMethod: action.paymentType.paymentMethod,
    };
    const basket = yield call(basketApi.setPaymentType, data, headers);
    if (basket) {
      yield put({
        type: ActionTypes.SET_PAYMENT_TYPE_SUCCESS,
        basket,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    }
    const containsErrorData = e.response && e.response.data;
    yield put({
      type: ActionTypes.SET_PAYMENT_TYPE_FAILED,
      message: containsErrorData && e.response.data.message,
      code: containsErrorData && e.response.data.code,
      issue: BASKET_ERRORS_TYPE.PAYMENT_TYPE,
    });
  }
}

function* setSubstitute(action) {
  try {
    const getStoreBasket = state => state.basket;
    const getBasketItems = state => state.basketItems;
    const getSubstitutes = state => state.substitutes;
    const getAuth = state => state.auth;
    const storeBasket = yield select(getStoreBasket);
    const basketItems = yield select(getBasketItems);
    const substitutes = yield select(getSubstitutes);
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const newBasket = yield call(
      basketApi.setSubstitutes,
      action.substitutes,
      headers
    );
    if (newBasket) {
      const updatedBasketItems = replaceOosItemsBySub(
        basketItems,
        substitutes,
        newBasket.items
      );
      const updatedBasket = updateBasketAfterSubstitutions(
        newBasket,
        storeBasket
      );
      yield put({
        type: ActionTypes.SET_SUBSTITUTE_ITEM_SUCCEEDED,
        basketItems: updatedBasketItems,
        substitutes: action.substitutes,
      });
      yield put({
        type: ActionTypes.SET_SUBSTITUTE_ITEM_IN_BASKET_SUCCEEDED,
        basket: updatedBasket,
      });
      yield put({
        type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
        basketHashMap: createHashMap(newBasket.items),
      });
    }
  } catch (e) {
    let code;
    if (e.response && e.response.data && e.response.data.code) {
      code = e.response.data.code;
    }
    yield put({
      type: ActionTypes.SET_SUBSTITUTE_ITEM_FAILED,
      message: e.message,
      code,
    });
  }
}

function* reOrderBasket(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const newBasket = yield call(basketApi.reOrderBasket, action.uuid, headers);
    if (newBasket) {
      yield put({type: ActionTypes.REORDER_BASKET_SUCCEEDED, newBasket});
      yield getBasket({withProductDetails: true});
    } else {
      yield put({
        type: ActionTypes.REORDER_BASKET_FAILED,
        message: e.message,
      });
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else if (e.error === ERRORS.FATAL_SERVER_ERROR) {
      yield put({type: ActionTypes.GET_BASKET_SERVER_ERROR});
    }
    yield put({
      type: ActionTypes.REORDER_BASKET_FAILED,
      message: e.message,
    });
    yield put({
      type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
      basketHashMap: null,
    });
  }
}

function* addVoucher(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const voucher = yield call(basketApi.addVoucher, action.voucher, headers);
    if (
      voucher.code === ERRORS.SLOT_EXPIRED ||
      voucher.code === ERRORS.SET_VOUCHER_AX_BASKET
    ) {
      yield put({type: ActionTypes.ADD_VOUCHER_FAILED, voucher});
    } else {
      yield put({type: ActionTypes.ADD_VOUCHER_SUCCEEDED, voucher});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_VOUCHER_FAILED,
      message: e.message,
    });
  }
}

function* removeVoucher(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const voucher = yield call(basketApi.removeVoucher, headers);
    if (
      voucher.code === ERRORS.SLOT_EXPIRED ||
      voucher.code === ERRORS.SET_VOUCHER_AX_BASKET
    ) {
      yield put({type: ActionTypes.REMOVE_VOUCHER_FAILED, voucher});
    } else {
      yield put({type: ActionTypes.REMOVE_VOUCHER_SUCCEEDED, voucher});
    }
  } catch (e) {
    yield put({
      type: ActionTypes.REMOVE_VOUCHER_FAILED,
      message: e.message,
    });
  }
}

function* editOrder(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const newBasket = yield call(basketApi.editOrder, action.orderId, headers);
    if (newBasket) {
      yield put({type: ActionTypes.EDIT_ORDER_SUCCEEDED, newBasket});
      yield getBasket({withProductDetails: true});
    }
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else if (e.error === ERRORS.FATAL_SERVER_ERROR) {
      yield put({type: ActionTypes.GET_BASKET_SERVER_ERROR});
    } else if (
      e.response &&
      e.response.data &&
      e.response.data.code === ERRORS.PICKED
    ) {
      yield put({
        type: ActionTypes.AX_BASKET_ALREADY_PICKED,
        message: AX_BASKET_ERRORS.STARTED_PICKING,
      });
    } else if (
      e.response &&
      e.response.data &&
      e.response.data.code === ERRORS.NOT_EDITABLE
    ) {
      yield put({
        type: ActionTypes.AX_BASKET_NOT_EDITABLE,
        message: AX_BASKET_ERRORS.NOT_EDITABLE,
      });
    } else if (
      e.response &&
      e.response.data &&
      e.response.data.code === ERRORS.AX_BASKET_NOT_FOUND
    ) {
      if (e.response.data.params && e.response.data.params.expiredEdit) {
        yield put({
          type: ActionTypes.AX_BASKET_ALREADY_PICKED,
          message: AX_BASKET_ERRORS.STARTED_PICKING,
        });
      } else {
        yield put({type: ActionTypes.AX_BASKET_NOT_FOUND});
      }
    } else {
      yield put({
        type: ActionTypes.EDIT_ORDER_FAILED,
        message: e.message,
      });
    }

    yield put({
      type: ActionTypes.SET_BASKET_HASHMAP_SUCCEEDED,
      basketHashMap: null,
    });
  }
}

function* deleteAxBasket(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    yield call(basketApi.deleteAxBasket, headers);
    yield put({type: ActionTypes.REMOVE_AX_BASKET_SUCCEEDED});
  } catch (e) {
    yield put({
      type: ActionTypes.REMOVE_AX_BASKET_FAILED,
      message: e.message,
    });
  }
}

function* addAllShoppingListToBasket(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    yield call(basketApi.addAllShoppingListItems, action.listId, headers);
    yield put({type: ActionTypes.ADD_ALL_SHOPPING_LIST_ITEMS_SUCCESS});
  } catch (e) {
    yield put({
      type: ActionTypes.ADD_ALL_SHOPPING_LIST_ITEMS_FAILED,
      message: e.message,
    });
  }
}

export default function* getBasketYield() {
  yield takeLatest(ActionTypes.GET_BASKET_REQUESTED, getBasket);
  yield takeLatest(ActionTypes.GET_BASKET_SILENTLY_REQUESTED, getBasket);
  yield takeLatest(
    ActionTypes.CHECK_BASKET_SYNC_REQUESTED,
    checkBasketSyncStatus
  );
  yield takeLatest(ActionTypes.REMOVE_BASKET_ITEM_REQUESTED, removeBasketItem);
  yield takeLatest(ActionTypes.SET_FULFILLMENT_REQUESTED, setFulfillment);
  yield takeLatest(ActionTypes.SET_PAYMENT_TYPE_REQUESTED, setPaymentType);
  yield takeLatest(ActionTypes.SET_SUBSTITUTE_ITEM_REQUESTED, setSubstitute);
  yield takeLatest(ActionTypes.REORDER_BASKET_REQUESTED, reOrderBasket);
  yield takeLatest(ActionTypes.ADD_VOUCHER_REQUESTED, addVoucher);
  yield takeLatest(ActionTypes.REMOVE_VOUCHER_REQUESTED, removeVoucher);
  yield takeLatest(ActionTypes.EDIT_ORDER_REQUESTED, editOrder);
  yield takeLatest(ActionTypes.REMOVE_AX_BASKET_REQUESTED, deleteAxBasket);
  yield takeLatest(
    ActionTypes.ADD_ALL_SHOPPING_LIST_ITEMS_REQUESTED,
    addAllShoppingListToBasket
  );
}
