export default {
  accounts: null,
  activateAccount: null,
  aggression: null,
  analytics: null,
  adyen: null,
  blogCategories: null,
  blogPost: null,
  blogPosts: null,
  blogPostsLatest: null,
  blogPostsSearch: null,
  login: null,
  loginToken: null,
  auth: null,
  basket: null,
  basketItems: null,
  branch: null,
  branchList: null,
  branchLocator: null,
  brochures: [],
  checkout: null,
  cleanMode: false,
  cms: null,
  currentRoute: null,
  pbbDefaultBank: null,
  driverInstruction: '',
  basketHashMap: null,
  errors: null,
  favourites: [],
  fulfillmentDetails: null,
  init: null,
  isMobile: false,
  isStaffAccount: false,
  isUseDefaultPbb: true,
  jjContent: null,
  loading: {
    searchResults: null,
    productDetail: false,
    updatingBasket: false,
    addingToBasket: false,
    loadMarketing: false,
    updateMarketing: false,
    offers: false,
    orderHistory: false,
    cancellingOrder: false,
    recommended: false,
    auth: true,
    getNearestBranch: false,
  },
  loginRedirect: null,
  lookup: null,
  marketingOptions: null,
  modal: null,
  nextRoute: null,
  offers: null,
  offersToday: null,
  openingTimes: null,
  order: null,
  orderHistory: null,
  page: null,
  password: null,
  paymentDetails: null,
  prerender: null,
  prevRoute: null,
  previouslyPurchased: null,
  productDetail: null,
  profile: null,
  promoTotal: null,
  refreshToken: null,
  route: null,
  recommendedItems: null,
  regPasswordReset: null,
  reloginAccount: null,
  shoppingListLookup: null,
  shoppingLists: null,
  slots: null,
  searchResults: [],
  searchSuggestions: [],
  settings: null,
  shopBySectorsReducer: null,
  status: null,
  systemMessages: null,
  substitutes: null,
  vacancies: [],
  upsellingItems: null,
};
