import React from 'react';
import {
  BlogCategoryBlock,
  CategoryTitle,
  BackgroundBox,
  TitleText,
} from './BlogPage.style';
export const CategoryBlockItem = ({bgImg, label, slug}) => {
  return (
    <BlogCategoryBlock bgImg={bgImg} to={`/blog/${slug}`}>
      <CategoryTitle>
        <TitleText>{label}</TitleText>
        <BackgroundBox />
      </CategoryTitle>
    </BlogCategoryBlock>
  );
};
