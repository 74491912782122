import React from 'react';
import {LocationWrapper} from './VacanciesPage.style';

export const Location = props => {
  const {location, isActive, setLocationFilter} = props;
  const setLocation = () => setLocationFilter(location);
  return (
    <LocationWrapper isActive={isActive} onClick={setLocation}>
      {location}
    </LocationWrapper>
  );
};
