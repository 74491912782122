import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Wrapper, Head, Title, Container} from '../../styles/pages/pages';
import Product from '../../components/Product/Product';
import Loading from '../../components/Loading/Loading';
import {getPreviouslyPurchased} from '../../actions/previouslyPurchased.action';
import {NoItemsAvailable} from './PreviouslyPurchasedPage.style';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  PREVIOUSLY_PURCHASED_PARAMS,
  ROUTES,
  SCROLL_THRESHOLD,
} from '../../constants/constants';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import {setCurrentRoute} from '../../actions';
import ToTheTop from '../../components/ToTheTop/ToTheTop';
import {setPrevRoute} from '../../actions/prevRoute.action';

class PreviouslyPurchasedPage extends PureComponent {
  state = {
    params: PREVIOUSLY_PURCHASED_PARAMS,
  };
  componentDidMount() {
    const {
      getPreviouslyPurchased,
      setCurrentRoute,
      prevRoute,
      history,
    } = this.props;
    const isNewSearch =
      history.location &&
      history.location.state &&
      history.location.state.newSearch;
    setCurrentRoute(ROUTES.RECENT_PURCHASES);
    if (prevRoute === ROUTES.PRODUCT && !isNewSearch) {
      return;
    }
    getPreviouslyPurchased(this.state.params.page, this.state.params);
  }

  componentDidUpdate(prevProps) {
    const {auth, getPreviouslyPurchased} = this.props;
    if (auth && auth !== prevProps.auth) {
      getPreviouslyPurchased(this.state.params.page, this.state.params);
    }
  }
  loadMore = () => {
    const {getPreviouslyPurchased, previouslyPurchased} = this.props;
    const {params} = this.state;
    getPreviouslyPurchased(previouslyPurchased.page.number + 1, params);
  };
  render() {
    const {
      basket,
      previouslyPurchased,
      loading,
      branch,
      updateBasket,
      updateItem,
      removeItem,
      basketHashMap,
      onProductClick,
      deviceInfo,
      keywordSearch,
      setPrevRoute,
    } = this.props;
    const navToProduct = itemId => {
      setPrevRoute(ROUTES.RECENT_PURCHASES);
      onProductClick(itemId);
    };
    const previouslyPurchasedItems =
      (previouslyPurchased &&
        previouslyPurchased._embedded &&
        previouslyPurchased._embedded.products &&
        syncBasketQuantityWithProducts(
          basketHashMap,
          previouslyPurchased._embedded.products
        )) ||
      [];
    const renderLoading = loading && loading.gettingProducts && (
      <Loading isLight={false} />
    );
    const renderPreviouslyPurchased =
      previouslyPurchasedItems && previouslyPurchasedItems.length > 0 ? (
        previouslyPurchasedItems.map((product, index) => (
          <Product
            product={product}
            branch={branch}
            key={product.itemId + index}
            onProductClick={navToProduct}
            loading={loading}
            basketHashMap={basketHashMap}
            updateBasket={updateBasket}
            updateItem={updateItem}
            removeItem={removeItem}
            fulfillmentType={basket && basket.fulfillmentType}
            deviceInfo={deviceInfo}
            keywordSearch={keywordSearch}
          />
        ))
      ) : (
        <NoItemsAvailable>
          No previously purchased items available
        </NoItemsAvailable>
      );
    const renderGoTop = previouslyPurchased &&
      previouslyPurchased.page &&
      previouslyPurchased.page.number > 0 && <ToTheTop />;
    const hasMore =
      previouslyPurchased &&
      previouslyPurchased.page &&
      previouslyPurchased.page.number < previouslyPurchased.page.totalPages - 1;
    const renderContent = (
      <InfiniteScroll
        next={this.loadMore}
        hasMore={hasMore}
        dataLength={previouslyPurchasedItems.length}
        scrollThreshold={SCROLL_THRESHOLD}
        loader={<Loading isLight={false} />}
      >
        {renderPreviouslyPurchased}
      </InfiniteScroll>
    );
    return (
      <Wrapper>
        <Head>
          <Title>My Recent Purchases</Title>
        </Head>
        <Container>
          {renderContent}
          {renderLoading}
          {renderGoTop}
        </Container>
      </Wrapper>
    );
  }
}
const mapStateToProps = state => ({
  previouslyPurchased: state.previouslyPurchased,
  auth: state.auth,
  loading: state.loading,
  prevRoute: state.prevRoute,
});

const mapDispatchToProps = dispatch => ({
  getPreviouslyPurchased: bindActionCreators(getPreviouslyPurchased, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviouslyPurchasedPage);
