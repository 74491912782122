import React, {PureComponent} from 'react';
import PopupModal from './PopupModal';
import {
  ERRORS,
  ORDER_SERVICE_DATE_FORMAT,
  SERVICE_TYPE,
  SLOT_FULFILMENT_STATUSES,
} from '../../constants/constants';
import moment from 'moment';

export default class SlotModal extends PureComponent {
  state = {
    message: {
      title: '',
      content: '',
      cancel: 'Cancel',
      ok: 'Ok',
      showCancelButton: false,
    },
  };

  componentDidMount() {
    const {fulfillmentStatus, fulfillment, fulfillAtSlot} = this.props;
    if (!fulfillmentStatus || !fulfillment) {
      return null;
    }
    const serviceType = fulfillment.delivery
      ? SERVICE_TYPE.DELIVERY
      : SERVICE_TYPE.COLLECTION;
    const movedToNextAvailableDate =
      fulfillAtSlot &&
      moment(
        fulfillAtSlot && fulfillAtSlot.day,
        ORDER_SERVICE_DATE_FORMAT
      ).format('on dddd Do MMMM');
    const movedToNextAvailableTime =
      fulfillAtSlot &&
      `${fulfillAtSlot && fulfillAtSlot.from} - ${fulfillAtSlot &&
        fulfillAtSlot.to}`;
    let message = {
      title: '',
      content: '',
      cancel: '',
    };
    let showCancelButton = false;
    if (fulfillmentStatus === SLOT_FULFILMENT_STATUSES.SLOT_NOT_AVAILABLE) {
      message = {
        title: 'Slot not available',
        content:
          'There is a problem with your slot - please book another. If this problem persists please call Customer Support.',
        cancel: '',
        ok: 'OK',
      };
      showCancelButton = false;
    }
    if (
      fulfillmentStatus === SLOT_FULFILMENT_STATUSES.SLOT_ADJUSTED ||
      fulfillmentStatus === SLOT_FULFILMENT_STATUSES.TYPE_ADJUSTED
    ) {
      message = {
        title: 'Slot changed',
        content: `Your chosen ${serviceType} slot has been moved to the next available time: ${movedToNextAvailableTime} ${movedToNextAvailableDate}`,
        cancel: 'OK',
        ok: 'Change Slot',
      };
    }
    this.setState({message, showCancelButton});
  }

  callback = () => {
    const {
      fulfillmentStatus,
      closeModalCallback,
      navigateToBookSlot,
    } = this.props;
    closeModalCallback();
    if (fulfillmentStatus === SLOT_FULFILMENT_STATUSES.SLOT_NOT_AVAILABLE) {
      navigateToBookSlot();
    }
  };
  cancelCallback = () => {
    const {closeModalCallback} = this.props;
    closeModalCallback();
  };
  render() {
    const {showModal, closeModalCallback} = this.props;
    const {message, showCancelButton} = this.state;
    return (
      <PopupModal
        modalName={'Slot modal'}
        showOKButton={true}
        showCancelButton={showCancelButton}
        cancelButtonText={message.cancel}
        callback={this.callback}
        cancelCallBack={this.cancelCallback}
        closeModalCallback={closeModalCallback}
        showModal={showModal}
        content={message.content}
        subContent={message.subContent}
        title={message.title}
        isError={true}
        loading={this.state.loading}
      />
    );
  }
}
