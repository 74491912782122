import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

const strapiUrl = '/api/olympus/strapi';
export default class blogApi {
  static getAllBlogCategories(hostDomain) {
    const config = getApiConfig();
    return axios
      .get(`${hostDomain || ''}${config.strapiUrl || strapiUrl}/categoriesHome`)
      .then(res => res.data);
  }

  static getAllBlogCategoryBySlug(slug, hostDomain) {
    const config = getApiConfig();
    return axios
      .get(
        `${hostDomain || ''}${config.strapiUrl ||
          strapiUrl}/postsBySlug/${slug}`
      )
      .then(res => res.data);
  }

  static getBlogPostById(postId) {
    const config = getApiConfig();
    return axios
      .get(`${config.strapiUrl || strapiUrl}/post/${postId}`)
      .then(res => res.data);
  }

  static getBlogPostBySlug(postSlugName, hostDomain) {
    const config = getApiConfig();
    const url = `${hostDomain || ''}${config.strapiUrl ||
      strapiUrl}/postBySlug/${postSlugName}`;
    return axios.get(url).then(res => res.data);
  }

  static getBlogPostByTitle(title, hostDomain) {
    const config = getApiConfig();
    return axios
      .get(
        `${hostDomain || ''}${config.strapiUrl ||
          strapiUrl}/postByTitle/${title}`
      )
      .then(res => res.data);
  }

  static getLatestBlogPosts() {
    const config = getApiConfig();
    return axios
      .get(`${config.strapiUrl || strapiUrl}/latestPosts/3`)
      .then(res => res.data);
  }
}
