import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getBlogPostBySlug} from '../../actions/blog.action';
import {
  Wrapper,
  CategoryBlocks,
  MainTitle,
  PostNode,
  BreadCrumb,
  PostBar,
  BlogError,
  CommentWrapper,
  CommentUser,
  CommentDate,
  CommentText,
  RelatedPostWrapper,
  RelatedPostTitle,
  RelatedPostExcerpt,
  RelatedPosts,
  Comments,
} from './BlogPage.style';
import {Head, Title} from '../../styles/pages/pages';
import {
  BreadCrumbLinks,
  BreadCrumbResult,
  CurrentPage,
  Separator,
} from '../SearchPage/SearchPage.style';
import {DEFAULT_DATE_FORMAT} from '../../constants/constants';
import moment from 'moment';
import {replaceContentUploadUrls} from '../../helpers/data.helper';

const BlogPostPage = ({match}) => {
  const {isTablet, blogPost, loading} = useSelector(state => ({
    isTablet: state.isTablet,
    blogPost: state.blogPost,
    loading: state.loading,
  }));
  const [isGettingPost, setIsGettingPost] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      match.params.postSlugName &&
      !isGettingPost &&
      (!blogPost ||
        (blogPost && blogPost.attributes.slug !== match.params.postSlugName))
    ) {
      setIsGettingPost(true);
      dispatch(getBlogPostBySlug(match.params.postSlugName));
    }
  }, [match && match.params, blogPost]);
  const renderTitle = blogPost && blogPost.attributes.title && (
    <MainTitle>{blogPost.attributes.title}</MainTitle>
  );
  const renderError = blogPost && blogPost.error && (
    <BlogError>Failed to fetch blog post, please try again.</BlogError>
  );
  const renderContent = blogPost &&
    blogPost.attributes &&
    blogPost.attributes.content && (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceContentUploadUrls(blogPost.attributes.content),
        }}
      ></div>
    );
  const renderComments = blogPost &&
    blogPost.attributes.commentsEnabled &&
    blogPost.attributes &&
    blogPost.attributes.comments &&
    blogPost.attributes.comments.data &&
    blogPost.attributes.comments.data.length > 0 && (
      <Comments>
        <h3>Customer's Comments</h3>
        {blogPost.attributes.comments.data.map(comment => (
          <CommentWrapper key={comment.id}>
            <CommentUser>{comment.attributes.user}</CommentUser>
            <CommentDate>
              {moment(comment.attributes.createdAt).format(DEFAULT_DATE_FORMAT)}
            </CommentDate>
            <CommentText>{comment.attributes.comment}</CommentText>
          </CommentWrapper>
        ))}
      </Comments>
    );

  const renderRelatedPosts = blogPost &&
    blogPost.attributes &&
    blogPost.attributes.relatedPosts &&
    blogPost.attributes.relatedPosts.data &&
    blogPost.attributes.relatedPosts.data.length > 0 && (
      <RelatedPosts>
        <h3>Related Posts</h3>
        {blogPost.attributes.relatedPosts.data.map(post => (
          <RelatedPostWrapper key={post.id}>
            <RelatedPostTitle to={`/blog/post/${post.attributes.slug}`}>
              {post.attributes.title}
            </RelatedPostTitle>
            <RelatedPostExcerpt>{post.attributes.excerpt}</RelatedPostExcerpt>
          </RelatedPostWrapper>
        ))}
      </RelatedPosts>
    );

  const renderLastUpdate = !!blogPost && (
    <PostBar>
      {(!!blogPost.attributes.wixUpdatedAt ||
        !!blogPost.attributes.updatedAt) &&
        `Updated: ${moment(
          blogPost.attributes.wixUpdatedAt || blogPost.attributes.updatedAt
        ).format(DEFAULT_DATE_FORMAT)}`}
    </PostBar>
  );
  const isDefaultCategoryCrumb =
    match.params && match.params.slugName && match.params.slugName !== 'post';
  const renderCategoryCrumb = isDefaultCategoryCrumb && (
    <BreadCrumbLinks to={`/blog/${match.params.slugName}`}>
      {match.params.slugName.replace('-', ' ')}
    </BreadCrumbLinks>
  );
  const renderSeparator = isDefaultCategoryCrumb && <Separator>&gt;</Separator>;
  return (
    <Wrapper>
      <Head>
        <Title />
      </Head>
      <CategoryBlocks>
        <BreadCrumb>
          <BreadCrumbLinks to="/">Home &gt; </BreadCrumbLinks>
          <BreadCrumbLinks to="/blog">Blog &gt; </BreadCrumbLinks>
          {renderCategoryCrumb}
          <CurrentPage noPadding={!isDefaultCategoryCrumb}>
            {renderSeparator}
            <BreadCrumbResult>
              {blogPost && blogPost.attributes.title}
            </BreadCrumbResult>
          </CurrentPage>
        </BreadCrumb>
        <PostNode>
          {renderTitle}
          {renderLastUpdate}
          {renderError}
          {renderContent}
          {renderRelatedPosts}
          {renderComments}
        </PostNode>
      </CategoryBlocks>
    </Wrapper>
  );
};
export default BlogPostPage;
