import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import loading from '../../images/loading.svg';
import {Spinner} from './Loading.style';

export class Loading extends PureComponent {
  render() {
    return (
      <Spinner isLight={this.props.isLight}>
        <ReactSVG src={loading} />
      </Spinner>
    );
  }
}

Loading.propTypes = {
  isLight: PropTypes.bool,
};

Loading.defaultProps = {
  isLight: true,
};

export default Loading;
