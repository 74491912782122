import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  TextWrapper,
  OK,
} from './PopupModal.style';
import moment from 'moment-timezone';
import {getCurrentUTCTime} from '../../helpers/data.helper';
import {H_MM_A_FORMAT, TIME_ZONE} from '../../constants/constants';

export default class SlotsModal extends PureComponent {
  state = {
    timeLeft: null,
    cutOffTime: null,
    isExpired: this.props.isExpired,
  };
  componentDidMount() {
    const {slotTimestamp} = this.props;
    this.handleTimeLeft();
    const cutOffTime = moment
      .tz(slotTimestamp, TIME_ZONE)
      .format(H_MM_A_FORMAT);
    this.setState({cutOffTime});
    this.interval = setInterval(this.handleTimeLeft.bind(this), 60 * 1000);
  }

  static getDerivedStateFromProps(props, state) {
    const {timeLeft} = state;
    if (timeLeft === 0) {
      return {
        isExpired: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isExpired && !prevState.isExpired) {
      clearInterval(this.interval);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleTimeLeft() {
    const {slotTimestamp} = this.props;
    const currentUtcTime = getCurrentUTCTime();
    const timeLeft =
      slotTimestamp && moment(slotTimestamp).diff(currentUtcTime, 'minutes');
    const aHour = 60;
    if (timeLeft <= aHour) {
      clearInterval(this.interval);
    }
    this.setState({timeLeft});
  }
  render() {
    const {isDelivery, showModal, callback} = this.props;
    const {isExpired, timeLeft, cutOffTime} = this.state;
    const renderTitle = isExpired
      ? 'Sorry, your slot has expired'
      : 'Your slot will expire soon';
    const renderContent = isExpired
      ? `Please book a new one.`
      : isDelivery
      ? `Please note you must checkout before ${cutOffTime} to receive this order tomorrow. You have ${timeLeft} minutes remaining.`
      : `Checkout within ${timeLeft} minutes or choose a later slot.`;
    const renderInfo = isExpired && <p>Your current slot will be removed</p>;
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={showModal}
        closeModal={callback}
        data-modal-name={'slots modal'}
      >
        <StyledWrapper isError={isExpired}>
          <Content>
            <TextWrapper>
              <Title isError={isExpired}>{renderTitle}</Title>
              <p>{renderContent}</p>
              {renderInfo}
              <OK isError={isExpired} onClick={callback}>
                Ok
              </OK>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
