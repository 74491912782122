import styled from 'styled-components';
import {WayDown, WayUp} from '../../styles/components/arrow';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  border: ${p => `solid 0.1rem ${p.theme.borderDarkColor}`};
  margin-bottom: 0.4rem;
`;

export const TheHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  min-height: 4rem;
  max-height: 4rem;
  overflow: hidden;
  cursor: pointer;
  color: ${p => (p.isApplied ? p.theme.primaryColor : p.theme.textColor)};
`;

export const Content = styled.div`
  display: ${p => (p.isShown ? 'block' : 'none')};
  padding: 0 0 1rem 0;
`;

export const StyledItem = styled.div`
  position: relative;
  padding: 0.4rem 0 0 1.2rem;
`;

export const StyledFilterItem = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 1.2rem;
  padding: 0;
  min-height: 2rem;
  user-select: none;
  input[type='checkbox']:checked + label {
    color: ${props => props.theme.primaryColor};
  }
  input[type='checkbox']:checked + label:before {
    border: ${p => `solid 0.1rem ${p.theme.primaryColor}`};
  }
  input[type='checkbox']:checked + label:after {
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;
    background-color: ${p => p.theme.secondaryColor};
    width: 0.6rem;
    height: 0.6rem;
    content: '';
    display: block;
  }
`;

export const ArrowUp = styled(WayDown)`
  margin-top: 0.3rem;
  &::before {
    color: ${props => props.theme.secondaryColor};
    font-size: 2.4rem;
    font-weight: lighter;
  }
`;

export const ArrowDown = styled(WayUp)`
  padding-left: 0.2rem;
  transform-origin: center 1rem 0;
  &::before {
    color: ${props => props.theme.primaryColor};
    font-size: 2.4rem;
    font-weight: lighter;
  }
`;

export const CheckBox = styled.input`
  display: none;
`;

export const ItemLabel = styled.label`
  display: inline;
  cursor: pointer;
  padding-left: 1.6rem;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    border: ${p => `solid 0.1rem ${p.theme.tertiaryTextColor}`};
    width: 1.2rem;
    height: 1.2rem;
    content: '';
    display: block;
  }
`;

export const Number = styled.span`
  margin-left: 0.4rem;
`;
