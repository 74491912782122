import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  TextWrapper,
  ErrorButton,
  ButtonsWrapper,
  Link,
} from './PopupModal.style';
import Loading from '../Loading/Loading';
import {JJ_FORM, JJ_TEL, JJ_TEL_FULL} from '../../constants/constants';

export default class ContactAdminModal extends PureComponent {
  render() {
    const {
      showModal,
      callback,
      isLoadingModal,
      closeModalCallback,
      isDeliveryDisabled,
    } = this.props;
    const rendering = isLoadingModal ? (
      <Loading isLight={false} />
    ) : (
      <ButtonsWrapper>
        <ErrorButton onClick={callback}>OK</ErrorButton>
      </ButtonsWrapper>
    );
    const renderTitle = isDeliveryDisabled
      ? 'Delivery is not available'
      : 'Your account seems to have a problem';
    const renderContent = isDeliveryDisabled
      ? 'Please contact us on'
      : 'Please try to login again. If you continue to see this message you can call us on';
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
      >
        <StyledWrapper isError={true}>
          <Content>
            <TextWrapper>
              <Title isError={true}>{renderTitle}</Title>
              <p>
                {renderContent}{' '}
                <Link href={`tel:${JJ_TEL_FULL}`}>{JJ_TEL}</Link> or complete
                our{' '}
                <Link href={JJ_FORM} target="_blank">
                  support form.
                </Link>
              </p>
              {rendering}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
