import {captureException} from '@sentry/browser';
import moment from 'moment';
import {
  A_DAY,
  BRANCH_MAPPING,
  BRANCH_NAME_MAPPING,
  JJ_DOMAIN,
  PAGE_SIZE,
  SEARCH_ADVANCED_PARAMS,
  SORT_TYPES,
  STANDARD_FORMAT,
} from '../constants/constants';
import {
  setBranch,
  setBranchOpeningTimes,
  setNearestBranch,
} from '../actions/branch.action';
import productApi from '../api/product.api';
import {
  checkIfStringIsDefined,
  checkProductDetails,
  getBranchFromUrl,
  getCategoryName,
  getCategoryNamesFromUrl,
  getKeyCaseInsensitive,
  getTodayDate,
  getValidUSDate,
  processedGQLSearchResults,
  processedProductDetail,
  titleCase,
  trimComma,
} from '../helpers/data.helper';
import {
  getFacetsFromServerSide,
  getSearchFromServerSide,
  getSearchPageFromServerSide,
  setProductDetail,
} from '../actions';
import SearchApi from '../api/search.api';
import {getApiConfig, parseConfig} from '../config/configProvider';
import {getOgData, getSchemaOpeningTimes} from '../helpers/seo.helper';
import {setCleanMode} from '../actions/cleanMode.action';
import {setShopBySectors} from '../actions/offers.action';
import {arrayToObject} from '../helpers/array.helper';
import {ServerCacheHelper} from '../helpers/ssr.helper';
import logo from '../images/logo.png';
import {
  setBlogCategories,
  setBlogCategoryBySlug,
  setBlogPost,
} from '../actions/blog.action';
import blogApi from '../api/blog.api';
import {strapiImage} from '../helpers/image.helper';
import jjContentApi from '../api/jjContent.api';

export const loadProductDetailsData = async (match, store) => {
  // only use for server side rendering
  if (typeof window !== 'undefined') {
    return Promise.resolve(null);
  }
  const config = getApiConfig();
  const branchFromUrl = getBranchFromUrl(match);
  const deliveryDate = moment(new Date()).format(STANDARD_FORMAT);
  if (store) {
    store.dispatch(setBranch(branchFromUrl));
  }
  const isRunningInternalDefault =
    process.env.CONFIG_JS && process.env.NODE_LOCAL_DEV !== 'true';
  try {
    const branchSpecificProductDetail = await productApi.getProduct({
      itemIds: [match.params && match.params.itemId],
      deliveryDate,
      branch: branchFromUrl,
      host: isRunningInternalDefault
        ? parseConfig(process.env.CONFIG_JS).graphQlInternal
        : config.graphQL,
    });
    if (
      branchSpecificProductDetail &&
      branchSpecificProductDetail.errors &&
      branchSpecificProductDetail.errors.length > 0
    ) {
      return Promise.resolve({og: false});
    }
    if (checkProductDetails(branchSpecificProductDetail)) {
      const genericProductDetail = await productApi.getProduct({
        itemIds: [match.params && match.params.itemId],
        deliveryDate,
        branch: '',
        host: isRunningInternalDefault
          ? parseConfig(process.env.CONFIG_JS).graphQlInternal
          : config.graphQL,
      });
      const genericProductDetailData =
        processedProductDetail(
          genericProductDetail.data.searchProductsByItemId.results[0]
        ) || null;

      if (store && genericProductDetailData) {
        store.dispatch(setProductDetail(genericProductDetailData));
      }
      const og = getOgData(genericProductDetailData);
      return Promise.resolve(og);
    } else {
      const branchSpecificProductDetailData =
        processedProductDetail(
          branchSpecificProductDetail.data.searchProductsByItemId.results[0]
        ) || null;
      if (store && branchSpecificProductDetailData) {
        store.dispatch(setProductDetail(branchSpecificProductDetailData));
      }
      const og = getOgData(branchSpecificProductDetailData);
      return Promise.resolve(og);
    }
  } catch (e) {
    console.log(e, 'ssr load data error');
    captureException(e);
    return Promise.resolve({og: false});
  }
};

export const loadSearchData = (match, store) => {
  let og;
  const config = getApiConfig();
  const host =
    process.env.CONFIG_JS && process.env.NODE_LOCAL_DEV !== 'true'
      ? parseConfig(process.env.CONFIG_JS).graphQlInternal
      : config.graphQL;
  const branchFromUrl = getBranchFromUrl(match);
  store.dispatch(setBranch(branchFromUrl));
  const {brandName, category1Name, category2Name, category3Name} = match.params;
  let params = Object.assign({}, SEARCH_ADVANCED_PARAMS, {
    b: branchFromUrl,
    size: PAGE_SIZE.M,
    deliveryDate: getTodayDate(),
  });
  if (brandName) {
    params = {...params, ...{brand: decodeURIComponent(brandName)}};
  }
  const categoryNames = getCategoryNamesFromUrl(
    category1Name,
    category2Name,
    category3Name
  );
  if (categoryNames.length > 0) {
    params = {
      ...params,
      ...{sortType: SORT_TYPES.CATEGORY},
      ...{categoryNames},
    };
  }
  try {
    const getSearch = SearchApi.search(params, null, host);
    return Promise.all([getSearch]).then(responses => {
      if (
        responses[0] &&
        responses[0].data &&
        responses[0].data.searchProducts
      ) {
        if (responses[0].data.searchProducts.results) {
          const searchResults =
            processedGQLSearchResults(
              responses[0].data.searchProducts.results
            ) || [];
          store.dispatch(getSearchFromServerSide(searchResults));
          store.dispatch(
            getSearchPageFromServerSide(responses[0].data.searchProducts.page)
          );
        }
        if (responses[0].data.searchProducts.facets) {
          store.dispatch(
            getFacetsFromServerSide(responses[0].data.searchProducts.facets)
          );
        }
        let title = '';
        if (brandName) {
          title = brandName;
        }
        if (category1Name) {
          title = category1Name;
        }
        if (category2Name) {
          title = category2Name;
        }
        if (category3Name) {
          title = category3Name;
        }
        if (categoryNames.length > 0) {
          title = getCategoryName(categoryNames);
        }
        title = titleCase(trimComma(title));
        og = {
          og: true,
          title: `Buy Wholesale ${title} From JJ Foodservice For Delivery or Collection`,
          description: `View and order online from our range of wholesale ${title} at JJ Foodservice. Open to all.`,
        };
        return Promise.resolve(og);
      }
    });
  } catch (e) {
    captureException(e);
  }
};

export const loadSearchResultsData = title => {
  return {
    og: true,
    title: `Buy Wholesale ${title} From JJ Foodservice For Delivery or Collection`,
    description: `View and order online from our range of wholesale ${title} at JJ Foodservice. Open to all.`,
  };
};

export const loadHomePageData = (match, store, req) => {
  // only use for server side rendering
  if (typeof window !== 'undefined') {
    return Promise.resolve(null);
  }
  const hostDomain = `${req.protocol}://${req.hostname}`;
  return jjContentApi
    .getContentBySlug('shop-by-sectors', hostDomain)
    .then(res => {
      if (store && res) {
        store.dispatch(setShopBySectors(res));
      }
      return Promise.resolve({});
    })
    .catch(e => {
      captureException(e);
      return Promise.resolve({});
    });
};

export const loadBranchLocatorData = (match, store) => {
  // only use for server side rendering
  if (typeof window !== 'undefined') {
    return Promise.resolve(null);
  }
  const defaultOGData = {
    og: true,
    title:
      'JJ Foodservice Branch Finder - Find Your Nearest Branch For Wholesale Food & Catering Supplies in England',
    description:
      'Enter your postcode on our branch finder tool to find your nearest JJ Foodservice branch in England for collection orders.',
  };
  const branchId = getKeyCaseInsensitive(
    BRANCH_NAME_MAPPING,
    match.params.branchName
  );
  if (!branchId) {
    return Promise.resolve(defaultOGData);
  }
  const cachedopeningTimes = ServerCacheHelper.get('branches');
  const ogBranchData = ServerCacheHelper.get(`ogData-${branchId}`);
  if (cachedopeningTimes) {
    store.dispatch(setBranchOpeningTimes(cachedopeningTimes));
  }
  if (cachedopeningTimes && ogBranchData) {
    return Promise.resolve({...defaultOGData, ogBranchData});
  }

  const openingTimes = arrayToObject(cachedopeningTimes, 'inventLocationId');
  const currentBranch = openingTimes[branchId];
  store.dispatch(setNearestBranch(currentBranch));
  const newOgBranchData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: `JJ Foodservice ${currentBranch.branchName}`,
    '@id': `https://jjfoodservice.com/branch/${BRANCH_MAPPING[
      branchId
    ].toLowerCase()}`,
    url: `https://jjfoodservice.com/branch/${BRANCH_MAPPING[
      branchId
    ].toLowerCase()}`,
    description:
      'Enter your postcode on our branch finder tool to find your nearest JJ Foodservice branch in England for collection orders.',
    telephone: currentBranch.contact && currentBranch.contact.phone,
    email: currentBranch.contact && currentBranch.contact.email,
    logo: {
      '@type': 'ImageObject',
      url: `https://${JJ_DOMAIN.PROD}${logo}`,
      width: 560,
      height: 92,
    },
    image: logo,
    currenciesAccepted: 'GBP',
    paymentAccepted: 'Cash, Credit Card',
    hasMap: `https://www.google.com/maps/search/JJ+Foodservice+Limited+${encodeURI(
      currentBranch.branchName
    )}/`,
    sameAs: [
      'https://www.facebook.com/jjfoodservice',
      'https://www.youtube.com/channel/UCt4UlV3a_3DW_-Zlrqn9EVQ',
    ],
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress:
          currentBranch.address &&
          currentBranch.address.address.replace(/(\r\n|\n|\r)/gm, ', '),
        addressRegion: currentBranch.city,
        postalCode: currentBranch.address && currentBranch.address.zipcode,
        addressCountry: 'England',
      },
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: currentBranch.contact && currentBranch.contact.phone,
        contactType: 'customer service',
      },
    ],
    geo: {
      '@type': 'GeoCoordinates',
      latitude: currentBranch.latitude,
      longitude: currentBranch.longitude,
    },
    openingHoursSpecification:
      currentBranch.collectionOpeningTime &&
      getSchemaOpeningTimes(currentBranch.collectionOpeningTime),
  };
  store.dispatch(setBranchOpeningTimes(openingTimes));
  ServerCacheHelper.set('openingTimes', openingTimes, A_DAY);
  ServerCacheHelper.set(`ogData-${branchId}`, ogBranchData, A_DAY);
  return Promise.resolve({...defaultOGData, ogBranchData: newOgBranchData});
};

export const loadPackagingPageData = () => {
  return {
    og: true,
    title:
      'Buy Wholesale Takeaway Packaging Wholesales From JJ Foodservice For Delivery or Collection',
    description:
      'View and order online from our range of wholesale Takeaway Packaging at JJ Foodservice. Choose from a wide range of wholesale food packaging supplies & catering containers: takeaway boxes, bags, cups and more',
  };
};

export const setAppCleanMode = (match, store) => store.dispatch(setCleanMode());

export const loadWixCategories = async (match, store, req) => {
  try {
    const hostDomain = `${req.protocol}://${req.hostname}`;
    const categories = await blogApi.getAllBlogCategories(hostDomain);
    if (categories) {
      store.dispatch(setBlogCategories(categories));
      return Promise.resolve({categories: true});
    }
    return Promise.resolve({categories: false});
  } catch (e) {
    captureException(e);
  }
};

export const loadWixPostsBySlug = async (match, store, req) => {
  try {
    const hostDomain = `${req.protocol}://${req.hostname}`;
    const posts = await blogApi.getAllBlogCategoryBySlug(
      match.params.slugName,
      hostDomain
    );
    if (posts) {
      store.dispatch(setBlogCategoryBySlug(posts));
      return Promise.resolve({});
    }
    return Promise.resolve({});
  } catch (e) {
    captureException(e);
  }
};

export const loadWixPost = async (match, store, req) => {
  try {
    const hostDomain = `${req.protocol}://${req.hostname}`;
    const blogPost = await blogApi.getBlogPostBySlug(
      match.params.postSlugName,
      hostDomain
    );
    if (blogPost) {
      store.dispatch(setBlogPost(blogPost));
      return Promise.resolve({
        og: true,
        title: blogPost.attributes.title,
        description: blogPost.attributes.excerpt,
        image: strapiImage(blogPost).full,
      });
    }
    return Promise.resolve({
      og: true,
      title: 'No blog post',
    });
  } catch (e) {
    captureException(e);
  }
};
