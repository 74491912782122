import {put, all, take, select, fork, join} from 'redux-saga/effects';
import searchApi from '../api/search.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {processedGQLSearchResults} from '../helpers/data.helper';
import {waitFor} from '../helpers/saga.helper';
import {getDeliveryDate} from '../helpers/search.helper';
import {DEFAULT_BRANCH} from '../constants/constants';

export default function* search() {
  while (true) {
    const action = yield take(ActionTypes.GET_SEARCH_RESULTS_REQUESTED);
    try {
      const getAuth = state => state.auth;
      const getBasket = state => state.basket;
      const getSettings = state => state.settings;
      const getProfile = state => state.profile;
      const getBranchId = state => state.branch;
      let storeToken = yield select(getAuth);
      const basket = yield select(getBasket);
      const settings = yield select(getSettings);
      const headers = getBearerHeader(action.jjToken, storeToken);

      let b = action.params.b;
      const times = 5;
      const ms = 300;
      if (!storeToken) {
        storeToken = yield waitFor(getAuth, 3, ms);
      }
      // check if basket/settings/profile contains branchId
      if (storeToken) {
        const theBasket = yield waitFor(getBasket, times, ms);
        b = theBasket && theBasket.branchId;
      } else {
        b = yield waitFor(getBranchId, times, ms);
      }
      const deliveryDate = getDeliveryDate(
        basket,
        action.fulfillmentDetails,
        settings
      );
      let params = action.params;
      params.q = decodeURIComponent(params.q);
      if (deliveryDate) {
        params = Object.assign({}, params, {deliveryDate});
      }
      params = {...params, ...{b: b || DEFAULT_BRANCH}};

      const searchResultsFork = yield fork(searchApi.search, params, headers);
      const result = yield join(searchResultsFork);
      if (!result || result.errors) {
        yield put({
          type: ActionTypes.GET_SEARCH_RESULTS_FAILED,
          message: result.errors[0],
        });
        return;
      }
      yield all([
        put({
          type: ActionTypes.GET_SEARCH_RESULTS_SUCCESS,
          searchResults:
            result &&
            result.data &&
            result.data.searchProducts &&
            result.data.searchProducts.results &&
            processedGQLSearchResults(result.data.searchProducts.results),
        }),
        put({
          type: ActionTypes.GET_SEARCH_RESULTS_PAGE_SUCCESS,
          page:
            result &&
            result.data &&
            result.data.searchProducts &&
            result.data.searchProducts.page,
        }),
        put({
          type: ActionTypes.GET_SEARCH_AGGREGATE_SUCCESS,
          aggression:
            result &&
            result.data &&
            result.data.searchProducts &&
            result.data.searchProducts.facets,
        }),
      ]);
    } catch (error) {
      yield put({
        type: ActionTypes.GET_SEARCH_RESULTS_FAILED,
        error,
      });
    }
  }
}
