import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {LinkStyle} from '../../styles/components/links';

import {Content} from '../../styles/pages/pages';
import {Button3Inverted} from '../../styles/components/buttons';
import {DropdownStandard} from '../../styles/components/dropdown';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const ContentSection = styled(Content)`
  border-bottom: none;
`;

export const AccountContent = styled(ContentSection)`
  margin-top: 0;
`;

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.medium`
    padding: 0 2rem;
    display: block;
  `};
  ${media.smaller`
    flex-direction: column;
    padding: 2rem;
  `};
`;

export const Row = styled.div`
  width: 100%;
  padding: 0 1rem 1rem;
  display: flex;
  align-items: center;
  line-height: 4rem;
  min-height: 4.8rem;
  font-size: 1.6rem;
  ${media.smaller`
    display: block;
  `};
  &:only-child {
    padding: 0 0 0 2rem;
  }
`;

export const Title = styled.span`
  color: ${p => p.theme.secondaryColor};
  text-align: ${p => (p.left ? 'left' : 'center')};
  font-weight: bold;
  ${media.smaller`
    min-width: 40%;
  `};
`;

export const BlockContent = styled.div`
  padding: 1rem 1rem 0;
`;

export const Block = styled.div`
  padding: 1rem;
  border-bottom: solid 1rem ${p => p.theme.secondaryBgColor};
`;

export const HeaderSection = styled.div`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AccountRow = styled.div`
  display: flex;
  align-items: flex-end;
  color: ${p => p.theme.textColor};
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ChangePassword = styled.div`
  color: ${p => p.theme.primaryColor};
  font-size: 1.4rem;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 1rem;
  cursor: pointer;
`;

export const HeaderTitle = styled.span`
  color: ${p => p.theme.secondaryColor};
  padding-right: 0.5rem;
`;

export const Info = styled.div`
  display: flex;
  font-size: 1.6rem;
  align-items: flex-end;
  justify-content: space-between;
  color: ${p => p.theme.textColor};
  width: 100%;
  ${media.medium`
    padding-top: 1rem;
  `};
  ${media.smaller`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

export const AccountNo = styled.span`
  padding-left: 1rem;
  ${media.smaller`
    padding-left: 0;
  `};
`;

export const Name = styled.span`
  padding-left: 2rem;
  ${media.smaller`
    padding-left: 0;
  `};
`;

export const AccountTitle = styled.div`
  width: 20rem;
  min-width: 20rem;
  line-height: 2rem;
  color: ${p => p.theme.secondaryColor};
  ${media.smaller`
    width: 100%;
  `};
`;

export const AccountDetails = styled.div`
  display: flex;
  flex-grow: 1;
  ${media.smaller`
    justify-content: space-between;
  `};
`;

export const Edit = styled.span`
  font-size: 1.4rem;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  color: ${p => p.theme.primaryColor};
`;

export const SwitchAccount = styled(Edit)`
  ${media.smaller`
    width: 100%;
    text-align: right;
  `};
`;

export const SelectorWrapper = styled.div`
  width: 20rem;
  ${media.smaller`
    margin-top: 1rem;
    width: 100%;
  `};
`;

export const Selector = styled(DropdownStandard)`
  text-transform: uppercase;
`;

export const AccountText = styled.div`
  color: ${p => p.theme.textColor};
  padding: 1rem 2rem 1rem 0;
  line-height: 2rem;
  ${media.smaller`
    padding: 1rem 1rem 1rem 0;
  `};
`;

export const AccountInput = styled.input`
  width: 50%;
  height: 4rem;
  padding: 1rem;
  outline: none;
  border: ${p => `solid 0.1rem ${p.theme.borderColor}`};
  ${media.smaller`
    width: 100%;
  `};
`;

export const AccountTextField = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: ${p => `solid 0.1rem ${p.theme.borderColor}`};
  outline: none;
  color: ${p => p.theme.textColor};
  ${media.smaller`
    width: 100%;
  `};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const TheSaveButton = styled(Button3Inverted)`
  width: 10rem;
  ${media.smaller`
    width: 100%;
  `};
`;

export const CheckboxLabel = styled.label`
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  line-height: 3rem;
  padding-right: 2rem;
`;

export const AccountTextFieldWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const ButtonLink = styled.a`
  ${LinkStyle};
  text-align: center;
  ${media.smaller`
    padding: 0 1rem;
  `};
  ${media.smallest`
    text-align: center;
  `};
`;

export const ButtonLoadingWrapper = styled.div`
  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: 3rem;
    width: 3rem;
    fill: ${p => p.theme.secondaryColor};
  }
`;
