import React, {PureComponent} from 'react';
import {
  IconWrapper,
  PreviouslyPurchasedIcon,
} from './PreviouslyPurchased.style';
import {ToolTips} from '../../styles/components/toolTips';
import {MESSAGE_TIMEOUT} from '../../constants/constants';

export default class PreviouslyPurchased extends PureComponent {
  state = {
    showToolTip: false,
  };
  componentWillUnmount() {
    this.closeToolTip();
  }
  onToolTipClick = () => {
    this.setState({showToolTip: true});
    this.timeout = setTimeout(
      () => this.setState({showToolTip: false}),
      MESSAGE_TIMEOUT
    );
  };
  openToolTip = () => {
    if (this.state.showTooTip) {
      return;
    }
    this.setState({showToolTip: true});
  };
  closeToolTip = () => this.setState({showToolTip: false});
  render() {
    const {isOffer} = this.props;
    return (
      <IconWrapper
        onMouseOver={this.openToolTip}
        onMouseLeave={this.closeToolTip}
      >
        <ToolTips show={this.state.showToolTip}>Previously Purchased</ToolTips>
        <PreviouslyPurchasedIcon
          isWhite={isOffer}
          onClick={this.onToolTipClick}
        />
      </IconWrapper>
    );
  }
}
