import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {BlockWithArrow} from '../../styles/components/blockWithArrow';
import {ArrowSwitch} from '../../styles/components/arrowSwitch';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Block = styled(BlockWithArrow)`
  width: 100%;
  max-width: 100%;
  height: 4.4rem;
  margin: 1rem 0.2rem;
  &::after {
    top: 4.4rem;
    border: ${props => (props.selected ? 'solid 0.8rem transparent' : 0)};
    border-top-width: 0.8rem;
    border-bottom-width: 0;
    border-top-color: ${props => props.theme.primaryColor};
  }
  ${media.small`
     margin: 0;
     padding: 2rem;
     justify-content: space-between;
     border-bottom: ${p => `solid 0.1rem ${p.theme.secondaryBgColor}`};
     &::after {
      display: none;
     }
  `};
`;

export const Status = styled(ArrowSwitch)`
  display: none;
  ${media.small`
    display: block;
    &::before {
      display: ${p => (p.selected ? 'none' : 'block')};
    }
    &::after {
      display: ${p => (p.selected ? 'block' : 'none')};
    }
  `};
`;

export const Content = styled.p`
  padding: 2rem;
  display: none;
  ${media.small`
    display: ${p => (p.selected ? 'block' : 'none')};
  `};
`;
