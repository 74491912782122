import React, {Fragment, PureComponent} from 'react';
import {Wrapper, StatusMessage} from './SystemStatus.style';
import ReactMarkdown from 'react-markdown';
import {markdownConvert} from '../../helpers/string.helper';

export default class SystemStatus extends PureComponent {
  state = {
    index: 0,
    messages: null,
  };

  componentDidUpdate(prevProps) {
    const {systemMessages} = this.props;
    if (systemMessages && systemMessages !== prevProps.systemMessages) {
      let messages;
      if (systemMessages.maintenance && systemMessages.maintenance.length > 0) {
        messages = systemMessages.maintenance;
      } else if (
        systemMessages.announcement &&
        systemMessages.announcement.length > 0
      ) {
        messages = systemMessages.announcement;
      }
      this.setState({messages});
    }
  }
  render() {
    const {messages} = this.state;
    if (!messages) {
      return null;
    }
    const renderMessages =
      messages &&
      messages.map((msg, i) => (
        <Fragment key={i + msg.heading}>
          <StatusMessage>
            <ReactMarkdown
              children={markdownConvert(decodeURIComponent(msg.message))}
            />
          </StatusMessage>
        </Fragment>
      ));
    return <Wrapper>{renderMessages}</Wrapper>;
  }
}
