import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3b} from '../../styles/components/buttons';
import {Content} from '../../styles/pages/pages';

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  color: ${p => p.theme.textColor};
  font-size: 1.4rem;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const LeftBlock = styled.div`
  display: flex;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const Field = styled(Block)`
  border-bottom-width: 0;
`;

export const LoadingWrapper = styled.div`
  svg {
    width: 3rem;
  }
`;

export const TheButton = styled(Button3b)`
  width: 19rem;
  &:hover {
    svg {
      fill: ${p => p.theme.primaryTextColor};
    }
  }
  ${media.smaller`
    width: 15rem;
  `};
`;

export const HiddenButton = styled.div`
  width: 0.1rem;
  height: 0.1rem;
  background-color: ${p => p.theme.primaryTextColor};
`;

export const ButtonLoading = styled.div`
  display: flex;
  justify-content: center;
  width: 19rem;
  ${media.smaller`
    width: 15rem;
  `};
`;

export const GpIframe = styled.iframe`
  display: ${p => (p.isShown ? 'flex' : 'none')};
  justify-content: center;
  border: none;
  margin: 4rem 0 0 1rem;
  min-height: 84rem;
  ${media.smaller`
    margin: 0;
  `};
  #rxp-header .rxp-title {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
`;

export const BraintreeDropIn = styled.div`
  display: flex;
  margin: 5rem 0 0;
  #dropin-container > div {
    display: flex;
    justify-content: center;
    max-width: 40rem;
    min-width: 40rem;
    margin-right: 1rem;
    margin-left: 2rem;
    .braintree-method .braintree-method__icon {
      width: 3rem;
      height: 3rem;
    }
  }
  ${media.smaller`
    margin: 2rem 1rem 1rem;
    min-height: 1rem;
    #dropin-container > div {
      max-width: 100%;
      min-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
    .braintree-dropin {
      min-width: 30rem;
    }
  `};
`;

export const CheckoutContent = styled(Content)`
  flex-grow: 1;
  border: none;
  max-width: ${p => (p.halfScreen ? '55%' : '100%')};
  ${media.small`
    max-width: 100%;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.small`
    flex-direction: column;
    justify-content: center;
    #dropin-adyen {
      padding: 0;
    }
  `};
`;

export const BorderContent = styled.div`
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
`;

export const ContentLine = styled.div`
  font-weight: bold;
  line-height: 2rem;
`;

export const PaymentDetailsText = styled.div`
  font-size: 1.4rem;
  min-width: 20rem;
  color: ${p => p.theme.textColor};
  ${media.smaller`
    width: 100%;
    padding-bottom: 1rem;
  `};
`;

export const PaymentDetailsContent = styled.div`
  margin-left: 1rem;
  ${media.smaller`
    margin-left: 0;
  `};
`;

export const ChangeButtonWrapper = styled.div`
  div {
    ${media.smaller`
      text-align: left;
      padding-top: 1rem;
      padding-bottom: 0;
  `};
  }
`;

export const AdyenContent = styled.div`
  padding: 4rem 0 0 2rem;
  min-width: 45%;
  iframe .full-screen {
    justify-content: flex-start;
  }
  ${media.small`
    min-width: 100%;
    .adyen-checkout__threeds2__challenge--05,
    .adyen-checkout__threeds2__challenge--05
    .adyen-checkout__iframe--threeDSIframe {
      min-height: 60rem;
    }
  `};
  .adyen-checkout__payment-method--next-selected,
  .adyen-checkout__payment-method:first-child,
  .adyen-checkout__payment-method:last-child {
    border-radius: 0;
  }
  .adyen-checkout__payment-method--selected {
    background-color: #ffffff;
  }
  .adyen-checkout__button {
    background-color: #3594cc;
  }
  .adyen-checkout__payment-method__name {
    font-size: 1.4rem;
    color: #797979;
  }
  .adyen-checkout__label__text {
    font-size: 1rem;
  }
  .adyen-checkout__button__text {
    text-transform: uppercase;
    font-size: 1.6rem;
  }
`;

export const PaymentAmount = styled.div`
  width: 100%;
  display: none;
  font-size: 1.6rem;
  color: ${p => p.theme.textColor};
  padding: 2rem;
  ${media.smaller`
    display: block;
  `};
`;
