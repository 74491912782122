import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {CrossIcon, BackIcon} from '../../styles/components/icons';

export const Wrapper = styled.div`
  display: none;
  justify-content: space-between;
  font-size: 1.2rem;
  ${media.smaller`
    display: flex;
    width: 100%;
    line-height: 4rem;
    height: 4rem;
    padding-left: 2rem;
    padding-right: 0.6rem;
    color: ${p => p.theme.primaryTextColor};
    background-color ${p =>
      p.isRoot ? p.theme.primaryColor : p.theme.secondaryColor};
    border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
  `};
`;

export const Left = styled.div`
  flex-grow: 1;
  cursor: pointer;
  color: ${p => (p.disable ? p.theme.disabledColor : p.theme.primaryTextColor)};
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 1.2rem;
`;

export const Close = styled(CrossIcon)`
  width: 4rem;
  cursor: pointer;
  ${media.smaller`
    color: ${p => p.theme.primaryTextColor};
    &:hover {
      color: ${p => p.theme.primaryTextColor};
    }
  `};
`;

export const BackButton = styled.div`
  display: flex;
  text-transform: uppercase;
`;

export const GoBack = styled(BackIcon)`
  width: 1rem;
  padding-right: 1.4rem;
  &:before {
    color: ${p => p.theme.primaryTextColor};
  }
`;

export const Name = styled.span`
  color: ${p => p.theme.primaryTextColor};
  padding-right: 1rem;
`;

export const Account = styled.span`
  color: ${p => p.theme.primaryTextColor};
`;

export const LoginRegister = styled.span`
  text-transform: uppercase;
  &:hover {
    color: ${p => p.theme.primaryTextColor};
  }
`;
