export const UPSELLING_GQL = `
query($branch: String, $itemIds: [String!]!, $deliveryDate: Date, $size: Int) {
    haveYouForgottenTheseItems(
        branch: $branch,
        itemIds: $itemIds,
        deliveryDate: $deliveryDate,
        size: $size
    ) {
        results {
            uuid
            itemId
            itemName
            delivery {
                price
                priceInc
                unitPriceDisplay
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                unitPriceDisplay
                step
                max
                unitPriceDisplay
            }
            collectionMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            deliveryMultiBuy {
                price
                priceInc
                step
                min
                max
                unitPriceDisplay
            }
            quantityStep
            quantityMax
            productDescription
            offer {
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
    },
    didYouSeeTheseDeals(
        branch: $branch,
        itemIds: $itemIds,
        deliveryDate: $deliveryDate,
        size: $size
    ) {
        results {
            uuid
            itemId
            itemName
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            productDescription
            offer {
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
    },
    flashSales(
        branch: $branch,
        itemIds: $itemIds,
        deliveryDate: $deliveryDate,
        size: $size
    ) {
        results {
            uuid
            itemId
            itemName
            delivery {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            collection {
                price
                priceInc
                step
                max
                unitPriceDisplay
            }
            productDescription
            offer {
                onOffer
                promoTypes
                promoTag
                promoTagId
                promoEnd
                promoDiscountText
                promoForCc
                promoDisAmt
                promoMinQty
                promoPrice
            }
            favourite
            imageLinks
            isAvailable
            tags
            price
        }
    }
}`;
