import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  BranchesWrapper,
  LongButton,
  ButtonsWrapper,
  TitleTail,
  ChangePaymentInfo,
} from './PopupModal.style';

export default class CashPaymentModal extends PureComponent {
  changePaymentMethod = () => {
    const {
      closeModalCallback,
      changeToPbbPayment,
      changeToCardPayment,
      hasPbbPayment,
    } = this.props;
    if (hasPbbPayment) {
      changeToPbbPayment();
    } else {
      changeToCardPayment();
    }

    closeModalCallback();
  };

  render() {
    const {
      showModal,
      closeModalCallback,
      branchName,
      callback,
      isCollection,
      promoTotal,
      hasPbbPayment,
    } = this.props;
    const renderBranchName = isCollection && branchName && (
      <TitleTail>({branchName})</TitleTail>
    );
    const paymentMethodText = hasPbbPayment ? 'Pay By Bank' : 'Card';
    const total =
      hasPbbPayment && promoTotal
        ? promoTotal.payByBank
        : promoTotal.onlineCard;
    const renderWithPromoText =
      hasPbbPayment &&
      !!total &&
      `You could save £${total} using ${paymentMethodText} or please ensure you have cash available for this order.`;
    const renderCashText =
      !hasPbbPayment ||
      (!total &&
        `Please ensure you have cash available for this order, or switch to ${paymentMethodText}`);

    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw={'cash-modal'}
      >
        <StyledWrapper>
          <Content>
            <BranchesWrapper>
              <Title>Cash order {renderBranchName}</Title>
              <ChangePaymentInfo>
                {renderWithPromoText}
                {renderCashText}
              </ChangePaymentInfo>
              <ButtonsWrapper wideButtons>
                <LongButton onClick={this.changePaymentMethod}>
                  Use {paymentMethodText}
                </LongButton>
                <LongButton onClick={callback}>Continue as Cash</LongButton>
              </ButtonsWrapper>
            </BranchesWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
