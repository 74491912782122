import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  PriceDetails,
  BoldText,
  Column,
  TextTitle,
  TextWrapper,
  Button,
  Group,
  InfoText,
  LoadingWrapper,
  Voucher,
  PriceField,
  VatField,
  Note,
  EstimateText,
} from './PriceSummary.style';
import {hasMinDeliveryOrderNotMet} from '../../helpers/data.helper';
import PopupModal from '../PopupModal/PopupModal';
import Loading from '../Loading/Loading';
import VoucherModal from '../PopupModal/VoucherModal';
import {
  SLOT_FULFILMENT_STATUSES,
  VOUCHER_STATUS,
} from '../../constants/constants';
import {
  getBasketItemsCodes,
  getBasketTotalItems,
  isBasketSynced,
} from '../../helpers/basket.helper';

export default class PriceSummary extends PureComponent {
  static propTypes = {
    basket: PropTypes.object.isRequired,
    showDeliveryOnly: PropTypes.bool,
    showCollectionOnly: PropTypes.bool,
    showDeleteBasketBtn: PropTypes.bool,
    isCheckoutPage: PropTypes.bool,
    minOrder: PropTypes.number,
    applyVoucher: PropTypes.func,
    clearVoucherErrors: PropTypes.func,
    showDailyLimit: PropTypes.bool,
    dailyLimitNum: PropTypes.number,
    navigateToBasket: PropTypes.func,
  };
  state = {
    showConfirmModal: false,
    showConfirmDeleteVoucherModal: false,
    showVoucherModal: false,
    totalItemsNumber:
      (this.props.basket &&
        getBasketTotalItems(
          this.props.basket.items,
          this.props.isCheckoutPage
        )) ||
      0,
  };

  componentDidUpdate(prevProp, prevState) {
    const {basket, isCheckoutPage} = this.props;
    if (
      basket &&
      basket.items &&
      prevProp.basket &&
      prevProp.basket.items &&
      getBasketItemsCodes(basket.items) !==
        getBasketItemsCodes(prevProp.basket.items)
    ) {
      const itemsNumber = getBasketTotalItems(basket.items, isCheckoutPage);
      if (itemsNumber !== prevState.totalItemsNumber) {
        this.setState({totalItemsNumber: itemsNumber});
      }
    }
  }

  openStopEditModal = () => {
    this.props.openStopEditModal();
  };
  toggleConfirmModal = () => {
    this.setState({showConfirmModal: !this.state.showConfirmModal});
  };

  toggleConfirmDeleteVoucherModal = () =>
    this.setState({
      showConfirmDeleteVoucherModal: !this.state.showConfirmDeleteVoucherModal,
    });

  toggleVoucherModal = () => {
    this.props.clearVoucherErrors();
    this.setState({showVoucherModal: !this.state.showVoucherModal});
  };

  cleanBasket = () => {
    this.props.cleanBasket();
    this.setState({showConfirmModal: false});
  };

  removeVoucher = () => {
    this.props.removeVoucher();
    this.toggleConfirmDeleteVoucherModal();
  };

  render() {
    const {
      basket,
      showDeliveryOnly,
      showCollectionOnly,
      showDeleteBasketBtn,
      showAddVoucherBtn,
      minOrder,
      isCheckoutPage,
      loading,
      applyVoucher,
      errors,
      showDailyLimit,
      dailyLimitNum,
      navigateToBasket,
    } = this.props;
    const {showVoucherModal} = this.state;
    const basketSynced =
      basket &&
      isBasketSynced(basket.syncStatus) &&
      loading &&
      !loading.updatingBasket;
    const renderRemoveVoucherModal = this.state
      .showConfirmDeleteVoucherModal && (
      <PopupModal
        content={'Are you sure?'}
        title={'Delete the current voucher?'}
        showOKButton={true}
        showCancelButton={true}
        callback={this.removeVoucher}
        cancelCallBack={this.toggleConfirmDeleteVoucherModal}
        closeModalCallback={this.toggleConfirmDeleteVoucherModal}
        showModal={this.state.showConfirmDeleteVoucherModal}
        modalId="modal--delete-voucher"
      />
    );
    const renderItemLength = basket && basket.items && (
      <BoldText left>{this.state.totalItemsNumber} products</BoldText>
    );
    const renderTotalVat = basket &&
      basket.price &&
      !!basket.price.totalVat && (
        <VatField>including £{basket.price.totalVat.toFixed(2)} VAT</VatField>
      );
    const totalPrice =
      basketSynced || isCheckoutPage ? (
        <BoldText>
          <PriceField>
            £
            {basket.price &&
              basket.price.total &&
              basket.price.total.toFixed(2)}
            {renderTotalVat}
          </PriceField>
        </BoldText>
      ) : (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      );
    const hasNoSlotSet =
      basket &&
      (!basket.fulfillAtSlot ||
        basket.fulfillmentStatus === SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT) &&
      !isCheckoutPage;
    const renderEstimateText = showCollectionOnly
      ? 'collection tomorrow'
      : 'delivery tomorrow to your address';
    const renderFulfillmentNote = hasNoSlotSet && (
      <EstimateText>
        {`(estimated price based on ${renderEstimateText})`}
      </EstimateText>
    );
    const renderMinOrderNote = hasMinDeliveryOrderNotMet(basket) &&
      minOrder > 0 && <InfoText>Minimum required £{minOrder}</InfoText>;
    const dailyLimitText = `NOTE: ${dailyLimitNum} item${
      dailyLimitNum > 1 ? 's' : ''
    } had ${
      dailyLimitNum > 1 ? 'quantities' : 'quantity'
    } reduced due to daily limits or limited stock.`;
    const renderDailyLimitNote = showDailyLimit && !!dailyLimitNum && (
      <Note>{dailyLimitText}</Note>
    );
    const renderDailyLimitNoteOnMobile = showDailyLimit && !!dailyLimitNum && (
      <Note showOnMobile={true}>{dailyLimitText}</Note>
    );
    const renderDeliveryPrice = !showCollectionOnly && (
      <Column>
        <TextTitle>Delivery Price</TextTitle>
        <TextWrapper>
          {totalPrice}
          {renderMinOrderNote}
          {renderFulfillmentNote}
        </TextWrapper>
      </Column>
    );
    const renderDeleteBasketModal = this.state.showConfirmModal && (
      <PopupModal
        content={'Are you sure?'}
        title={'Delete the current basket'}
        showOKButton={true}
        showCancelButton={true}
        callback={this.cleanBasket}
        cancelCallBack={this.toggleConfirmModal}
        closeModalCallback={this.toggleConfirmModal}
        showModal={this.state.showConfirmModal}
        modalId="modal--delete-basket"
      />
    );
    const renderVoucherModal = showVoucherModal && (
      <VoucherModal
        callback={applyVoucher}
        cancelCallBack={this.toggleVoucherModal}
        closeModalCallback={this.toggleVoucherModal}
        basketUuid={basket.uuid}
        basketVoucher={basket.voucher}
        errors={errors}
      />
    );
    const renderCollectionPrice = !showDeliveryOnly && (
      <Column>
        <TextTitle>Collection Price</TextTitle>
        <TextWrapper>
          {totalPrice}
          {renderFulfillmentNote}
        </TextWrapper>
      </Column>
    );
    const renderCleanButton = showDeleteBasketBtn &&
      basket &&
      !basket.editMode && (
        <Button
          onClick={this.toggleConfirmModal}
          data-rw="basket--delete-basket-link"
        >
          Delete Basket
        </Button>
      );

    const renderStopEditButton = showDeleteBasketBtn &&
      basket &&
      basket.editMode && (
        <Button
          onClick={this.openStopEditModal}
          data-rw="basket--delete-basket-link"
        >
          Stop editing
        </Button>
      );
    const renderViewBasketButton = isCheckoutPage && basket && (
      <Button onClick={navigateToBasket} data-rw="basket--view-basket-link">
        View Basket
      </Button>
    );
    const voucherApplied =
      basket &&
      basket.voucher &&
      basket.voucher.status === VOUCHER_STATUS.APPLIED;

    const voucherNotApplied =
      basket &&
      basket.voucher &&
      basket.voucher.status === VOUCHER_STATUS.NOT_APPLIED;

    const voucherError =
      basket &&
      basket.voucher &&
      basket.voucher.status === VOUCHER_STATUS.ERROR;

    const renderAddVoucherButton = showAddVoucherBtn && !voucherApplied && (
      <Button
        onClick={this.toggleVoucherModal}
        data-rw="basket--add-voucher-link"
      >
        Add voucher
      </Button>
    );
    const renderVoucher = voucherApplied && (
      <Voucher>
        Voucher used:
        <br />
        {basket.voucher.code}
        <Button
          onClick={this.toggleConfirmDeleteVoucherModal}
          data-rw="basket--remove-voucher-link"
        >
          Remove voucher
        </Button>
      </Voucher>
    );
    const renderVoucherNotApplied = voucherNotApplied && (
      <Voucher>
        Voucher could not be applied:
        {basket.voucher.code}
      </Voucher>
    );
    const renderVoucherError = voucherError && (
      <Voucher>
        Voucher error:
        {basket.voucher.code}
      </Voucher>
    );

    return (
      <Fragment>
        <PriceDetails>
          {renderDeleteBasketModal}
          {renderVoucherModal}
          {renderRemoveVoucherModal}
          <Column>
            <TextTitle left>Total Items</TextTitle>
            <Group>{renderItemLength}</Group>
          </Column>
          {renderDeliveryPrice}
          {renderCollectionPrice}
          <Column>
            {renderDailyLimitNote}
            {renderCleanButton}
            {renderStopEditButton}
            {renderViewBasketButton}
            {renderAddVoucherButton}
            {renderVoucher}
            {renderVoucherNotApplied}
            {renderVoucherError}
          </Column>
        </PriceDetails>
        {renderDailyLimitNoteOnMobile}
      </Fragment>
    );
  }
}
