import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Id} from './Product.style';
import {ExtraPromo, PriceWholeWrapper} from './ListProduct-v2.style';

const Info = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.medium`
    margin-top: 0;
  `};
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 26.5rem;
  max-height: 26.5rem;
  padding-bottom: 1rem;
  margin: 0.8rem;
  flex-direction: column;
  background-color: ${p => p.theme.tertiaryBgColor};
  display: flex;
  ${media.small`
    height: 24.8rem;
    max-height: 24.8rem;
  `}
  ${media.smaller`
    height: 20.5rem;
    max-height: 20.5rem;
    margin: 0.8rem 0.4rem;
  `};
  ${media.smaller`
    margin: 0 0 1rem;
  `};
`;

export const ListViewContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Price = styled.div`
  width: 50%;
  display: flex;
  color: ${p => p.theme.tertiaryTextColor};
  font-size: 1.4rem;
  align-items: baseline;
  ${media.small`
    font-size: 1.2rem;
    height: 1.2rem;
  `};
  ${media.smaller`
    width: auto;
  `};
`;
export const Small = styled.span`
  font-size: 1.2rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.small`
    font-size: 1.1rem;
  `};
`;
export const Larger = styled.span`
  font-size: 1.6rem;
  ${media.small`
    font-size: 1.4rem;
  `};
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 0 2rem;
  text-align: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.medium`
    padding: 1rem;
  `};
  ${media.small`
    margin-top: -0.2rem;
  `};

  div[data-id='promotionSection'] {
    justify-content: flex-start;
  }
`;

export const Weight = styled.span`
  flex-grow: 1;
  font-size: 1rem;
  text-align: left;
  color: ${p =>
    p.isOffer ? p.theme.tertiaryColor : p.theme.tertiaryTextColor};
  line-height: 1.8rem;
  padding-top: 0.4rem;
  min-width: 100%;
  ${media.small`
    margin-top: 0;
  `};
`;

export const PhotoWrapper = styled.div`
  position: relative;
  width: 17rem;
  height: 17rem;
  text-align: center;
  margin: 0 1rem 0 2rem;
  cursor: pointer;
  ${media.small`
    width: 15rem;
    height: 15rem;
    margin: 1rem 0 0 1rem;
  `};
  ${media.smallest`
    display: flex;
    align-items: center;
  `};

  img {
    width: 17rem;
    height: 17rem;
    min-height: 17rem;
    min-width: 17rem;
    z-index: 1;
    ${media.small`
      width: 15rem;
      height: 15rem;
      min-width: 15rem;
      min-height: 15rem;
      max-height: 15rem;
    `};
    ${media.smallest`
      margin-left: 0;
    `};
  }
`;

export const ListViewInfo = styled(Info)`
  margin-bottom: 0.5rem;
  text-align: left;
  ${media.medium`
    margin-bottom: 0;
  `};
`;

export const NameWrapper = styled.div`
  width: 100%;
  ${media.small`
    padding: 0;
  `};
`;
export const Name = styled.h3`
  display: block;
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  text-overflow: ellipsis;
  min-height: 2rem;
  max-height: 2rem;
  text-align: left;
  color: ${p => p.theme.primaryColor};
  cursor: pointer;
  overflow: hidden;
  ${media.medium`
    font-size: 1.2rem;
    min-height: 3rem;
    max-height: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `};
`;

export const ButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.4rem;
  padding: 1rem 0;

  a {
    width: 50%;
  }

  ${media.medium`
    padding: 0;
  `};
  ${media.small`
    a {
      width: 100%;
    }
  `};
  ${media.smaller`
    font-size: 1.2rem;
  `};
`;

export const Details = styled(Content)`
  padding: 0;
  width: 40%;
  flex-direction: column;
  text-align: center;
  ${media.medium`
    padding: 0;
  `};
  ${media.small`
    width: 60%;
  `};
  ${media.smallest`
    width: 75%;
  `};
`;

export const PriceWrapper = styled.div`
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${media.medium`
    padding-bottom: 0.5rem;
  `};
  ${media.small`
    padding: 0.5rem 0;
    line-height: 1.4rem;
  `};
  ${media.smaller`
    padding: 0;
  `};
  ${media.smallest`
    padding: 1rem 0;
  `};
`;

export const Description = styled.span`
  display: block;
  font-size: 1.3rem;
  color: ${p => p.theme.textColor};
  line-height: 2rem;
  max-height: 4rem;
  height: 4rem;
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  ${media.medium`
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-top: 0;
  `};
  ${media.small`
    line-height: 1.2rem;
  `};
  ${media.smaller`
    display: none;
  `};
`;

export const PromoWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 2rem;
  min-height: 2rem;
  max-height: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.smaller`
    height: 1.6rem;
    flex-direction: column;
    min-height: 2.6rem;
    max-height: 2.6rem;
  `};
  ${media.smallest`
    align-items: flex-start;
    flex-direction: column;
    height: 2.2rem;
    min-height: 2.2rem;
    max-height: 2.2rem;
  `};
`;

export const SubWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TagsWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -1rem;
  ${media.small`
    position: relative;
  `};
`;

export const ListId = styled(Id)`
  font-size: 1.6rem;
  ${media.medium`
    font-size: 1.4rem;
    line-height: 1.4rem;
  `};
  ${media.smaller`
    padding-left: 0;
  `}
`;

export const PriceUnavailable = styled.div`
  height: 6.5rem;
  display: flex;
  align-items: center;
`;

export const SmallExtraPromo = styled(ExtraPromo)`
  font-size: 1.4rem;
  ${media.medium`
   font-size: 1.3rem;
  `};
`;

export const SmallPriceWholeWrapper = styled(PriceWholeWrapper)`
  margin-top: ${p => (p.hasNoOffers ? '3.3rem' : 0)};
`;

export const ListPriceWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: ${p => (p.onlyOnePrice ? '3rem' : '1.8rem')};
  line-height: ${p => (p.onlyOnePrice ? '3rem' : '1.8rem')};
  justify-content: space-between;
`;
