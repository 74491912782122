import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3} from '../../styles/components/buttons';
import {BlockWithArrow} from '../../styles/components/blockWithArrow';
import {fadeInOut} from '../../styles/components/keyframes';

export const Banner = styled.article`
  background-image: url(${props =>
    props.isMobile ? props.ads.mediaMobileUrl : props.ads.mediaDesktopUrl});
  background-size: cover;
  height: 38rem;
  min-height: 38rem;
  width: 100%;
  margin-bottom: 4.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  animation: 0.5s ${fadeInOut} ease-in;
  transition: all 1s ease-out;
  position: relative;
  z-index: 1;
  ${media.medium`
    height: 35rem;
    min-height: 35rem;
    background-position-x: center;
    margin-bottom: 2rem;
  `};
  ${media.small`
    background-position: top center;
    height: 35rem;
    min-height: 35rem;
  `};
  ${media.smaller`
    background-image: url(${props => props.ads.mediaMobileUrl});
    min-height: 66vw;
    height: 66vw;
    max-height: 66vw;
  `};
`;

export const Clickable = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
`;

export const Blocks = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  padding: 0 0.1rem 0.1rem;
  z-index: 2;
  ${media.medium`
    height: 4rem;
    min-height: 4rem;
  `};
  ${media.smaller`
    display: none;
  `};
`;

export const Block = styled(BlockWithArrow)`
  margin: 0.1rem;
  text-align: center;
  &::after {
    top: -0.8rem;
    border: ${props => (props.selected ? 'solid 0.8rem transparent' : 0)};
    border-top-width: 0;
    border-bottom-color: ${props => props.theme.primaryColor};
  }
  ${media.small`
    font-size: 1.2rem;
    text-align: center;
  `};
  ${media.smaller`
    font-size: 1rem;
    margin: 0;
    border-right: ${p => `solid 0.05rem ${p.theme.borderLightColor}`};
  `};
`;

export const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8rem;
  ${media.medium`
    padding: 2rem;
  `};
`;

export const Next = styled.div`
  display: none;
  ${media.smaller`
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 0;
    font-size: 5rem;
    font-weight: bold;
    text-shadow: 0px 0px 3px #000;
    color: ${props => props.theme.primaryTextColor};
    padding: 4rem 2rem;
    margin-top: -7rem;
    z-index: 10;
    opacity: 0.7;
    &::before {
      content: '⟩';
    }
  `};
`;

export const Header = styled.span`
  display: block;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0.3rem 0.6rem 0.6rem #000;
  color: ${props => props.theme.primaryTextColor};
  padding-bottom: 2rem;
  ${media.medium`
    max-width: 100%;
    font-size: 2rem;
    overflow: hidden;
  `};
`;

export const Description = styled.span`
  display: block;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: lighter;
  text-shadow: 0.3rem 0.6rem 0.6rem #000;
  color: ${props => props.theme.primaryTextColor};
  ${media.medium`
    max-width: 100%;
    font-size: 3rem;
    line-height: 3.6rem;
    overflow: hidden;
    text-shadow: 0.3rem #000;
  `};
`;

export const Button = styled(Button3)`
  min-height: 3.4rem;
  width: 10rem;
  box-sizing: content-box;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  margin-top: 2rem;
`;
