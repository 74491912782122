import {put, call, takeLatest, select} from 'redux-saga/effects';
import {waitFor} from '../helpers/saga.helper';
import recommendedApi from '../api/recommended.api';
import * as ActionTypes from '../actions/types.action';
import {processedSearchResults} from '../helpers/data.helper';
import {getDeliveryDate} from '../helpers/search.helper';

function* recommended(action) {
  try {
    const getBranch = state => state.branch;
    const getBasket = state => state.basket;
    const getSettings = state => state.settings;
    let basket = yield select(getBasket);
    const settings = yield select(getSettings);
    const branch = yield select(getBranch);
    const times = 5;
    const ms = 300;
    if (!basket) {
      basket = yield waitFor(getBasket, times, ms);
    }
    let params = {};
    const deliveryDate = getDeliveryDate(
      basket,
      action.fulfillmentDetails,
      settings
    );
    if (deliveryDate) {
      params = {deliveryDate};
    }
    params = Object.assign({}, params, {
      branch: action.branch || branch,
      itemId: action.itemId,
    });
    const results = yield call(recommendedApi.get, params, action.fullList);

    yield put({
      type: ActionTypes.GET_RECOMMENDED_ITEMS_SUCCESS,
      recommendedItems: processedSearchResults(results),
    });
  } catch (e) {
    yield put({
      type: ActionTypes.GET_RECOMMENDED_ITEMS_FAILED,
      message: e.message,
    });
  }
}

export default function* recommendedYield() {
  yield takeLatest(ActionTypes.GET_RECOMMENDED_ITEMS_REQUESTED, recommended);
}
