import {getDateParams, getValidDeliveryDate} from './data.helper';

export const getDeliveryDate = (basket, fulfillmentDetails, settings) => {
  if (basket && basket.fulfillAtSlot && basket.fulfillAtSlot.day) {
    return getValidDeliveryDate(basket.fulfillAtSlot.day);
  } else if (fulfillmentDetails) {
    return getValidDeliveryDate(fulfillmentDetails.date);
  } else if (settings) {
    return getDateParams(basket, settings).deliveryDate;
  }
  return null;
};

export const getPriceMulti = prices => {
  let finalPrice;
  prices.map(price => {
    if (
      !finalPrice ||
      (finalPrice && price.min && finalPrice.min < price.min)
    ) {
      finalPrice = price;
    }
  });
  return finalPrice;
};
