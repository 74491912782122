import React, {PureComponent} from 'react';

import {
  QtySelector,
  SelectorButton,
  CatchWeightInput,
} from './OrdersPage.style';
import {createArrayFromNumber} from '../../helpers/array.helper';

export default class RefundQty extends PureComponent {
  constructor(props) {
    super(props);
    const {quantity, catchWeightItem} = props;
    const qty = parseFloat(quantity);
    this.state = {
      totalQty: catchWeightItem ? qty : createArrayFromNumber(props.quantity),
      catchWeightValue: qty,
    };
  }
  onQtyChange = e => {
    const qty = e.target.value;
    const {setRefundQty} = this.props;
    setRefundQty(parseInt(qty, 10));
  };

  onValueChange = e => {
    const {totalQty} = this.state;
    const qty = parseFloat(e.target.value);
    const refundQty = qty > totalQty ? totalQty : qty;
    this.setState({catchWeightValue: refundQty});
    this.props.setRefundQty(refundQty);
  };

  render() {
    const {totalQty, catchWeightValue} = this.state;
    const {refundQty, catchWeightItem} = this.props;
    if (catchWeightItem) {
      return (
        <CatchWeightInput
          type="number"
          value={catchWeightValue}
          onChange={this.onValueChange}
          min={0.01}
        />
      );
    }
    const renderOptions =
      !catchWeightItem &&
      totalQty.map(item => (
        <option key={item} value={item}>
          {item}
        </option>
      ));
    return (
      <SelectorButton isSelect={true}>
        <QtySelector onChange={this.onQtyChange} value={refundQty}>
          <option key={0} value={1}>
            Refund 1
          </option>
          {renderOptions}
        </QtySelector>
      </SelectorButton>
    );
  }
}
