import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 1rem;
`;

export const Star = styled.span`
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  &::before {
    font-family: 'icomoon';
    content: '\\e909';
    font-size: 3rem;
    font-weight: normal;
    color: ${props =>
      props.rated ? props.theme.tertiaryColor : props.theme.secondaryBgColor};
    padding-top: 0.2rem;
    padding-right: 0.4rem;
  }
`;

export const FeedbackStar = styled(Star)`
  width: 3rem;
  height: 4rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &::before {
    font-size: 4rem;
    padding-top: 0;
    color: ${props =>
      props.rated ? props.theme.ratingColor : props.theme.borderDarkColor};
  }
`;
