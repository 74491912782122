import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {WayRight, WayLeft} from '../../styles/components/arrow';
import {Remove} from '../Basket/BasketItem.style';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
  &:hover {
    background-color: ${p => p.theme.secondaryBgColorHover};
  }
  ${media.small`
    padding: 1rem;
    flex-direction: column;
  `};
  ${media.smaller`
    padding: 1rem 0 0;
  `};
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 72%;
  ${media.small`
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  `};
  ${media.smaller`
    justify-content: space-between;
  `};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 45%;
  max-width: 45%;
  width: 45%;
  ${media.small`
    flex-grow: 1;
    min-width: intial;
    max-width: intial;
    width: intial;
  `};
`;

export const ItemWrapper = styled.div`
  width: 100%;
  min-height: 10rem;
  display: flex;
  margin-bottom: ${p => (p.hasSubItems && !p.hideAlternatives ? '3rem' : 0)};
  font-size: 1.4rem;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  color: ${p => p.theme.textColor};
  background-color: ${p => p.theme.secondaryBgColor};
  border: ${p =>
    p.unavailable
      ? `0.1rem solid ${p.theme.primaryColorHover}`
      : `0.1rem solid ${p.theme.secondaryColor}`};
  ${media.small`
    margin-bottom: 0;
  `};
  ${media.smaller`
    min-height: 9rem;
    padding: 1rem 0;
    align-items: flex-start;
  `};
  ${media.smallest`
    flex-direction: column;
    align-items: center;
  `};
`;

export const TextContent = styled.div`
  ${media.smallest`
    padding: 0 1rem;
  `};
`;

export const MessageWrapper = styled(ItemWrapper)`
  justify-content: center;
  cursor: inherit;
  border: ${p =>
    p.unavailable
      ? `0.1rem solid ${p.theme.tertiaryTextColor}`
      : `0.1rem solid ${p.theme.secondaryColor}`};
  min-width: 45%;
  max-width: 45%;
  width: 45%;
  ${media.small`
    min-width: intial;
    max-width: intial;
    width: intial;
    padding: 1rem 1.5rem;
  `};
`;

export const NotAvailable = styled.div`
  color: ${p => p.theme.primaryColor};
  ${media.small`
    font-size: 1.2rem;
  `};
`;

export const AvailableAt = styled.span`
  white-space: nowrap;
`;

export const Image = styled.div`
  img {
    min-width: 7rem;
    height: 7rem;
    max-height: 7rem;
    margin: 0 1rem 0 0;
    ${media.smaller`
      min-width: 5rem;
      height: 5rem;
      max-height: 5rem;
      margin: 0 1rem;
    `};
  }
`;

export const Name = styled.div`
  font-size: 1.2rem;
  color: ${p => (p.unavailable ? p.theme.textColor : p.theme.secondaryColor)};
  padding-bottom: 1rem;
  ${media.small`
    font-size: 1.1rem;
  `};
  ${media.smallest`
    min-height: 4rem;
    padding-bottom: 0.5rem;
  `};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${p => (p.hasSubItems ? '3rem' : 0)};
`;

export const Arrow = styled(WayRight)`
  margin-left: 2rem;
  margin-right: 2rem;
  ${media.small`
    margin-left: 1rem;
    margin-right: 1rem;
  `};
  ${media.smaller`
    margin: 0 0.5rem 1rem;
  `};
`;

export const PrevOne = styled(WayLeft)`
  cursor: pointer;
`;

export const NextOne = styled(WayRight)`
  cursor: pointer;
`;

export const QuantityWrapper = styled.div`
  ${media.medium`
     margin-top: 0;
  `};
  ${media.small`
    height: 3rem;
    min-height: 3rem;
  `};
`;

export const LoadingWrapper = styled.div`
  svg {
    width: 3rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 14rem;
  max-width: 14rem;
  font-size: 1.4rem;
  padding: 2rem 0 0 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${p =>
    !p.hasAlternative || !p.showRemoveItemButton
      ? 'flex-end'
      : 'space-between'};
  align-items: center;
  flex-grow: 1;
  color: ${p => p.theme.textColor};
  ${media.medium`
    width: auto;
  `};
  ${media.small`
    width: 100%;
    ${p => p.showRemoveItemButton && 'flex-direction: row-reverse'};
  `};
`;

export const RemoveButton = styled(Remove)`
  ${media.small`
    position: relative;
    right: initial;
    top: initial;
    display: block;
    margin-right: 1rem;
    height: 3rem;
    &::before {
      position: relative;
    }
  `};
  ${media.small`
    visibility: visible;
    position: relative;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  `};
`;

export const AcceptButtonWrapper = styled.div`
  position: relative;
  visibility: ${p => (p.show ? 'visible' : 'hidden')};
  ${media.small`
    width: 50%;
    display: flex;
    justify-content: center;
  `};
`;

export const RemoveButtonWrapper = styled.div`
  ${media.small`
    width: 50%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  `};
`;

export const AcceptButton = styled.div`
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 4rem;
  min-height: 4rem;
  min-width: 15rem;
  max-width: 15rem;
  text-align: center;
  cursor: pointer;
  color: ${p => p.theme.secondaryColor};
  text-transform: uppercase;
  border: ${p => `0.2rem solid ${p.theme.secondaryColor}`};
  margin: 1rem 2rem;
  ${media.medium`
    margin: 1rem 1rem 1rem 2rem;
  `};
  ${media.small`
    width: 12rem;
    max-width: 12rem;
    min-width: 12rem;
    margin: 0;
  `};
  ${media.smaller`
    margin: 0 1rem 1rem 2rem;
    height: 3rem;
    min-height: 3rem;
  `};
`;

export const AlternativeText = styled.span`
  padding-left: 0.5rem;
  display: inline;
  ${media.small`
    display: none;
  `};
`;

export const AlterDotsWrapper = styled.div`
  display: flex;
  min-height: 3rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AlterDots = styled.div`
  display: flex;
`;

export const AlterDot = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${p =>
    p.selected ? p.theme.primaryColor : p.theme.secondaryColor};
`;
