import React, {Fragment, PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  ErrorButton,
  ButtonsWrapper,
} from './PopupModal.style';

export default class MaintenanceModal extends PureComponent {
  render() {
    const {showModal, callback, messages, closeModalCallback} = this.props;
    const renderMessages =
      messages &&
      messages.map((msg, i) => (
        <Fragment key={i + msg.heading}>
          <SubContent>{msg.message}</SubContent>
        </Fragment>
      ));
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw="maintenance modal"
      >
        <StyledWrapper isError={true}>
          <Content>
            <TextWrapper>
              <Title isError={true}>System Maintenance</Title>
              {renderMessages}
              <ButtonsWrapper>
                <ErrorButton onClick={callback} data-rw="modal--ok-button">
                  OK
                </ErrorButton>
              </ButtonsWrapper>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
