const textBakery =
  'Browse our selection of sweet and savoury baked goods; from pastries to pitta bread, burger buns and baguettes. Start the day with our croissants, bagels, muffins and cookies, or create sensational sandwiches with our paninis, ciabattas, baps and wraps.';
const textBarbecue =
  'Barbecue essentials for your charcoal bbq grill. A selection of premium, restaurant grade coal, wood and logs.';
const textBreading =
  "Our wide range of flour includes rice flour, corn flour, chapatti flour, self-raising and plain flour. We've got you covered with chicken marinades and chicken batter mix, pancake mix and piri-piri seasoning mix.";
const textBurger =
  "We've got a wide range of beef burgers including gourmet and signature styles, along with our premium chicken and lamb doner kebab meat. Our fish and chip shop favourites include saveloy, quality sausages and frankfurters. All available with halal options.";
const textCooking =
  'Browse our range of store cupboard essentials including herbs and spices, seasonings, gravy granules, panko breadcrumbs, coconut milk, instant yeast, salt and stuffing.';
const textCrockery =
  'Our Lavazza coffee branded crockery is available in coffee cups, cappuccino cups, mugs, and espresso cups, as well as saucers.';
const textDairy =
  'An extensive selection of dairy products including burger cheese, cheddar cheese, halloumi, feta, paneer, mozzarella, pizza cheese and vegan cheese. Speciality ayran, milk, dairy alternatives, eggs, cream, butter and ghee.';
const textDessert =
  "Indulge in our delicious desserts from cheesecake to chocolate cake, baklava and belgian waffles. Serve alongside a scoop of ice cream including Ben&Jerry's or Haagen Dazs, and top with gourmet flavoured sauce. Monin syrups are ideal for coffee shops, whilst our maple syrup and golden syrup are suitable for vegans and vegetarians.";
const textDrinks =
  'We cater for all of your drinks needs with our extensive range of soft drinks, juices, water, teas and coffees. Shop from brands including Vivat, Coca Cola, Fanta, Natura, Nescafe, PG Tips and more.';
const textFish =
  'Our range of fresh and frozen fish and seafood includes frozen at sea, MSC certified fillets ideal for fish and chip shops. Along with breaded and battered fish options, and skin-on and skinless salmon, cod, haddock and sea bass, there is also our prawns, squid and octopus.';
const textFruit =
  'Our fruit and vegetable range has everything you need, be it fresh, frozen or canned. Explore our high quality products including onions, chilli peppers, mushrooms, lettuce and tomatoes; baked beans, tinned tomatoes, gherkins and olives. Our fresh fruit varieties consist of oranges, lemons, pomegranates and avocados, as well as our frozen berry mixes ideal for smoothies.';
const textHoney =
  'A selection of breakfast spreads including strawberry jam, raspberry jam, orange marmalade, and lemon curd, Nutella, honey and Marmite. Essential condiments around the breakfast table.';
const textHygiene =
  "A wide range of cleaning products essential for maintaining hygiene. From blue roll to black bags, kitchen degreaser, cleaning cloths and anti-bacterial sprays - we've got you covered.";
const textMeat =
  'A fantastic selection of fresh and frozen beef, pork, steak, lamb, mutton and gammon. Along with meaty pizza toppings, charcuterie selections and speciality turkish sausage.';
const textOil =
  'Oils for your every need - extra virgin olive oil, vegetable oil and sunflower oil, as well as coconut oil, rapeseed oil and palm oil.';
const textPackaging =
  'Our huge range of packaging includes fish and chips boxes, pizza boxes, chicken boxes, burger boxes and so much more. Not forgetting carrier bags, microwave plastic containers, cutlery and coffee cups. Our packaging options also include recyclable and biodegradable products.';
const textPastry =
  "Browse our range of pastry products - from puff pastry to Pukka pies, spring rolls, samosas and sausage rolls, we've got a wide variety to choose from.";
const textPosters =
  "We've got a selection of posters to show off to your customers, including chicken posters, kebab posters, burger posters and hot dog posters.";
const textPotato =
  'All of your potato needs in one place - chunky chips, skinny fries, sweet potato fries, hash browns, wedges, mashed potato, and fresh potatoes too.';
const textPoultry =
  "Whether it's fresh chicken, frozen chicken, or cooked chicken; chicken breasts, chicken fillets, chicken wings, chicken burgers, chicken shish or southern fried chicken, we've got it all. We also stock turkey rashers, turkey butterfly, and roasted duck too.";
const textReadyMeals =
  'Our range of ready meals are prepared for your convenience. Browse our selection of pizzas, lasagne, chilli con carne, ravioli and buffet snacks.';
const textRice =
  'Dry store essentials including basmati rice, risotto rice, pasta, spaghetti, noodles, lentils, tofu, quinoa, couscous, dried fruit and nuts.';
const textSandwich =
  'A range of sandwich fillings for every preference - coronation chicken, tuna mayonnaise, egg mayo, cheese and onion and more. Pre-prepared for your convenience.';
const textSauces =
  'From sauces for cooking, pizza sauce and soy sauce, to mayonnaise, ketchup and burger sauce, we have everything you need. Browse our range of sauces, salad dressings, burger relish, pickles and vinegars.';
const textSnacks =
  'Our snack selection includes chocolate bars, cereal bars, biscuits, cookies, wafers and flapjacks, as well as crisps, nuts, popcorn, sweets, crackers and chewing gum. Not forgetting our breakfast cereals including Cheerios and Shredded Wheat.';
const textStationery =
  'In need of till roll and A4 printer paper – discover our stationery items here.';
const textSugar =
  "We've got a wide selection of sugars and sweeteners from white sugar, brown sugar, demerara or icing sugar, to sweetener sachets and Tate & Lyle sugar - big or small, we have it all.";
const textVegetarian =
  'Our ever-growing range of vegan and vegetarian products include meat-free burgers, vegetable burgers, falafels, veggie sausages, Quorn, plant-based nuggets, meat free goujons, onion rings and much more.';

export const OWN_BRAND_DESCRIPTION = {
  'Double Crunch':
    'Double Crunch Super Crisp Chips are pre-fried, frozen, extra crunchy wholesale chips perfect for restaurants, pubs, and takeaways',
  'Warmer for longer chips':
    'Warmer for Longer Super Crisp Chips are pre-fried, frozen, crunchy wholesale chips designed to stay hotter for longer, perfect for takeaways.',
  'Super Crisp Chips':
    "Meet the Super Crisp Chips Family! Pre-fried, frozen, crunchy wholesale chips available in a wide variety of options from skinny fries to chunky cut and sweet potato wedges. There's an option to suit every dish on your menu!",
  Letsdough:
    'Enjoy the taste of freshly baked bread with the convenience of being able to cook straight from frozen. The Letsdough range offers everything from wholesale baguettes and paninis to croissants and Turkish-style Simits.',
  Natura:
    'Bursting with the juiciest fruits we can find to create a delicious drink that helps you feel hydrated and refreshed. Natura juices are made from concentrate and available in a wide range of flavours to tantalise your tastebuds.',
  'Pine Hills':
    "Pure mineral water sourced from the dense pine forest of Istanbul's the Camlica hills. With spectacular views, the region is well-known for its beautiful forestry and flowers, helping to create mineral-rich spring water.",
  VIVAT:
    'Drawn and bottled at source from an organic, British site in the rolling hills of Pembrokeshire, VIVAT water is available in robust, high-quality bottles in a range of sizes and formats, in still and sparkling variants.',
  'Royal Roast':
    'Royal Roast coffee is produced by our partners on the Amalfi Coast of Italy using state-of-the-art machinery. A third-generation family business whose passion for coffee is unrivalled in the region.',
  IQF:
    'Known for its white, flaky flesh, our MSC-certified, Atlantic range is Individually Quick Frozen, helping to reduce waste and support portion control, while meeting international best practice for sustainable fishing.',
  'White Gold Mr Chingr':
    'White Gold Mr Chingri shrimps are a popular in traditional Chinese and Indian cuisines. Sourced from the cold, clear waters of Bangladesh, a region well-known for its rich source of quality prawns.',
  Aquafish:
    'Our team travel the world to source the best wholesale fish and seafood products, from Vietnamese prawns to stunning Turkish sea bass, all frozen to retain flavour and nutrients. Choose from a range of options in our Aquafish range.',
  NSFIL:
    'High-quality, premium NSFIL prawns suitable for a range of cuisines from curries and stir fries to BBQ grills, including stunning Black Tiger King Prawns, available in a range of sizes, guaranteed to add wow-factor to your menu.',
  Buffalo:
    'When it comes to pizza, nothing beats our super stretchy, deliciously creamy Buffalo Mozzarella grated pizza cheese, it will keep your customers coming back for seconds! High quality cheese at an unbeatable wholesale price.',
  Letscake:
    'Treat your customers to indulgent and delicious, beautifully crafted cakes - perfect for café, coffee shop and restaurant dessert menus.',
  'SQ-Easy':
    'Deliciously tasty, great value wholesale sauces, also available in single units, suitable for a range of takeaway outlets or condiment fanatics at home.',
  'Fish and Chips':
    'Strong, robust, and fantastic quality packaging for fish and chip takeaways including recyclable, biodegradable and compostable options. Keeps food hot and crispy. Wide range of sizes to choose from.',
  Premiumeat:
    'High quality, great value halal chicken and meat options, Premium Eat is the perfect choice for a wide range of restaurant and takeaway menus, and larger families cooking at home.',
  Vittorios:
    'High-quality, wholesale cheddar cheese sourced in the United Kingdom and available in both grated and pre-sliced variants at affordable catering prices.',
  Famosa:
    'Famosa tomato sauce has been with the JJ family for more than 30 years and is made with the sweetest, most succulent tomatoes grown from Israeli tomato seeds which are well-known for their quality and taste.',
  Fineats:
    'Tasty and convenient fillings for sandwiches, wraps and jacket potatoes, Fineats sandwich fillings are ideal for busy sandwich shops and cafes, and will add maximum flavour to your lunchtime menu for great, everyday value prices.',
  '4Flame':
    '4Flame offers robust, reliable, high-quality packaging for your restaurant or takeaway business. Available in a range of sizes with options suitable for pizza, Chinese, fish, and chips, Indian and many other cuisines.',
  Falcon:
    "Suitable for vegan's and vegetarian's, Falcon's spiced pizza sauce is a favourite with our restaurant and takeaway customers, and for households that enjoy making restaurant quality pizza at home.",
};

export const CATEGORY_DESCRIPTION = {
  Bakery: textBakery,
  100001: textBakery,
  Barbecue: textBarbecue,
  100031: textBarbecue,
  'Breading, Batter & Flour': textBreading,
  100002: textBreading,
  'Burger, Doner & Sausages': textBurger,
  100029: textBurger,
  'Cooking Ingredients': textCooking,
  100007: textCooking,
  Crockery: textCrockery,
  100010: textCrockery,
  'Dairy & Egg': textDairy,
  100003: textDairy,
  'Dessert & Ice Cream': textDessert,
  100004: textDessert,
  Drinks: textDrinks,
  100005: textDrinks,
  'Fish & Seafood': textFish,
  100006: textFish,
  'Fruit & Vegetables': textFruit,
  100025: textFruit,
  'Honey, Jam & Spread': textHoney,
  100027: textHoney,
  Hygiene: textHygiene,
  100008: textHygiene,
  Meat: textMeat,
  100009: textMeat,
  Oil: textOil,
  100012: textOil,
  Packaging: textPackaging,
  100013: textPackaging,
  Pastry: textPastry,
  100015: textPastry,
  'Posters, Stands & Signs': textPosters,
  100017: textPosters,
  Potato: textPotato,
  100018: textPotato,
  Poultry: textPoultry,
  100019: textPoultry,
  'Ready Meals': textReadyMeals,
  100020: textReadyMeals,
  'Rice, Pasta & Dried Foods': textRice,
  100014: textRice,
  'Sandwich Fillings': textSandwich,
  100021: textSandwich,
  'Sauces, Dressings & Relishes': textSauces,
  100022: textSauces,
  Snacks: textSnacks,
  100024: textSnacks,
  Stationery: textStationery,
  100030: textStationery,
  'Sugar & Sweeteners': textSugar,
  100028: textSugar,
  'Vegetarian & Vegan': textVegetarian,
  100026: textVegetarian,
};
