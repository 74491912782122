import React, {PureComponent} from 'react';
import {
  DropButton,
  DropdownWrapper,
  ListWrapper,
  AddAList,
  AddInputWrapper,
  SubmitButton,
  AddInput,
} from './DropdownList.style';
import AList from './AList';
import {KEYBOARD_KEYS} from '../../constants/constants';

export default class DropdownList extends PureComponent {
  state = {
    showList: false,
    showInput: false,
    updatedInput: '',
  };
  handleChange = event => {
    this.setState({updatedInput: event.target.value});
  };

  handleOnKeyPress = e => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      this.createList();
    }
  };
  toggleList = () =>
    this.setState({showList: !this.state.showList, showInput: false});
  toggleInput = () => this.setState({showInput: !this.state.showInput});
  createList = () => {
    this.setState({showInput: false});
    if (this.state.updatedInput.trim() === '') {
      return;
    }
    this.props.createList(this.state.updatedInput);
  };
  updateItemInList = (listId, quantity) => {
    const {itemId, updateListItem} = this.props;
    updateListItem(listId, itemId, quantity);
  };
  render() {
    const {addName, lists, productShoppingList, loading, step} = this.props;
    const {showList, showInput, updatedInput} = this.state;
    const renderListContent =
      lists &&
      lists.map(list => (
        <AList
          key={list.id}
          list={list}
          callback={this.updateItemInList}
          loading={loading}
          quantity={
            list &&
            list.id &&
            productShoppingList &&
            productShoppingList[list.id]
          }
          productShoppingList={productShoppingList}
          step={step}
        />
      ));
    const renderInput = showInput && (
      <AddInputWrapper>
        <AddInput
          type="text"
          placeholder="Enter the new list name"
          value={updatedInput}
          onChange={this.handleChange}
          onKeyPress={this.handleOnKeyPress}
        />
        <SubmitButton onClick={this.createList} />
      </AddInputWrapper>
    );
    const renderAddButton = !showInput && (
      <AddAList onClick={this.toggleInput}>Create new list</AddAList>
    );
    const renderList = showList && (
      <div>
        <ListWrapper>{renderListContent}</ListWrapper>
        {renderInput}
        {renderAddButton}
      </div>
    );
    return (
      <DropdownWrapper>
        <DropButton onClick={this.toggleList} active={showList}>
          {addName}
        </DropButton>
        {renderList}
      </DropdownWrapper>
    );
  }
}
