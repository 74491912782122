import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Link1} from '../../styles/components/links';
import {Button3a, Button3} from '../../styles/components/buttons';
import {ArrowSwitch} from '../../styles/components/arrowSwitch';

export const Container = styled.section`
  width: 100%;
  display: flex;
  background-color: ${props => props.theme.secondaryBgColor};
  padding: 1rem;
  align-items: flex-start;
  ${media.medium`
    margin-top: 0;
  `};
  ${media.smaller`
    padding: 0;
    flex-direction: column;
  `};
`;

export const BreadCrumb = styled.nav`
  margin-top: 2.4rem;
  width: 100%;
  min-height: 4rem;
  background-color: ${props => props.theme.secondaryBgColor};
  font-size: 1.2rem;
  padding: 2rem;
  color: ${props => props.theme.primaryColor};
  ${media.smaller`
    margin-top: 1rem;
    padding: 1rem 2rem 1rem;
    line-height: 1.6rem;
  `};
`;

export const BreadCrumbLinks = styled(Link1)`
  text-transform: capitalize;
`;

export const CurrentPage = styled.a`
  font-weight: ${p => (p.normal ? 'normal' : 'bold')};
  text-decoration: none;
  padding-left: ${p => (p.noPadding ? 0 : '0.5rem')};
  cursor: ${p => (p.normal ? 'pointer' : 'default')};
  color: ${p => p.theme.primaryColor};
`;

export const Separator = styled.span`
  padding-right: 0.5rem;
  font-weight: normal;
`;

export const FiltersWrapper = styled.aside`
  width: 25%;
  min-width: 25%;
  font-size: 1.4rem;
  color: ${props => props.theme.textColor};
  ${media.smaller`
    display: ${p => (p.showInMobile ? 'block' : 'none')};
    position: ${p => (p.showInMobile ? 'absolute' : 'relative')};
    background-color:
      ${p => (p.showInMobile ? p.theme.secondaryBgColor : 'none')};
    width: 100%;
    min-width: 100%;
    top: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 999;
  `};
`;

export const FilterInfo = styled.div`
  ${media.smaller`
    display: none;
  `};
`;

export const ResultsPanel = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  ${media.small`
    width: 100%;
  `};
`;

export const ResultsWrapper = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  overflow-y: hidden !important;

  > div {
    overflow-y: hidden !important;
  }

  > div > div {
    overflow-y: hidden !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  ${media.smallest`
    padding-right: 0;
  `};
`;

export const ResultsTitle = styled.div`
  color: ${props => props.theme.secondaryColor};
  font-size: 3.6rem;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8.1rem;
  padding-bottom: 4rem;
  margin: 0;
  ${media.medium`
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 2.4rem;
  `};
  ${media.small`
    padding: 2rem;
    font-size: 2rem;
  `};
`;

export const LoadMore = styled.div`
  padding: 2rem;
  width: 100%;
  text-align: center;
`;

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
  color: ${p => p.theme.textColor};
  margin: 0 0 0 1rem;
`;

export const ClearFilter = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0.8rem 1rem 0 1rem;
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;

export const FilterButtonWrapper = styled.div`
  display: none;
  background-color: ${p => p.theme.secondaryBgColor};
  ${media.smaller`
    padding: 0 2rem;
    display: block;
    width: 100%;
  `};
`;

export const FilterButton = styled(Button3)`
  text-transform: capitalize;
  text-align: left;
  justify-content: space-between;
  padding-left: 2rem;
  color: ${p => p.theme.tertiaryTextColor};
  border: ${props => `solid 0.2rem ${props.theme.borderDarkerColor}`};

  &:hover {
    background-color: ${props => props.theme.tertiaryBgColor};
    border-color: ${props => props.theme.secondaryColor};
  }
`;

export const Status = styled(ArrowSwitch)`
  display: none;
  ${media.smaller`
    display: block;
    padding-right: 1rem;
  `};
`;

export const SearchFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 1rem;
`;

export const NoResult = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.textColor};
  padding: 2rem;
`;
export const FiltersButtons = styled.div`
  display: none;
  padding: 0.6rem 1rem 1rem;
  ${media.smaller`
    display: flex;
    justify-content: space-evenly;
  `};
`;

export const ClearFilterButton = styled(Button3)`
  text-transform: uppercase;
  width: 50%;
  margin-left: 0.5rem;
  color: ${props => props.theme.secondaryColor};
  border-color: ${props => props.theme.secondaryColor};

  &:hover {
    color: ${props => props.theme.primaryTextColor};
  }
`;

export const DoneButton = styled(ClearFilterButton)`
  margin-right: 0.5rem;
  margin-left: 0;
  background-color: ${props => props.theme.secondaryColor};
  color: ${props => props.theme.primaryTextColor};
`;

export const LoadMoreButton = styled(Button3a)`
  text-transform: uppercase;
  width: 50%;
  margin-top: 2rem;
  max-width: 23rem;
  ${media.small`
    width: 18rem;
    min-height: 3rem;
    height: 3rem;
  `};
  ${media.smaller`
    display: none;
  `};
`;

export const NameWrapper = styled.h1`
  font-size: 3rem;
  font-weight: normal;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50rem;
  ${media.medium`
    max-width: 40rem;
    text-align: center;
  `};
  ${media.small`
    font-size: 2.2rem;
    max-width: 32rem;
    text-align: center;
  `};
  ${media.smaller`
    font-size: 2rem;
  `};
`;

export const BreadCrumbResult = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70rem;
  vertical-align: top;
  text-transform: capitalize;
  ${media.medium`
    max-width: 50rem;
  `};
  ${media.small`
    max-width: 40rem;
  `};
  ${media.smaller`
    max-width: 26rem;
  `};
  ${media.smallest`
    max-width: 22rem;
  `};
`;

export const CategoryDescription = styled.h3`
  font-weight: normal;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.tertiaryBgColor};
  padding: 1.2rem 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.7rem 0.7rem 0 0.7rem;
`;
