import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {addAllShoppingListToBaskets, setCurrentRoute} from '../../actions';
import {setPrevRoute} from '../../actions/prevRoute.action';
import {
  getShoppingListById,
  getMoreShoppingListItems,
  removeFromShoppingList,
  addToShoppingList,
  renameAShoppingList,
  deleteAShoppingList,
} from '../../actions/shoppingList.action';
import {
  ANALYTICS_SOURCES,
  ROUTES,
  SCROLL_THRESHOLD,
} from '../../constants/constants';
import {BasketButton} from '../BasketPage/BasketPage.style';
import {ExtraWrapper} from '../../styles/components/wrapper';
import {
  Wrapper,
  Title,
  ButtonWrapper,
  Head,
  ButtonSubWrapper,
  Container,
} from '../../styles/pages/pages';
import {
  AddAll,
  Back,
  EmptyMessage,
  SubHead,
  SubTitle,
  TopButton,
  LoadingWrapper,
} from './ShoppingListsContentPage.style';
import Loading from '../../components/Loading/Loading';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import Product from '../../components/Product/Product';
import PopupModal from '../../components/PopupModal/PopupModal';
import RenameListModal from '../../components/PopupModal/RenameListModal';

class ShoppingListsContentPage extends PureComponent {
  constructor(props) {
    super(props);
    const {match} = props;
    const {listId, listName} = match.params;
    this.gotShoppingListLookup = false;
    this.state = {
      listId,
      listName: decodeURIComponent(listName),
      shoppingListItemsSync: null,
      showRemoveModal: false,
      showRenameModal: false,
    };
  }
  componentDidMount() {
    const {auth, match, getShoppingListById, prevRoute} = this.props;
    setCurrentRoute(ROUTES.SHOPPING_LISTS_CONTENT);
    if (auth) {
      const listId = match.params.listId;
      const listName = match.params.listName;
      this.setState({listId, listName});
      this.gotShoppingListLookup = true;
      if (prevRoute !== ROUTES.PRODUCT) {
        getShoppingListById(listId);
      } else {
        this.syncShoppingListWithBasket();
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      auth,
      shoppingListLookup,
      getShoppingListById,
      getMoreShoppingListItems,
      basketHashMap,
      prevRoute,
    } = this.props;
    const {listId} = this.state;
    if (
      !this.gotShoppingListLookup &&
      auth &&
      (shoppingListLookup === null || listId !== prevState.listId) &&
      prevRoute !== ROUTES.PRODUCT
    ) {
      this.gotShoppingListLookup = true;
      getShoppingListById(listId);
    }
    if (auth !== prevProps.auth && prevRoute !== ROUTES.PRODUCT) {
      if (!shoppingListLookup || shoppingListLookup) {
        getShoppingListById(listId);
      } else if (
        shoppingListLookup &&
        shoppingListLookup.page &&
        shoppingListLookup.page.number
      ) {
        getMoreShoppingListItems(listId, shoppingListLookup.page.number + 1);
      }
    }
    if (
      basketHashMap !== prevProps.basketHashMap ||
      shoppingListLookup !== prevProps.shoppingListLookup
    ) {
      this.syncShoppingListWithBasket();
    }
  }
  syncShoppingListWithBasket = () => {
    const {basketHashMap, shoppingListLookup} = this.props;
    if (shoppingListLookup && shoppingListLookup.results) {
      const shoppingListItemsSync = syncBasketQuantityWithProducts(
        basketHashMap,
        shoppingListLookup.results
      );
      this.setState({shoppingListItemsSync});
    } else {
      this.setState({shoppingListItemsSync: null});
    }
  };
  navToProduct = itemId => {
    const {setPrevRoute, onProductClick} = this.props;
    setPrevRoute(ROUTES.SHOPPING_LISTS_CONTENT);
    onProductClick(itemId);
  };
  loadMore = () => {
    const {shoppingListLookup, getMoreShoppingListItems} = this.props;
    if (
      shoppingListLookup &&
      shoppingListLookup.page &&
      shoppingListLookup.page.number &&
      shoppingListLookup.page.totalPages &&
      shoppingListLookup.page.number === shoppingListLookup.page.totalPages - 1
    ) {
      return;
    }
    getMoreShoppingListItems(
      this.state.listId,
      shoppingListLookup.page.number + 1
    );
  };

  removeItemInList = (listId, itemId) => {
    const isLookupPage = true;
    this.props.removeFromShoppingList(listId, itemId, isLookupPage);
  };
  updateItemInList = (listId, itemId, quantity) => {
    const {removeFromShoppingList, addToShoppingList} = this.props;
    if (quantity === 0) {
      removeFromShoppingList(listId, itemId);
    } else {
      addToShoppingList(listId, itemId, quantity);
    }
  };

  deleteCurrentList = () => {
    const {history, deleteAShoppingList} = this.props;
    deleteAShoppingList(this.state.listId);
    history.goBack();
  };

  removeList = e => {
    e.preventDefault();
    const {shoppingListLookup} = this.props;
    if (
      shoppingListLookup &&
      shoppingListLookup.page &&
      shoppingListLookup.page.totalElements &&
      shoppingListLookup.page.totalElements.length === 0
    ) {
      this.deleteCurrentList();
    } else {
      this.setState({showRemoveModal: true});
    }
  };

  renameList = updatedName => {
    const {renameAShoppingList, history} = this.props;
    const {listId} = this.state;
    renameAShoppingList(listId, updatedName);
    history.push({
      pathname: `/shoppingList/${listId}/${encodeURIComponent(updatedName)}/`,
    });
    this.setState({
      listName: updatedName,
      showRenameModal: false,
    });
  };

  goBack = () => this.props.history.push(`/${ROUTES.SHOPPING_LISTS}`);

  toggleRemoveListModal = () =>
    this.setState({showRemoveModal: !this.state.showRemoveModal});

  toggleRenameListModal = () =>
    this.setState({showRenameModal: !this.state.showRenameModal});

  addAllShoppingList = e => {
    e.preventDefault();
    const {shoppingListLookup, addAllShoppingListToBaskets} = this.props;
    if (
      shoppingListLookup &&
      shoppingListLookup.page &&
      shoppingListLookup.page.totalElements &&
      shoppingListLookup.page.totalElements.length === 0
    ) {
      return;
    }
    addAllShoppingListToBaskets(this.state.listId);
  };
  render() {
    const {
      shoppingListLookup,
      loading,
      branch,
      basket,
      basketHashMap,
      updateBasket,
      updateItem,
      removeItem,
      deviceInfo,
      keywordSearch,
    } = this.props;
    const {
      shoppingListItemsSync,
      listName,
      listId,
      showRenameModal,
      showRemoveModal,
    } = this.state;
    const hasPage = !!(shoppingListLookup && shoppingListLookup.page);
    const hasMore =
      hasPage &&
      shoppingListLookup.page.number < shoppingListLookup.page.totalPages - 1;
    const renderLoading = loading && loading.getShoppingListLookup && (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderListItems =
      shoppingListItemsSync &&
      shoppingListItemsSync.length > 0 &&
      shoppingListItemsSync.map((product, index) => (
        <Product
          product={product}
          branch={branch}
          key={product.itemId + index}
          onProductClick={this.navToProduct}
          loading={loading}
          basketHashMap={basketHashMap}
          updateBasket={updateBasket}
          updateItem={updateItem}
          removeItem={removeItem}
          fulfillmentType={basket && basket.fulfillmentType}
          deviceInfo={deviceInfo}
          source={ANALYTICS_SOURCES.FAVOURITES}
          keywordSearch={keywordSearch}
          showShoppingListQuantity={true}
          shoppingListId={listId}
          updateItemFromShoppingList={this.updateItemInList}
          removeItemFromShoppingList={this.removeItemInList}
        />
      ));
    const renderPlural =
      hasPage && shoppingListLookup.page.totalElements > 1 && 's';
    const theTotalItemsText = hasPage && shoppingListLookup.page.totalElements;
    const renderRemoveList = !!showRemoveModal && (
      <PopupModal
        content={'Are you sure?'}
        subContent={`This shopping list contains ${shoppingListLookup &&
          shoppingListLookup.page &&
          shoppingListLookup.page.totalElements} items`}
        title={`Do you want to delete this shopping list?`}
        showOKButton={true}
        showCancelButton={true}
        callback={this.deleteCurrentList}
        cancelCallBack={this.toggleRemoveListModal}
        closeModalCallback={this.toggleRemoveListModal}
        showModal={true}
        isNotFullScreen={true}
        modalId="modal--remove-a-list"
      />
    );
    const renderRenameListModal = !!showRenameModal && (
      <RenameListModal
        listName={listName}
        callback={this.renameList}
        closeModalCallback={this.toggleRenameListModal}
      />
    );
    const renderSubtitle = !!theTotalItemsText && (
      <SubTitle>
        Total Item{renderPlural}: {theTotalItemsText}
      </SubTitle>
    );
    const renderButtonLoading = loading.addAllShoppingList && (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderAddAllButtonText =
      !loading.addAllShoppingList && 'Add all items';
    const renderAddAllButton = shoppingListItemsSync &&
      shoppingListItemsSync.length > 1 && (
        <TopButton onClick={this.addAllShoppingList}>
          <AddAll>
            {renderButtonLoading}
            {renderAddAllButtonText}
          </AddAll>
        </TopButton>
      );
    const renderEmptyContent = shoppingListItemsSync &&
      shoppingListItemsSync.length === 0 && (
        <EmptyMessage>
          This shopping list is empty. Add to it from any product page.
        </EmptyMessage>
      );
    return (
      <Wrapper>
        {renderRemoveList}
        {renderRenameListModal}
        <Head>
          <Title>
            {listName || 'The Shopping List'}
            {renderSubtitle}
          </Title>
        </Head>
        <SubHead>
          <Back onClick={this.goBack}>&lt; Back to My Shopping Lists</Back>
          <ButtonWrapper>
            <ButtonSubWrapper>
              <TopButton onClick={this.toggleRenameListModal}>Rename</TopButton>
              <TopButton onClick={this.removeList}>Delete</TopButton>
              {renderAddAllButton}
            </ButtonSubWrapper>
          </ButtonWrapper>
        </SubHead>
        <Container>
          {renderLoading}
          <InfiniteScroll
            next={this.loadMore}
            hasMore={hasMore}
            dataLength={
              (shoppingListItemsSync && shoppingListItemsSync.length) || 0
            }
            scrollThreshold={SCROLL_THRESHOLD}
            loader={<Loading isLight={false} />}
          >
            {renderListItems}
          </InfiniteScroll>
          {renderEmptyContent}
        </Container>
        <ExtraWrapper>
          <BasketButton to="/">Continue shopping</BasketButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  shoppingListLookup: state.shoppingListLookup,
  auth: state.auth,
  loading: state.loading,
  branch: state.branch,
  basket: state.basket,
  prevRoute: state.prevRoute,
  basketHashMap: state.basketHashMap,
});

const mapDispatchToProps = dispatch => ({
  getShoppingListById: bindActionCreators(getShoppingListById, dispatch),
  getMoreShoppingListItems: bindActionCreators(
    getMoreShoppingListItems,
    dispatch
  ),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
  addToShoppingList: bindActionCreators(addToShoppingList, dispatch),
  removeFromShoppingList: bindActionCreators(removeFromShoppingList, dispatch),
  deleteAShoppingList: bindActionCreators(deleteAShoppingList, dispatch),
  renameAShoppingList: bindActionCreators(renameAShoppingList, dispatch),
  addAllShoppingListToBaskets: bindActionCreators(
    addAllShoppingListToBaskets,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingListsContentPage);
