import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Close} from '../../styles/pages/modal';
import {Label} from '../../styles/components/checkbox';
import {LoadingContainer} from '../../styles/components/layout';
import {OK} from '../PopupModal/PopupModal.style';

export const CloseButton = styled(Close)`
  display: none;
  ${media.small`
    display: flex;
  `};
`;

export const ReLoginWrapper = styled.div`
  width: 60rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.primaryColor};
  color: ${p => p.theme.primaryTextColor};
  padding: 2rem;
  font-size: 1.4rem;
  ${media.small`
    width: 100%;
  `};
  ${media.smaller`
    height: 100%;
  `};
`;

export const ReLoginCloseButton = styled(Close)`
  display: none;
  ${media.small`
    display: flex;
    background-color: ${p => p.theme.tertiaryBgColor};
    &:before {
      color: ${p => p.theme.primaryColor};
    }
  `};
`;

export const FormWrapper = styled.div`
  min-height: ${p => (p.isLoginModal ? 'auto' : '32rem')};
  max-height: 80vh;
  background-color: ${p => p.theme.tertiaryBgColor};
  overflow-y: ${p => (p.nonIframe ? 'auto' : 'hidden')};
  padding: ${p => (p.nonIframe ? '2rem' : '50.25% 0 0 0')};
  position: relative;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
    min-width: 100%;
  }
  ${media.small`
    padding-top: 0;
    min-height: 28.5rem;
  `};
  ${media.smaller`  
    height: 100%;
    max-height: calc(100% - 11.6rem);
  `};
  ${media.smallest`
    min-height: 30.5rem;
    flex-grow: 1;
  `};
`;

export const ThankYouWrapper = styled.div`
  display: flex;
  color: ${p => p.theme.textColor};
  margin-bottom: ${p => (p.margin ? '1rem' : 0)};
  line-height: 3rem;
  flex-direction: column;
`;

export const ThankYouTitle = styled.h1`
  color: ${p => p.theme.textColor};
  line-height: 3rem;
  font-size: 1.6rem;
`;

export const ThankYouContent = styled.p`
  color: ${p => p.theme.textColor};
  line-height: 1.6rem;
  font-size: 1.4rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const LoginModalLinkWrapper = styled.div`
  wdith: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.4rem;
  ${media.small`
    justify-content: flex-start;
  `};
`;

export const LoginModalLink = styled.span`
  font-size: 1.2rem;
  cursor: pointer;
  color: ${p => p.theme.secondaryColor};
  text-decoration: underline;
`;

export const FormSelect = styled.select`
  flex-basis: 100%;
  margin: 0.9rem;
`;
export const ErrorsField = styled.div`
  min-height: 1.6rem;
  margin: 1rem;
`;

export const OptIn = styled.div`
  margin: 0 1rem;
  ${media.small`
    margin-left: 0.5rem;
  `};
`;

export const OptInCheckboxes = styled.div`
  padding: 0 1rem;
  ${media.small`
    padding: 0;
  `};
`;

export const CheckboxInput = styled.input`
  color: ${p => p.theme.secondaryColor};
  margin-right: 0.5rem;
`;

export const CheckboxText = styled.span`
  color: ${p => (p.checked ? p.theme.secondaryColor : p.theme.textColor)};
`;

export const Error = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 1rem 2rem;
  background-color: ${p => p.theme.errorBgColor};
`;

export const Details = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1rem;
  padding: 1rem 2rem;
`;

export const FormButton = styled.div`
  flex-basis: 100%;
  padding: 0 1rem;
  text-transform: uppercase;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p => p.theme.primaryColor};
  margin: 0.5rem;
  line-height: 4rem;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.secondaryColor};
  }
`;

export const Title = styled.span`
  color: ${p => p.theme.secondaryColor};
  width: 100%;
`;

export const InfoText = styled.span`
  font-style: italic;
  line-height: 2rem;
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const Type = styled(Label)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Column = styled.div`
  width: 50%;
  display: flex;
  ${media.small`
    width: 100%;
  `};
`;

export const RadioText = styled.div`
  color: ${p =>
    p.isChecked ? p.theme.secondaryColor : p.theme.tertiaryTextColor};
`;

export const ListWrapper = styled.fieldset`
  margin: 0.4rem;
  padding: 0;
  border: 0;
  width: 100%;
  display: inline-block;
  ${media.small`
    width: auto;
  `};
`;

export const SelectList = styled.select`
  width: 100%;
  height: 4rem;
  outline: none;
  border: ${p =>
    `solid 0.1rem ${p.error ? p.theme.tertiaryColor : p.theme.borderColor}`};
  ${media.small`
    border-radius: none;
    padding-left: 0;
    padding-right: 0;
    color: ${p => p.theme.textColor};
    background-color: ${p => p.theme.tertiaryBgColor};
  `};
`;

export const ReLoginHeader = styled.div`
  width: 100%;
  padding: 2rem 1.5rem 1rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => p.theme.primaryColor};
  text-align: center;
`;

export const LogoutButton = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 2rem 2rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.secondaryColor};
  font-weight: bold;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 1rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  ${media.small`
    padding: 2rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
  `};
`;

export const PasswordWrapper = styled(ContentWrapper)`
  ${media.small`
    max-height: 10rem;
  `};
`;

export const Tel = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
  padding: 0 0.4rem;
`;

export const TCText = styled.div`
  color: ${p => p.theme.textColor};
  font-size: 1.2rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  padding: 1.2rem 2rem;
  line-height: 1.8rem;
`;

export const Link = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  cursor: pointer;
`;

export const LoadingWrapper = styled(LoadingContainer)`
  padding: 2rem;
  flex-direction: column;
`;

export const LoadingText = styled.div`
  color: ${p => p.theme.textColor};
  text-align: center;
  min-height: 4rem;
  padding-bottom: 8rem;
`;

export const Password = styled.input`
  font-size: 1.4rem;
  padding-left: 2rem;
  margin: 1rem;
  background-color: transparent;
  border: solid 0.1rem
    ${p => (p.isError ? p.theme.tertiaryColor : p.theme.borderColor)};
  outline: none;
  width: 100%;
  height: 4rem;
  &::placeholder {
    color: ${props => props.theme.tertiaryTextColor};
    text-transform: uppercase;
  }
  ${media.small`
    margin: 0;
  `};
`;

export const LoginText = styled.div`
  font-size: 1.6rem;
  color: ${p => (p.isError ? p.theme.tertiaryColor : p.theme.textColor)};
  background-color: ${p => p.theme.tertiaryBgColor};
  padding: 1rem 2rem;
  text-align: center;
`;

export const LoginButton = styled(OK)`
  width: 50%;
  &:hover {
    svg {
      fill: ${p => p.theme.primaryTextColor};
    }
  }
  ${media.small`
    margin: 2rem 0 0;
    width: 100%;
  `};
`;
