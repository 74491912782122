import React, {PureComponent} from 'react';
import PopupModal from './PopupModal';
import {BASKET_ERRORS_TYPE, ERRORS} from '../../constants/constants';
import {REMOVE_BASKET_ITEM_FAILED} from '../../actions/types.action';
import {getBasketErrorMessage} from '../../helpers/string.helper';

export default class BasketErrorsModal extends PureComponent {
  state = {
    message: {
      title: '',
      content: '',
    },
  };

  componentDidMount() {
    const {error, minOrder} = this.props;
    if (error && typeof error.message !== 'string') {
      error.message = 'Something went wrong.';
    }
    let message;
    const actionType =
      error.actionType === REMOVE_BASKET_ITEM_FAILED ? 'removed' : 'added';
    switch (error.code) {
      case ERRORS.BASKET_ONLY_OOS_ITEMS:
        message = {
          title: 'Your basket items are out of stock',
          content: 'Please add new items to your basket before checking out',
        };
        break;
      case ERRORS.BASKET_NO_ITEMS:
        message = {
          title: 'Your basket is empty',
          content: 'Please add items before checking out',
        };
        break;
      case ERRORS.UPDATE_BASKET:
        message = {
          title: `Product could not be ${actionType}`,
          content: error.message,
        };
        break;
      case ERRORS.SLOT_EXPIRED:
      case ERRORS.SLOT_EXPIRED_DURING_CHECKOUT:
        const issueMessage = getBasketErrorMessage(error.issue);
        message = {
          title: 'Slot expired',
          content: `${issueMessage} as your slot has expired.`,
          subContent: 'Please book a new one',
        };
        if (error.issue === BASKET_ERRORS_TYPE.SLOT_EXPIRED_DURING_CHECKOUT) {
          message.subContent = 'Please book a new slot and checkout again.';
        }
        break;
      case ERRORS.TOTAL_DISCREPANCY:
        message = {
          title: 'PLEASE CHECKOUT AGAIN',
          content: 'Your order total has been changed since your payment.',
          subContent: 'You must checkout again to submit your order.',
        };
        break;
      case ERRORS.UPDATE_SUBSTITUTE_ITEM:
        message = {
          title: `Substitute item could not be ${actionType}`,
          content: error.message,
        };
        break;
      case ERRORS.PRICE_NOT_FOUND:
        message = {
          title: `Product could not be ${actionType}`,
          content: 'Default price not found, please try again',
        };
        break;
      case ERRORS.BASKET_MIN_DELIVERY_ORDER:
        message = {
          title: 'Minimum not reached',
          content: `You must spend at least £${minOrder} for a delivery. Please add more items or switch to collection.`,
        };
        break;
      case ERRORS.ORDER_ERROR:
        message = {
          title: 'Payment error',
          content: `There was a problem with your payment, please try again.`,
        };
        break;
    }
    this.setState({message});
  }

  callback = () => {
    const {
      removeFulfillSlot,
      basket,
      error,
      callback,
      navigateToBookSlot,
      navigateToBasket,
    } = this.props;
    this.setState({loading: true});
    callback();
    if (error.code === ERRORS.SLOT_EXPIRED) {
      if (basket && basket.uuid) {
        removeFulfillSlot(basket.uuid);
      }
      const slotExpired = true;
      navigateToBookSlot(slotExpired);
    } else if (error.code === ERRORS.TOTAL_DISCREPANCY) {
      // when ax has a different total value for the order than olympus
      navigateToBasket();
    } else {
      callback();
    }
  };
  render() {
    const {showModal, error} = this.props;
    const {message} = this.state;
    if (!message || (!message && message.title)) {
      return null;
    }
    return (
      <PopupModal
        modalName={'Basket Error' + ((error && error.code) || '')}
        content={message.content}
        subContent={message.subContent}
        title={message.title}
        isError={true}
        loading={this.state.loading}
        showOKButton={true}
        showModal={showModal}
        callback={this.callback}
        showCancelButton={false}
        closeModalCallback={this.callback}
      />
    );
  }
}
