import React, {PureComponent} from 'react';
import ReactMarkdown from 'react-markdown';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {MarkdownWrapper} from '../../styles/pages/pages';
import {Error, SectionWrapper} from './TeamPage.style';
import ImageComponent from '../../components/Image/Image';
import Loading from '../../components/Loading/Loading';
import {getTeam} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class TeamPage extends PureComponent {
  componentDidMount() {
    const {getTeam} = this.props;
    getTeam();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (cms && cms.team && (prevProps.cms && prevProps.cms.team) !== cms.team) {
      setCmsHeadTags(cms.team);
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const team = cms && cms.team;
    const renderMarkdown = team && team.content && (
      <ReactMarkdown
        children={markdownConvert(team.content)}
        escapeHtml={false}
      />
    );
    const renderMedia = team && team.media && (
      <ImageComponent url={team.media} alt={team.heading} />
    );
    const renderLoading = loading && loading.team && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.team && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{team && team.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getTeam: bindActionCreators(getTeam, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamPage)
);
