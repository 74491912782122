import React, {PureComponent} from 'react';
import {
  CompactWrapper,
  SubMinus,
  CompactValue,
  SubRemove,
  CompactAdd,
} from './Quantity.style';
import {DEFAULT_DEBOUNCE_TIME} from '../../constants/constants';
import {debounce} from '../../helpers/events.helper';

export default class CompactQuantity extends PureComponent {
  constructor(props) {
    super(props);
    const {quantity} = props;
    this.state = {
      value: quantity || 1,
      availableQuantities: [],
    };
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  cancel = () => this.setState({value: this.props.quantity});

  add = () => {
    const {quantity, update, step} = this.props;
    if (!this.addedCount) {
      this.addedCount = 0;
    }
    this.addedCount += 1;
    this.totalCount = quantity + this.addedCount * step;
    if (!this.debouncedAddFn) {
      this.debouncedAddFn = debounce(() => {
        update(this.totalCount);
        this.addedCount = 0;
      }, DEFAULT_DEBOUNCE_TIME);
    }
    this.debouncedAddFn();
  };

  remove = () => {
    const {quantity, update, remove, step} = this.props;
    if (
      quantity === 1 ||
      quantity === step ||
      this.totalRemovedCount === 0 ||
      this.totalRemovedCount < 0
    ) {
      remove();
    } else {
      update(quantity - step);
    }
    this.removedCount = 0;
  };

  update = () => {
    const {quantity, update, step} = this.props;
    update(quantity + step);
  };
  render() {
    const {quantity, isLight, step, isWide} = this.props;
    const renderMinus = quantity > 1 && quantity > step && (
      <SubMinus onClick={this.remove} data-rw="compact-quantity--minus" />
    );
    const renderRemove = (quantity === 1 || quantity === step) && (
      <SubRemove onClick={this.remove} data-rw="compact-quantity--remove" />
    );
    return (
      <CompactWrapper isLight={isLight} isWide={isWide}>
        {renderMinus}
        {renderRemove}
        <CompactValue data-rw="compact-quantity--value">
          {quantity}
        </CompactValue>
        <CompactAdd onClick={this.add} data-rw="product--add-button" />
      </CompactWrapper>
    );
  }
}
