import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {captureException, withScope} from '@sentry/browser';
import popularUrl from '../../images/popular.png';

import {
  Container,
  OfferWrapper,
  Like,
  OfferText,
  NameWrapper,
  Name,
  PhotoWrapper,
  Info,
  Id,
  Weight,
  PriceWrapper,
  Price,
  AddButton,
  Content,
  LoadingWrapper,
  ButtonStyled,
  QuantityWrapper,
  TopButtonGroup,
  NewProduct,
  OutOfStockText,
  OutOfStockButtonText,
  SubQuantityWrapper,
  ThePhoto,
  AvailableFromButtonText,
  ExtraPromo,
  ExtraPromoBig,
  ExtraPromoSmaller,
  PriceType,
  PriceText,
  PriceWholeWrapper,
  Details,
  PromoWrapper,
} from './Product.style';
import Loading from '../Loading/Loading';
import Quantity from '../Quantity/Quantity';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {SmallProduct} from './SmallProduct';
import Favorite from '../Favorite/Favorite';
import {
  ANALYTICS_EVENTS,
  OFFER_END_DATE_FULL_FORMAT,
  ERRORS,
  OFFER_ENDS_DATE_FORMAT_SHORT,
  SERVICE_TYPE,
  DEFAULT_DEBOUNCE_TIME,
  STOCK_CHECK,
  DEFAULT_BRANCH,
  PROMO_TAGS,
  PROMO_TAGS_MAP,
} from '../../constants/constants';
import {addFavourite, removeFavourite} from '../../actions';
import PreviouslyPurchased from '../PreviouslyPurchased/PreviouslyPurchased';
import {ListProduct} from './ListProduct';
import Promotion from '../Promotion/Promotion';
import ToolTipsImage from '../ToolTipsImage/ToolTipsImage';
import ImageComponent from '../Image/Image';
import {
  getPriceUnavailableText,
  getProductMax,
  getProductStep,
} from '../../helpers/product.helper';
import {getBasketItemsCodes, getProductItem} from '../../helpers/basket.helper';
import {getCategoryName, getQtyInList} from '../../helpers/array.helper';
import {debounce} from '../../helpers/events.helper';
import CompactQuantity from '../Quantity/CompactQuantity';
import {reduceImageSize} from '../../helpers/image.helper';
import {getAvailableAtDateString} from '../../helpers/string.helper';
import {getPromoPrice} from '../../helpers/data.helper';
import SentryHelper from '../../helpers/sentry.helper';

class Product extends PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired,
    fulfillmentType: PropTypes.string,
    currency: PropTypes.string,
    onProductClick: PropTypes.func,
    updateBasket: PropTypes.func,
    updateItem: PropTypes.func,
    removeItem: PropTypes.func,
    loading: PropTypes.object,
    isSmallView: PropTypes.bool,
  };

  static defaultProps = {
    currency: '£',
  };

  constructor(props) {
    super(props);
    const {
      fulfillmentType,
      product,
      shoppingListId,
      showShoppingListQuantity,
    } = props;
    const isPbbOffer =
      product &&
      product.offer &&
      product.offer.promoTagId === PROMO_TAGS.Pay_By_Bank;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    let desiredQuantity;
    if (
      product &&
      product.shoppingLists &&
      shoppingListId &&
      showShoppingListQuantity
    ) {
      const qty = getQtyInList(product.shoppingLists, shoppingListId);
      if (qty) {
        desiredQuantity = qty;
      }
    }
    this.state = {
      isFavorite: product.favourite,
      loading: false,
      deliveryPrice: null,
      collectionPrice: null,
      step: getProductStep(product, isDelivery),
      max: getProductMax(product, isDelivery),
      desiredQuantity,
      isPbbOffer,
      discountPrice: getPromoPrice(
        product && product.offer && product.offer.promoDisAmt
      ),
    };
    this.offerDate =
      product &&
      product.offer &&
      moment(product.offer.promoEnd, OFFER_END_DATE_FULL_FORMAT).format(
        OFFER_ENDS_DATE_FORMAT_SHORT
      );

    if (
      !(product.delivery && product.delivery.price) &&
      !(product.collection && product.collection.price)
    ) {
      GoogleAnalyticsHelper.trackEvent(ERRORS.PRICE_NOT_FOUND, {
        product: product.itemId,
      });
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.loading && !props.loading.addingToBasket) {
      return {
        loading: false,
      };
    }
    return null;
  }

  onFavoriteClick = product => {
    this.setState({isFavorite: !this.state.isFavorite});
    if (this.state.isFavorite) {
      this.props.removeFavouriteFunc(product);
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.REMOVE_FAVOURITE, {
        product,
      });
    } else {
      this.props.addFavouriteFunc(product);
      GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.ADD_FAVOURITE, {
        product,
      });
    }
  };
  addToBasket = e => {
    e.preventDefault();
    const {product, updateBasket, source} = this.props;
    const {loading, desiredQuantity} = this.state;
    if (loading && !product) {
      return;
    }
    if (!this.clickCount) {
      this.clickCount = 0;
    }
    this.clickCount += 1;
    if (!this.state.step) {
      SentryHelper.debugQuantityZero({
        message: `Product.js addToBasket() - qty: ${
          this.state.step
        } (${typeof this.state
          .step}), desiredQuantity: ${desiredQuantity} (${typeof desiredQuantity}), this.clickCount: ${
          this.clickCount
        }`,
      });
    }
    const item = getProductItem(product, this.state.step);
    const analyticsObj = {
      ...{
        product: {
          ...product,
          categoryName: getCategoryName(product),
        },
      },
      quantity: this.state.step,
      source,
    };
    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        const updateItem = {
          ...item,
          ...{quantity: desiredQuantity || this.clickCount * this.state.step},
        };
        updateBasket(updateItem, analyticsObj);
        this.clickCount = 0;
        this.setState({loading: true});
      }, DEFAULT_DEBOUNCE_TIME);
    }
    this.debouncedFn();
  };

  remove = () => {
    const {removeItem, product, basketHashMap} = this.props;
    const basketItemUuid =
      product &&
      product.itemId &&
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].basketItemUuid;
    if (basketItemUuid) {
      removeItem(basketItemUuid, product);
    } else {
      withScope(scope => {
        scope.setExtra('product', product);
        scope.setExtra('basketHashMap', basketHashMap);
        captureException(new Error(ERRORS.REMOVE_ITEM));
      });
    }
  };

  goToProductDetailPage = () => {
    const {product, onProductClick} = this.props;
    if (product && product.itemId && onProductClick) {
      onProductClick(product.itemId);
    }
  };

  onProductNameClick = e => e && e.preventDefault();

  gotoAlternatives = () => {
    const {product, substitutes, keywordSearch} = this.props;
    const query =
      substitutes &&
      product &&
      product.itemId &&
      getBasketItemsCodes(substitutes[product.itemId]);
    if (!query) {
      return;
    }
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.SEARCH_ALTERNATIVES, {
      itemId: product.itemId,
      query,
    });
    keywordSearch(query);
  };

  removeDesiredQty = () => {
    const {removeItemFromShoppingList, shoppingListId, product} = this.props;
    removeItemFromShoppingList(shoppingListId, product.itemId);
  };
  updateDesiredQty = qty => {
    const {shoppingListId, product, updateItemFromShoppingList} = this.props;
    if (!qty) {
      SentryHelper.debugQuantityZero({
        message: `Product.js updateDesiredQty() - qty: ${qty} (${typeof qty})`,
      });
    }
    this.setState({desiredQuantity: qty});
    updateItemFromShoppingList(shoppingListId, product.itemId, qty);
  };

  render() {
    const {
      product,
      currency,
      isListView,
      updateItem,
      isSmallView,
      auth,
      loading,
      deviceInfo,
      fulfillmentType,
      basketHashMap,
      substitutes,
      showShoppingListQuantity,
      isLimitedSpace,
    } = this.props;
    const {desiredQuantity, isPbbOffer, discountPrice} = this.state;
    const {delivery, collection} = product;
    const stockCheck =
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].stockCheck;
    const isOOs =
      stockCheck &&
      (stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ||
        stockCheck.result === STOCK_CHECK.DISCONTINUED);
    const isDiscontinued =
      stockCheck && stockCheck.result === STOCK_CHECK.DISCONTINUED;
    const isNotAllowed =
      stockCheck && stockCheck.result === STOCK_CHECK.NOT_ALLOWED;
    const isSubstituteAvailable =
      substitutes &&
      substitutes[product.itemId] &&
      substitutes[product.itemId].length > 0;
    const unitPrice =
      basketHashMap &&
      basketHashMap[product.itemId] &&
      basketHashMap[product.itemId].unitPrice;
    const renderOosTextContent = isDiscontinued
      ? 'Discontinued'
      : isNotAllowed
      ? 'Not Allowed'
      : 'Out of Stock';
    const isListMode = isListView || (deviceInfo && deviceInfo.isPhone);
    const isSmallViewMode =
      isSmallView && product && !(deviceInfo && deviceInfo.isPhone);
    const hasPromoText =
      product &&
      product.offer &&
      product.offer.promoDiscountText &&
      product.offer.promoDiscountText.length > 0;
    const renderPromotion = (
      <PromoWrapper>
        <Promotion
          offer={product && product.offer}
          promo={product && product.offer && product.offer.promoDiscountText}
          collectionPrice={
            product && product.collection && product.collection.price
          }
          unitPrice={
            product && product.collection && product.collection.unitPriceDisplay
          }
          isListMode={isListMode && !isSmallView}
        />
      </PromoWrapper>
    );
    const isNewProduct = product && (product.new || product.isNew);
    const isOffer = product && product.offer && product.offer.promoEnd;
    const renderIsNewProduct = isNewProduct && (
      <NewProduct isOffer={isOffer}>NEW</NewProduct>
    );
    const renderOfferDate = isOffer && (
      <OfferText isLimitedSpace={isLimitedSpace} isNew={isNewProduct}>
        {'Offer ends '}
        {this.offerDate}
      </OfferText>
    );
    const renderButtonLoading = this.state.loading && (
      <LoadingWrapper>
        <Loading isLight={true} />
      </LoadingWrapper>
    );
    const renderButtonContent =
      !this.state.loading &&
      `Add ${desiredQuantity ? desiredQuantity : 'item'}`;

    const renderAddButton =
      !isOOs &&
      (product && product.basketQuantity > -1 ? (
        <QuantityWrapper hasPromoText={hasPromoText}>
          <Quantity
            quantity={product.basketQuantity}
            update={updateItem}
            uuid={product.basketItemUuid}
            remove={this.remove}
            step={this.state.step}
            max={this.state.max}
            loading={loading}
            stockCheck={stockCheck}
            unitPrice={unitPrice}
          />
        </QuantityWrapper>
      ) : (
        <AddButton
          onClick={this.addToBasket}
          isLoading={this.state.loading}
          data-rw="product--add-button"
        >
          {renderButtonLoading}
          {renderButtonContent}
        </AddButton>
      ));

    const renderDesireQtyButton = !!showShoppingListQuantity && (
      <SubQuantityWrapper data-rw="desire-qty-button">
        <CompactQuantity
          quantity={desiredQuantity}
          remove={this.removeDesiredQty}
          update={this.updateDesiredQty}
          step={this.state.step}
        />
      </SubQuantityWrapper>
    );

    const renderAlternativesButton = isOOs && isSubstituteAvailable && (
      <AddButton
        onClick={this.gotoAlternatives}
        isLoading={this.state.loading}
        data-rw="product--alternatives-button"
      >
        Alternatives
      </AddButton>
    );
    const renderOOsText = isOOs &&
      !isSubstituteAvailable &&
      !stockCheck.availableAt && (
        <OutOfStockButtonText>{renderOosTextContent}</OutOfStockButtonText>
      );

    const renderAdvancedText = isOOs &&
      !isSubstituteAvailable &&
      !!stockCheck.availableAt && (
        <AvailableFromButtonText>
          {getAvailableAtDateString(stockCheck.availableAt)}
        </AvailableFromButtonText>
      );

    const renderLike = !!auth && (
      <Like onClick={() => this.onFavoriteClick(product)}>
        <Favorite
          isOffer={!!product.offer}
          isFavorite={this.state.isFavorite}
        />
      </Like>
    );

    const renderPreviouslyPurchased = product.previouslyPurchased && (
      <PreviouslyPurchased isOffer={!!product.offer} />
    );

    const renderPopular = (product.popular || product.isPopular) && (
      <ToolTipsImage url={popularUrl} toolTipsContent={'Popular'} />
    );
    const renderOfferTab = (
      <OfferWrapper isOffer={!!product.offer}>
        {renderIsNewProduct}
        {renderOfferDate}
        <TopButtonGroup>
          {renderPopular}
          {renderPreviouslyPurchased}
          {renderLike}
        </TopButtonGroup>
      </OfferWrapper>
    );
    const hasProductOffer = !!product && !!product.offer;
    const renderOfferTitle =
      (product &&
        product.offer &&
        product.offer.promoTagId &&
        PROMO_TAGS_MAP[product.offer.promoTagId]) ||
      'offer';
    if (isSmallViewMode) {
      return (
        <SmallProduct
          product={product}
          currency={currency}
          updateItem={updateItem}
          deliveryPrice={delivery && delivery.price}
          collectionPrice={collection && collection.price}
          loading={this.state.loading}
          loadingProps={loading}
          addToBasket={this.addToBasket}
          goToProductDetailPage={this.goToProductDetailPage}
          remove={this.remove}
          step={this.state.step}
          max={this.state.max}
          fulfillmentType={fulfillmentType}
          stockCheck={stockCheck}
          unitPrice={unitPrice}
          isOOs={isOOs}
          oosText={renderOosTextContent}
          renderOfferTab={renderOfferTab}
          hasProductOffer={hasProductOffer}
          discountPrice={discountPrice}
          renderOfferTitle={renderOfferTitle}
        />
      );
    }
    if (isListMode) {
      return (
        <ListProduct
          product={product}
          currency={currency}
          updateItem={updateItem}
          deliveryPrice={delivery && delivery.price}
          collectionPrice={collection && collection.price}
          loading={this.state.loading}
          loadingProps={loading}
          addToBasket={this.addToBasket}
          goToProductDetailPage={this.goToProductDetailPage}
          remove={this.remove}
          step={this.state.step}
          max={this.state.max}
          renderOfferTab={renderOfferTab}
          fulfillmentType={fulfillmentType}
          stockCheck={stockCheck}
          unitPrice={unitPrice}
          isOOs={isOOs}
          oosText={renderOosTextContent}
          isSubstituteAvailable={isSubstituteAvailable}
          gotoAlternatives={this.gotoAlternatives}
          renderDesireQtyButton={renderDesireQtyButton}
          isPbbOffer={isPbbOffer}
          renderPromotion={renderPromotion}
          hasProductOffer={hasProductOffer}
          discountPrice={discountPrice}
          renderOfferTitle={renderOfferTitle}
        />
      );
    }
    const renderPrice = !(isOOs && isSubstituteAvailable) &&
      ((collection && collection.price > 0) ||
        (delivery && delivery.price > 0)) && (
        <PriceWholeWrapper>
          {collection && (
            <Price>
              <PriceType>Collection:</PriceType>
              <PriceText>
                {currency}
                {collection.price}
              </PriceText>
            </Price>
          )}
          {delivery && (
            <Price>
              <PriceType>Delivery:</PriceType>
              <PriceText alignRight={true}>
                {currency}
                {delivery.price}
              </PriceText>
            </Price>
          )}
        </PriceWholeWrapper>
      );
    const promoDisAmt = product && product.offer && product.offer.promoDisAmt;
    const isMultiBuyOffer =
      !!product &&
      product.offer &&
      product.offer.promoMinQty &&
      product.offer.promoMinQty > 1;
    const renderOfferPrice = !(isOOs && isSubstituteAvailable) &&
      product &&
      product.offer &&
      (promoDisAmt >= 0 || (!!isMultiBuyOffer && product.offer.promoPrice)) &&
      ((collection && collection.price > 0) ||
        (delivery && delivery.price > 0)) && (
        <PriceWholeWrapper>
          {collection && (
            <Price>
              <PriceType isOffer={true}>
                {isMultiBuyOffer ? 'collection only' : renderOfferTitle}:
              </PriceType>
              <PriceText isOffer={true}>
                {currency}
                {isMultiBuyOffer
                  ? product.offer.promoPrice
                  : (collection.price - product.offer.promoDisAmt).toFixed(2)}
              </PriceText>
            </Price>
          )}
          {delivery && !isMultiBuyOffer && (
            <Price>
              <PriceType isOffer={true}>{renderOfferTitle}:</PriceType>
              <PriceText isOffer={true} alignRight={true}>
                {currency}
                {(delivery.price - product.offer.promoDisAmt).toFixed(2)}
              </PriceText>
            </Price>
          )}
        </PriceWholeWrapper>
      );
    const renderMultiplePrice = !(
      isOOs &&
      isSubstituteAvailable &&
      collection &&
      delivery
    ) &&
      product &&
      !product.offer &&
      (product.collectionMulti || product.deliveryMulti) && (
        <PriceWholeWrapper>
          <Price>
            <PriceType>
              {product.collectionMulti &&
                product.collectionMulti.min &&
                `Buy ${product.collectionMulti.min}+:`}
            </PriceType>
            <PriceText>
              {product.collectionMulti &&
                product.collectionMulti.priceInc &&
                `${currency}${product.collectionMulti.priceInc.toFixed(2)}`}
            </PriceText>
          </Price>
          <Price>
            <PriceType>
              {product.deliveryMulti &&
                product.deliveryMulti.min &&
                `Buy ${product.deliveryMulti.min}+:`}
            </PriceType>
            <PriceText alignRight={true}>
              {product.deliveryMulti &&
                product.deliveryMulti.priceInc &&
                `${currency}${product.deliveryMulti.priceInc.toFixed(2)}`}
            </PriceText>
          </Price>
        </PriceWholeWrapper>
      );
    const renderOOsPrice = isOOs &&
      isSubstituteAvailable &&
      !stockCheck.availableAt && (
        <OutOfStockText>{renderOosTextContent}</OutOfStockText>
      );
    const renderAdvancedPrice = isOOs &&
      isSubstituteAvailable &&
      !!stockCheck.availableAt && (
        <AvailableFromButtonText>
          {getAvailableAtDateString(stockCheck.availableAt)}
        </AvailableFromButtonText>
      );
    const renderPriceUnavailable =
      !collection && !delivery && getPriceUnavailableText();
    const imageUrl = product.imageLinks && product.imageLinks[0];
    const renderPromoDiscount = !!discountPrice && (
      <span>
        Extra <ExtraPromoBig>{discountPrice}</ExtraPromoBig> off
      </span>
    );
    // should be multi buy instead of fresh product
    const renderMultiBuyDiscount = isMultiBuyOffer &&
      !discountPrice &&
      !!product.offer &&
      product.offer.onOffer && (
        <span>
          Buy {product.offer.promoMinQty}+{' '}
          <ExtraPromoSmaller>
            £{!!product.offer.promoPrice && product.offer.promoPrice.toFixed(2)}
          </ExtraPromoSmaller>
        </span>
      );
    return (
      <Container image={product.image} href={product.url} key={product.id}>
        {renderOfferTab}
        <Content onClick={this.goToProductDetailPage}>
          <PhotoWrapper>
            {renderDesireQtyButton}
            <ThePhoto>
              <ImageComponent
                url={reduceImageSize(imageUrl)}
                alt={product.itemName}
              />
            </ThePhoto>
          </PhotoWrapper>
          <ExtraPromo>
            {renderPromoDiscount}
            {renderMultiBuyDiscount}
          </ExtraPromo>
          <NameWrapper
            onClick={this.goToProductDetailPage}
            data-rw="product--name"
          >
            <Info>
              <Id>{product && product.itemId}</Id>
              <Weight isOffer={hasProductOffer}>
                {(!!collection && collection.unitPriceDisplay) || ''}
              </Weight>
            </Info>
            <Name
              href={`/product/${DEFAULT_BRANCH}/${product.itemId}/`}
              onClick={this.onProductNameClick}
            >
              {product.itemName}
            </Name>
          </NameWrapper>
          <Details>
            <PriceWrapper>
              {renderPrice}
              {renderOfferPrice}
              {renderMultiplePrice}
              {renderOOsPrice}
              {renderAdvancedPrice}
              {renderPriceUnavailable}
            </PriceWrapper>
          </Details>
        </Content>
        <ButtonStyled>
          {renderAddButton}
          {renderAlternativesButton}
          {renderOOsText}
          {renderAdvancedText}
        </ButtonStyled>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  substitutes: state.substitutes,
});

const mapDispatchToProps = dispatch => ({
  addFavouriteFunc: bindActionCreators(addFavourite, dispatch),
  removeFavouriteFunc: bindActionCreators(removeFavourite, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
