import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  BranchesWrapper,
  LongButton,
  ButtonsWrapper,
  ChangePaymentInfo,
} from './PopupModal.style';

export default class CardPaymentModal extends PureComponent {
  changePaymentMethod = () => {
    const {closeModalCallback, changeToPbbPayment, hasPbbPayment} = this.props;
    if (hasPbbPayment) {
      changeToPbbPayment();
    }
    closeModalCallback();
  };

  render() {
    const {
      showModal,
      closeModalCallback,
      callback,
      hasPbbPayment,
      promoTotal,
    } = this.props;
    const paymentMethodText = hasPbbPayment ? 'Pay By Bank' : 'Card';
    const total = hasPbbPayment ? promoTotal.payByBank : promoTotal.onlineCard;

    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw={'cash-modal'}
      >
        <StyledWrapper>
          <Content>
            <BranchesWrapper>
              <Title>Card order payment</Title>
              <ChangePaymentInfo>
                You could save £{total} using {paymentMethodText}.
              </ChangePaymentInfo>
              <ButtonsWrapper wideButtons>
                <LongButton onClick={this.changePaymentMethod}>
                  Use {paymentMethodText}
                </LongButton>
                <LongButton onClick={callback}>Continue as Card</LongButton>
              </ButtonsWrapper>
            </BranchesWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
