import React from 'react';
import PropTypes from 'prop-types';
import {Favorited, OutlinedFavorite} from './Favorite.style';

const Favorite = props => {
  const {isFavorite, isOffer} = props;
  let renderFavorite;
  if (isOffer) {
    renderFavorite = isFavorite ? (
      <OutlinedFavorite isWhite={true} />
    ) : (
      <Favorited isWhite={true} />
    );
  } else {
    renderFavorite = isFavorite ? <Favorited /> : <OutlinedFavorite />;
  }
  return renderFavorite;
};

Favorite.propTypes = {
  isOffer: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
};

export default Favorite;
