import styled from 'styled-components';
import {Button3Inverted} from '../../styles/components/buttons';
import {media} from '../../styles/components/media-queries';

export const DropdownWrapper = styled.div`
  max-width: 60%;
  flex-basis: 60%;
  position: relative;
  ${media.medium`
      margin: 1rem 0;
      max-width: 100%;
  `};
`;

export const DropButton = styled(Button3Inverted)`
  margin-top: 1rem;
  position: relative;
  padding-right: 2rem;
  width: 100%;
  &:hover::before {
    color: ${p => p.theme.primaryTextColor};
  }
  &::before {
    font-family: 'icomoon';
    content: '\\e910';
    font-size: 3rem;
    font-weight: normal;
    position: absolute;
    right: 1rem;
    color: ${p => p.theme.secondaryColor};
    transform: ${p => (p.active ? 'rotate(180deg)' : 'none')};
  }
  ${media.medium`
     margin-top: 1rem;
  `};
`;

export const ListWrapper = styled.div`
  border: ${p => `solid 0.2rem ${p.theme.secondaryColor}`};
  border-top-width: 0;
  border-bottom-width: 0;
`;

export const ListDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  line-height: 4rem;
  padding-left: 1rem;
  border-bottom: ${p => `solid 0.2rem ${p.theme.secondaryColor}`};
  color: ${p => p.theme.textColor};
  &:hover {
    color: ${p => p.theme.secondaryColor};
    border-bottom-color: ${p => p.theme.primaryColor};
  }
  cursor: pointer;
`;

export const AddAList = styled.div`
  line-height: 4rem;
  text-align: center;
  font-weight: bold;
  background-color: ${p => p.theme.primaryColor};
  color: ${p => p.theme.primaryTextColor};
  cursor: pointer;
  text-transform: uppercase;
`;

export const AddInput = styled.input`
  line-height: 4rem;
  max-height: 4rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  padding-left: 1rem;
  color: ${p => p.theme.textColor};
  border: 0;
  width: 100%;
  outline: none;
  &::placeholder {
    color: ${p => p.theme.quaternaryTextColor};
  }
`;

export const AddInputWrapper = styled.div`
  width: 100%;
  display: flex;
  border: ${p => `solid 0.2rem ${p.theme.primaryColor}`};
  border-top-width: 0;
`;

export const SubmitButton = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${p => p.theme.primaryColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover::before {
    background-color: ${p => p.theme.primaryColorHover};
  }
  &::before {
    font-family: 'icomoon';
    content: '\\e90d';
    font-size: 3rem;
    font-weight: normal;
    color: ${p => p.theme.primaryTextColor};
    transform: ${p => (p.active ? 'rotate(180deg)' : 'none')};
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SubQuantityWrapper = styled.div`
  max-width: 9rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: 2rem;
  height: 4rem;
`;
