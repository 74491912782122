import React, {PureComponent} from 'react';

import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {Tabs, Tab} from '../../styles/pages/modal';
import {
  SwitchAccountWrapper,
  CloseButton,
  PanelWrapper,
  LoadingWrapper,
  Error,
  SearchAccount,
  AccountList,
  NoResults,
} from './SwitchAccount.style';
import Account from './Account';
import Loading from '../Loading/Loading';

import {persistDataToLocalStorage} from '../../helpers/localStorage.helper';
import {JJ_LOCAL_STORAGE} from '../../constants/constants';
import {getPartAccountsDetails} from '../../helpers/data.helper';
import jwt_decode from 'jwt-decode';

class SwitchAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.intialLoaded = true;
    this.focusTimeout = null;
    this.searchAccRef = null;
    this.isSelectingSearchAccount = false;
    this.state = {
      loading: false,
      showSearch: props.isStaffAccount,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {loading} = props;
    if (loading.accounts && !state.loading) {
      return {
        loading: true,
      };
    }
    if (!loading.accounts && state.loading) {
      return {
        loading: false,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    const {
      auth,
      isStaffAccount,
      profile,
      selectAccount,
      resetAccounts,
    } = this.props;
    if (auth && auth !== prevProps.auth && isStaffAccount) {
      resetAccounts();
      this.setState({showSearch: true});
    }
    // enhance the token after selecting a account from search account results
    if (
      profile &&
      profile !== prevProps.profile &&
      this.state.showSearch &&
      !this.isSelectingSearchAccount
    ) {
      const isSwitchAccount = true;
      this.isSelectingSearchAccount = true;
      resetAccounts();
      selectAccount(profile, isSwitchAccount);
    }
  }
  componentDidMount() {
    if (this.state.showSearch) {
      this.isSelectingSearchAccount = false;
      this.props.resetAccounts();
      this.focusTimeout = setTimeout(() => {
        if (this.searchAccRef) {
          this.searchAccRef.focus();
        }
      }, 10);
    }
  }
  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  selectAccount = account => {
    let email = 'default';
    this.setState({loading: true});
    const {
      auth,
      loginToken,
      selectAccount,
      removeBasket,
      setSelectedAccount,
    } = this.props;
    if (!auth && loginToken) {
      const loginObj = jwt_decode(loginToken);
      email = loginObj.email;
    } else if (!!auth) {
      email = auth.email;
    }
    const isSwitchAccount = !!(auth && auth.enhanced);
    this.isSelectingSearchAccount = false;
    if (this.state.showSearch) {
      this.setState({loading: true});
      setSelectedAccount(account);
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.ACCOUNTS, {
        [email]: getPartAccountsDetails([account]),
      });
      selectAccount(account, isSwitchAccount);
    } else {
      removeBasket();
      selectAccount(account, isSwitchAccount);
    }
  };
  searchAccounts = e => {
    if (e && e.target && e.target.value && e.target.value.length > 3) {
      this.intialLoaded = false;
      this.props.searchAccounts(e.target.value);
    }
  };

  setRef = node => (this.searchAccRef = node);

  render() {
    const {showModal, closeModal, accounts, auth, errors} = this.props;
    const renderLoading = this.state.loading && (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderNoAccounts = !this.state.loading &&
      accounts &&
      accounts.length === 0 &&
      !this.intialLoaded && (
        <NoResults>Nothing found, please try a new search</NoResults>
      );
    const renderAccounts =
      !this.state.loading &&
      accounts &&
      accounts.length > 0 &&
      accounts.map((account, index) => (
        <Account
          account={account}
          selectAccount={this.selectAccount}
          key={(account.number || account.id) + index}
        />
      ));
    let renderTitle =
      auth && auth.enhanced ? 'Switch account' : 'Please select an account';
    if (this.state.showSearch) {
      renderTitle = 'Find a user account';
    }
    const renderSearch = this.state.showSearch && (
      <SearchAccount
        ref={this.setRef}
        placeholder="Search by name, postcode or C account..."
        onChange={this.searchAccounts}
      />
    );
    const renderError = errors && errors.getAccountFailed && (
      <Error>Failed to get user account details, please try again!</Error>
    );
    const renderSearchAccountError = errors && errors.search_account && (
      <Error>Failed to search account, please try again!</Error>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        callback={closeModal}
        isOpen={showModal}
        closeModal={closeModal}
      >
        <SwitchAccountWrapper>
          <Tabs>
            <Tab isActive={true}>{renderTitle}</Tab>
            <CloseButton onClick={closeModal} />
          </Tabs>
          <PanelWrapper>
            {renderSearch}
            {renderError}
            {renderSearchAccountError}
            <AccountList>
              {renderLoading}
              {renderAccounts}
              {renderNoAccounts}
            </AccountList>
          </PanelWrapper>
        </SwitchAccountWrapper>
      </ModalWrapper>
    );
  }
}

export default SwitchAccount;
