import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Wrapper} from '../../styles/pages/modal';
import {FormInput} from '../../styles/components/input';
import {TextWrapper} from './PopupModal.style';

export const StyledWrapper = styled(Wrapper)`
  background-color: ${p =>
    p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
  width: 60rem;
`;

export const Content = styled.div`
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  align-items: center;
  display: flex;
  height: 100%;
`;

export const DetailsInput = styled(FormInput)`
  margin: 0.4rem 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const CountryCode = styled.div`
  max-width: 12rem;
  position: relative;
  margin-right: 1rem;
  &:before {
    content: '+';
    pointer-events: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    padding-left: 2rem;
  }
`;

export const DetailsWrapper = styled(TextWrapper)`
  ${media.smaller`
    min-height: 100%;
    padding: 2rem;
  `};
`;

export const Error = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.3rem;
  line-height: 1.4rem;
  padding: 1rem;
  background-color: #edd;
  margin-bottom: 0.2rem;
`;

export const ErrorsField = styled.div`
  margin-top: 0.5rem;
`;
