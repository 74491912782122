import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ItemContainer,
  ItemWrapper,
  NotAvailable,
  Name,
  AvailableAt,
} from './Substitutes.style';
import {
  AVAILABLE_AT_DATE_FORMAT,
  AVAILABLE_AT_DATE_FROM_FORMAT,
  IMAGE_UNAVAILABLE_SIZE,
  STOCK_CHECK,
} from '../../constants/constants';
import {ItemId} from '../Basket/BasketItem.style';
import ImageComponent from '../Image/Image';
import {reduceImageSize} from '../../helpers/image.helper';
import {wrapLongDescription} from '../../helpers/string.helper';
import moment from 'moment';

export default class OriginItem extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    currency: PropTypes.string,
    onItemClick: PropTypes.func.isRequired,
    unavailable: PropTypes.bool,
    hideAlternatives: PropTypes.bool,
    availableAt: PropTypes.array,
  };
  onSubItemClick = () => {
    const {onItemClick, item} = this.props;
    if (item && item.itemId) {
      onItemClick(item.itemId);
    }
  };

  render() {
    const {
      item,
      currency,
      unavailable,
      hasSubItems,
      hideAlternatives,
      availableAt,
    } = this.props;
    const renderNotAvailableMessage =
      item &&
      item.stockCheck &&
      (item.stockCheck.result === STOCK_CHECK.DISCONTINUED ||
        item.stockCheck.result === STOCK_CHECK.NOT_ALLOWED)
        ? item.stockCheck.result === STOCK_CHECK.DISCONTINUED
          ? 'Discontinued '
          : 'Not allowed'
        : availableAt
        ? 'Available from '
        : 'Not available';
    const renderPrice =
      !unavailable && item.price > 0 && `${currency}${item.price}`;
    const renderAvailableAtMessage = availableAt && (
      <AvailableAt>
        {moment(availableAt, AVAILABLE_AT_DATE_FROM_FORMAT).format(
          AVAILABLE_AT_DATE_FORMAT
        )}
      </AvailableAt>
    );
    const renderMessage = unavailable && (
      <NotAvailable>
        {renderNotAvailableMessage}
        {renderAvailableAtMessage}
      </NotAvailable>
    );
    const imageUrl =
      item &&
      item.product &&
      item.product.imageLinks &&
      item.product.imageLinks[0];
    return (
      <ItemContainer>
        <ItemWrapper
          onClick={this.onSubItemClick}
          unavailable={unavailable}
          hasSubItems={hasSubItems}
          hideAlternatives={hideAlternatives}
        >
          <Image>
            <ImageComponent
              url={reduceImageSize(imageUrl)}
              type={IMAGE_UNAVAILABLE_SIZE.S}
              alt={item && item.product && item.product.itemName}
            />
          </Image>
          <div>
            <ItemId>{item && item.product && item.product.itemId}</ItemId>
            <Name unavailable={unavailable}>
              {item.product &&
                item.product.itemName &&
                wrapLongDescription(item.product.itemName)}
            </Name>
            {renderPrice}
            {renderMessage}
          </div>
        </ItemWrapper>
      </ItemContainer>
    );
  }
}
