import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Content} from '../../styles/pages/pages';
import {LinkStyle} from '../../styles/components/links';

export const ReportContent = styled(Content)`
  line-height: 2rem;
  border-width: 1rem;
  margin-top: 0;
  height: 50rem;
  ${media.medium`
    height: 45rem;
  `};
`;

export const Selector = styled.select`
  ${LinkStyle};
  min-width: 20rem;
  width: ${p => (p.maxWidth ? '100%' : 'auto')};
`;

export const Option = styled.option`
  padding: 0 2rem;
`;

export const AccountText = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding-right: 1rem;
  color: ${p => p.theme.textColor};
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${p => p.theme.textColor};
  border: ${p => `solid 1rem ${p.theme.secondaryBgColor}`};
  padding: 2rem;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const Block = styled.div`
  width: 50%;
  padding-bottom: 2rem;
  ${media.smaller`
    width: 100%;
  `};
`;

export const CheckboxBlock = styled.div`
  display: flex;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  line-height: 3rem;
  padding-right: 2rem;
`;

export const Label = styled.div`
  text-transform: uppercase;
  min-height: 3rem;
  font-size: 1.4rem;
`;
