import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const PriceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const Text = styled.span`
  font-size: 1.2rem;
  color: ${p => p.theme.textColor};
  padding: 1rem 0 0;
  max-width: 75%;
  text-align: ${p => (p.left ? 'left' : 'center')};
  ${media.smaller`
    text-align: left;
    max-width: 100%;
    padding-top: 0;
  `};
`;
export const EstimateText = styled(Text)`
  margin-top: 0.5rem;
  ${media.smaller`
    font-size: 1rem;
    text-align: center;
    padding-top: 0.5rem;
  `};
`;

export const InfoText = styled(Text)`
  color: ${p => p.theme.tertiaryColor};
  margin-top: 1rem;
  ${media.smaller`
    font-size: 1rem;
    text-align: center;
    padding-top: 1rem;
  `};
`;

export const BoldText = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
`;
export const Note = styled.span`
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryColor};
  display: ${p => (!p.showOnMobile ? 'block' : 'none')};
  text-align: right;
  ${media.smaller`
    display: ${p => (p.showOnMobile ? 'block' : 'none')};
    padding: 1rem 0 0;
    text-align: center;
  `};
`;

export const PriceField = styled.div`
  position: relative;
  ${media.smaller`
    text-align: center;
  `};
`;

export const VatField = styled.div`
  position: absolute;
  top: 2rem;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: ${p => p.theme.disabledColor};
  ${media.smaller`
    top: 1.5rem;
  `};
`;

export const LoadingWrapper = styled.div`
  margin-top: 0.5rem;
  width: 75%;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  ${media.smaller`
    width: 100%;
  `};
`;

export const TextTitle = styled(Text)`
  color: ${p => p.theme.secondaryColor};
  text-align: ${p => (p.left ? 'left' : 'center')};
  font-weight: bold;
  ${media.smaller`
    min-width: 40%;
    text-align: ${p => (p.left ? 'left' : 'center')};
  `};
`;

export const Column = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const Button = styled.div`
  font-size: 1.2rem;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  font-weight: bold;
  text-transform: uppercase;
  color: ${p =>
    p.disabled ? p.theme.tertiaryTextColor : p.theme.primaryColor};
  text-decoration: underline;
  padding: 1rem 0;
  text-align: right;
  ${media.smaller`
    padding-top: 0;
    font-size: 1.2rem;
  `};
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.smaller`
    margin-top: 1rem;
  `};
`;

export const Voucher = styled.div`
  font-size: 1.2rem;
  color: ${p => p.theme.textColor};
  padding: 1rem 0;
  text-align: right;
`;
