import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  ${media.smaller`
    flex-direction: column;
    justify-conent: flex-start;
  `};
`;

export const Column = styled.nav`
  width: 25%;
  max-width: 25%;
  overflow: hidden;
  padding: 2rem;
  border-right: ${p =>
    p.last ? 'none' : `0.1rem solid ${p.theme.borderColor}`};
  ${media.smaller`
    width: 100%;
    max-width: 100%;
    border-right: 0;
    padding: 0;
    display: ${p => (p.active ? 'block' : 'none')};
  `};
`;

export const CategoryStyled = styled.a`
  display: flex;
  justify-content: space-between;
  color: ${p =>
    p.isSelected ? p.theme.secondaryColor : p.theme.tertiaryTextColor};
  font-size: 1.2rem;
  line-height: 2rem;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${p => p.theme.primaryColor};
  }
  &::after {
    display: ${p => (p.hasSubCategory ? 'block' : 'none')};
    font-family: 'icomoon';
    content: '\\e90b';
    font-size: 2rem;
    transform: rotate(90deg);
    color: ${p =>
      p.isSelected ? p.theme.secondaryColor : p.theme.tertiaryTextColor};
  }
  ${media.smaller`
    width: 100%;
    line-height: 4rem;
    padding: 0 1rem 0 2rem;
    color: ${p =>
      p.isSelected ? p.theme.primaryTextColor : p.theme.textColor};
    background-color: ${p =>
      p.isSelected ? p.theme.secondaryColor : p.theme.tertiaryBgColor};
    border-bottom: ${p => `0.1rem solid ${p.theme.borderColor}`};
    &:hover {
      color: ${p =>
        p.isSelected ? p.theme.primaryTextColor : p.theme.textColor};;
    }
    &::after {
      font-size: 3rem;
      color: ${p =>
        p.isSelected ? p.theme.primaryTextColor : p.theme.tertiaryTextColor};
    }
  `};
`;

export const AdvertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Advert = styled.img`
  width: 18.4rem;
  height: 19rem;
`;
