import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {bindActionCreators} from 'redux';

import ImageComponent from '../../components/Image/Image';
import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getHelp} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class HelpPage extends PureComponent {
  componentDidMount() {
    this.props.getHelp();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (cms && cms.help && (prevProps.cms && prevProps.cms.help) !== cms.help) {
      setCmsHeadTags(cms.help);
      const hash = window !== undefined && location.hash.slice(1);
      if (hash) {
        document.getElementById(hash).scrollIntoView();
      }
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const help = cms && cms.help;
    const renderMarkdown = help && help.content && (
      <ReactMarkdown
        children={markdownConvert(help.content)}
        className="columns-1"
      />
    );
    const renderMedia = help && help.media && (
      <ImageComponent url={help.media} alt={help.heading} />
    );
    const renderLoading = loading && loading.help && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.help && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{help && help.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}

const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getHelp: bindActionCreators(getHelp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage);
