import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Contact,
  Error,
  ErrorWrapper,
  Link,
  Title,
} from '../../client/LoginSuccessPage/LoginSuccessPage.style';
import {Tel} from '../LoginRegister/LoginRegister.style';
import {
  ANALYTICS_EVENTS,
  JJ_DOMAIN,
  JJ_TEL_FULL,
} from '../../constants/constants';
import {getLoginError} from '../../helpers/string.helper';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';

const LoginError = props => {
  const {error} = props;

  useEffect(() => {
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.LOGIN_ERROR, {error});
  }, []);

  const gotoHome = () => {
    const hostname =
      window.location.hostname === JJ_DOMAIN.PROD
        ? JJ_DOMAIN.PROD
        : JJ_DOMAIN.DEV;
    window.location.href = `https://${hostname}`;
  };
  const loginError = getLoginError(error);

  return (
    <ErrorWrapper>
      <Title>Process failed</Title>
      <Error>{loginError}</Error>
      <Contact>
        Please try again. If the problem persists, please Call the{' '}
        <Tel href={`tel:${JJ_TEL_FULL}`} nomargin={'true'}>
          Call Customer Support
        </Tel>
      </Contact>
      <Link onClick={gotoHome} nomargin={'true'}>
        Return to JJ Foodservice website
      </Link>
    </ErrorWrapper>
  );
};

LoginError.propTypes = {
  error: PropTypes.object.isRequired,
};

export default LoginError;
