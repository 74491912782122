import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const ExtraWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  ${media.medium`
    padding: 2rem;
  `};
  ${media.small`
    justify-content: ${p => (p.alignRight ? 'flex-end' : 'space-around')};
  `};
`;
