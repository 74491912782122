import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {bindActionCreators} from 'redux';

import ImageComponent from '../../components/Image/Image';
import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getDeliveries} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class DeliveriesPage extends PureComponent {
  componentDidMount() {
    const {getDeliveries} = this.props;
    getDeliveries();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.deliveries &&
      (prevProps.cms && prevProps.cms.deliveries) !== cms.deliveries
    ) {
      setCmsHeadTags(cms.deliveries);
    }
  }

  render() {
    const {cms, loading, errors, deviceInfo} = this.props;
    const isMobile = deviceInfo && (deviceInfo.isPhone || deviceInfo.isTablet);
    const deliveries = cms && cms.deliveries;
    const renderMarkdown = deliveries && deliveries.content && (
      <ReactMarkdown
        children={markdownConvert(deliveries.content)}
        className="columns-1"
      />
    );
    const renderMedia = deliveries && deliveries.media && (
      <ImageComponent url={deliveries.media} alt={deliveries.heading} />
    );
    const renderLoading = loading && loading.deliveries && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.deliveries && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{deliveries && deliveries.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}
const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getDeliveries: bindActionCreators(getDeliveries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveriesPage);
