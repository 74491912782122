import initialState from './initial.state';
import * as types from '../actions/types.action';

export default function basketReducer(state = initialState.basket, action) {
  switch (action.type) {
    case types.GET_BASKET_SUCCEEDED:
    case types.UPDATE_BASKET_SUCCEEDED:
    case types.UPDATE_BASKET_ITEM_SUCCEEDED:
    case types.REMOVE_BASKET_ITEM_SUCCEEDED:
    case types.SET_FULFILLMENT_SUCCESS:
    case types.SET_FULFILL_SLOT_SUCCESS:
    case types.DELETE_FULFILL_SLOT_SUCCESS:
    case types.SET_SUBSTITUTE_ITEM_IN_BASKET_SUCCEEDED:
      return action.basket;
    case types.SET_PAYMENT_TYPE_SUCCESS:
      return Object.assign({}, state, {
        paymentMethod: action.basket.paymentMethod,
        synced: action.basket.synced,
        syncStatus: action.basket.syncStatus,
        commercialCard: action.basket.commercialCard,
      });
    case types.CHECK_BASKET_SYNC_SUCCEEDED:
      return action.basket;
    case types.REMOVE_BASKET_SUCCEEDED:
    case types.RESET_SETTING_SUCCESS:
    case types.REMOVE_AUTH_SUCCESS: //logout clean up
    case types.REMOVE_AX_BASKET_SUCCEEDED:
      return null;
    case types.REMOVE_VOUCHER_SUCCEEDED:
    case types.ADD_VOUCHER_SUCCEEDED:
      return action.voucher;
    default:
      return state;
  }
}
