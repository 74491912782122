import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3b} from '../../styles/components/buttons';

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  min-height: 8rem;
`;

export const Title = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem;
  color: ${p => p.theme.primaryColor};
`;

export const Text = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 0.5rem;
  font-weight: ${p => (p.bold ? 'bold' : 'normal')};
`;

export const MarkText = styled(Text)`
  font-size: 1.4rem;
  color: ${p => p.theme.primaryColor};
`;

export const SmallPrint = styled.div`
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const SmallButton = styled(Button3b)`
  margin: 1rem;
  width: 10rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  color: ${p => p.theme.primaryColor};
  border-color: ${p => p.theme.primaryColor};
  &:hover {
    background-color: ${p => p.theme.primaryColor};
    border-color: ${p => p.theme.primaryColor};
  }
  ${media.smaller` 
     padding: 0 1rem;
  `};
`;
