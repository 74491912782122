import styled from 'styled-components';
import {Content} from '../../styles/pages/pages';
import {media} from '../../styles/components/media-queries';
import {Button3a} from '../../styles/components/buttons';

export const PageContent = styled(Content)`
  width: 100%;
  min-height: 10rem;
`;

export const SubHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem 0 0;
  ${media.medium`
    padding: 0 2rem 2rem;
  `};
  ${media.small`
    flex-direction: column;
  `};
`;

export const SubTitle = styled.span`
  color: ${p => p.theme.textColor};
  text-transform: capitalize;
  font-size: 1.4rem;
  padding-left: 3rem;
`;

export const EmptyMessage = styled.span`
  color: ${p => p.theme.textColor};
  font-size: 1.4rem;
  padding: 3rem;
  width: 100%;
  text-align: center;
`;

export const List = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  line-height: 3rem;
  cursor: pointer;
  border-bottom: ${p => `solid 0.1rem ${p.theme.secondaryBgColor}`};
  &:hover {
    background-color: ${p => p.theme.secondaryBgColorHover};
  }
`;

export const TopButton = styled(Button3a)`
  color: ${p => p.theme.secondaryColor};
  border: ${p => `0.2rem solid ${p.theme.secondaryColor}`};
  background-color: ${p =>
    p.isLoading ? p.theme.secondaryColor : 'transparent'};
  &:hover {
    color: ${p => p.theme.primaryTextColor};
    background-color: ${p => p.theme.secondaryColor};
    border-color: ${p => p.theme.secondaryColor};
  }
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
  margin-left: 1rem;
  ${media.small`
    line-height: 3.5rem;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 1.5rem;
    overflow: hidden;
    text-align: center;
  `};
`;

export const Back = styled.span`
  cursor: pointer;
  font-size: 1.2rem;
  color: ${props => props.theme.secondaryColor};
  display: flex;
  align-items: flex-end;
`;

export const AddAll = styled.div`
  width: 14rem;
`;

export const LoadingWrapper = styled.div`
  margin-top: -0.2rem;
`;
