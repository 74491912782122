import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Button3Inverted} from '../../styles/components/buttons';
import {Label, Radio} from '../../styles/components/checkbox';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6rem 0 2rem;
  ${media.smaller`
    margin: 0;
  `};
`;

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.medium`
    padding: 0 2rem;
  `};
  ${media.smaller`
    flex-direction: column;
    padding: 2rem;
  `};
`;

export const TheButton = styled(Button3Inverted)`
  width: 19rem;
  ${media.smaller`
    width: 15rem;
  `};
`;

export const Row = styled.div`
  width: 100%;
  padding: 0 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  min-height: 4.8rem;
  font-size: 1.6rem;
`;

export const RadioText = styled.span`
  color: ${p => (p.isChecked ? p.theme.secondaryColor : p.theme.textColor)};
`;

export const Title = styled.span`
  color: ${p => p.theme.secondaryColor};
  text-align: ${p => (p.left ? 'left' : 'center')};
  font-weight: bold;
  ${media.smaller`
    min-width: 40%;
  `};
`;

export const Container = styled.div`
  width: 60%;
  padding: 2rem;
  color: ${p => p.theme.secondaryColor};
  ${media.medium`
    min-width: 80%;
  `};
  ${media.small`
    min-width: 100%;
  `};
`;

export const RadioWrapper = styled.div`
  display: flex;
  line-height: 2rem;
`;

export const RadioLabel = styled(Label)`
  margin-left: 1rem;
`;

export const CheckMark = styled(Radio)`
  top: 0;
  left: 0;
  margin-right: 1rem;
  &:before {
    left: 0;
    top: 0.3rem;
    background: ${p => p.theme.secondaryColor};
  }
`;

export const Comments = styled.textarea`
  width: 100%;
  line-height: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 1.4rem;
  outline: none;
  color: ${p => p.theme.textColor};
  border-color: ${props => props.theme.secondaryColor};
`;
