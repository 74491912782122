import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Item,
  ItemImage,
  ItemWrapper,
  RadioWrapper,
  SubRadioWrapper,
  SubPaymentOptionsWrapper,
  BankDetail,
  Question,
  FeeApplied,
  ExtraText,
} from './PaymentTypeItem.style';
import {PAYMENT_TYPES} from '../../constants/constants';
import pbbImage from '../../images/payment/pbb.png';
import cardImage from '../../images/payment/card.png';
import cashImage from '../../images/payment/cash.png';
import commercialCardImage from '../../images/payment/commercialCard.png';
import {BigRadio} from '../../styles/components/checkbox';

export class PaymentTypeItem extends Component {
  click = () =>
    this.props.callback(PAYMENT_TYPES[this.props.paymentOption].mode);
  setDefaultBankFnc = isDefault => {
    const {isSelected, setUsePbbDefaultBank} = this.props;
    setUsePbbDefaultBank(isDefault);
    if (!isSelected) {
      this.click();
    }
  };
  useDefaultBank = () => this.setDefaultBankFnc(true);
  useOtherBanks = () => this.setDefaultBankFnc(false);

  navToPbbFaq = e => {
    e.preventDefault();
    e.stopPropagation();
    window && window.open('/paybybankfaq', '_blank');
  };
  navToCommercialCardFaq = e => {
    e.preventDefault();
    e.stopPropagation();
    window && window.open('/help#payments', '_blank');
  };

  render() {
    const {
      paymentOption,
      isBottom,
      isSelected,
      pbbBankDetails,
      isUseDefaultPbb,
      isEditMode,
      commercialCardCharge,
      isAdyenPayment,
    } = this.props;
    if (!PAYMENT_TYPES[paymentOption]) {
      return null;
    }
    const isPbbMode = paymentOption === PAYMENT_TYPES.PBB.mode;
    const isCdcMode = paymentOption === PAYMENT_TYPES.CDC.mode;
    const isBcdcMode = paymentOption === PAYMENT_TYPES.BCDC.mode;
    const renderPaymentCardIcon = isCdcMode && <ItemImage src={cardImage} />;
    const renderPaymentPBBIcon = isPbbMode && <ItemImage src={pbbImage} />;
    const renderPaymentBcdcIcon = isBcdcMode && (
      <ItemImage src={commercialCardImage} />
    );
    const renderPaymentCashIcon = paymentOption === PAYMENT_TYPES.CAS.mode && (
      <ItemImage src={cashImage} />
    );
    const renderCardExtra = isCdcMode && isAdyenPayment && (
      <ExtraText>(Consumer / Business)</ExtraText>
    );
    const renderPbbHelp = isPbbMode && (
      <Question
        href="/paybybankfaq"
        onClick={this.navToPbbFaq}
        alt="Pay By Bank FAQ"
      />
    );
    const renderFeeText = !!commercialCardCharge
      ? `£${commercialCardCharge}+VAT fee`
      : 'Fee Applies';
    const renderBcdcInfo = isBcdcMode && (
      <BankDetail>
        <FeeApplied> ({renderFeeText})</FeeApplied>
        <Question
          href="/help#payments"
          onClick={this.navToCommercialCardFaq}
          alt="Business Card FAQ"
        />
      </BankDetail>
    );
    const renderPbbBankDetails = isPbbMode && !!pbbBankDetails && (
      <>
        <SubPaymentOptionsWrapper onClick={this.useDefaultBank}>
          <SubRadioWrapper>
            <BigRadio isChecked={isSelected && isUseDefaultPbb} />
          </SubRadioWrapper>
          <BankDetail isChecked={isSelected && isUseDefaultPbb}>
            {pbbBankDetails.name}
          </BankDetail>
          <ItemImage src={pbbBankDetails.icon} />
        </SubPaymentOptionsWrapper>
        <SubPaymentOptionsWrapper onClick={this.useOtherBanks}>
          <SubRadioWrapper>
            <BigRadio isChecked={isSelected && !isUseDefaultPbb} />
          </SubRadioWrapper>
          <BankDetail isChecked={isSelected && !isUseDefaultPbb}>
            Use a different bank
          </BankDetail>
        </SubPaymentOptionsWrapper>
      </>
    );
    return (
      <>
        <ItemWrapper
          data-rw="paymentType-wrapper"
          isBottom={isBottom}
          onClick={this.click}
        >
          <RadioWrapper>
            <BigRadio isChecked={isSelected} />
          </RadioWrapper>
          <Item isSelected={isSelected} isDisabled={isEditMode && isSelected}>
            {PAYMENT_TYPES[paymentOption].title.toLowerCase()} {renderPbbHelp}
            {renderCardExtra}
            {renderBcdcInfo}
          </Item>
          {renderPaymentCardIcon}
          {renderPaymentPBBIcon}
          {renderPaymentBcdcIcon}
          {renderPaymentCashIcon}
        </ItemWrapper>
        {renderPbbBankDetails}
      </>
    );
  }
}

PaymentTypeItem.propTypes = {
  paymentOption: PropTypes.string.isRequired,
};

export default PaymentTypeItem;
