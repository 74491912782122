import React, {PureComponent} from 'react';

import {defaultStyles} from '../../components/Modal/Modal.config';
import ModalWrapper from '../../components/Modal/Modal';
import {Tabs, Close} from '../../styles/pages/modal';
import {
  FormContainer,
  FormWrapper,
  Button,
  ItemsWrapper,
  ButtonsWrapper,
  StyledModalWrapper,
} from '../BasketPage/EditItems.style';
import {OutOfStockGroup} from '../../components/Basket/BasketItem.style';
import Substitutes from '../../components/Substitutes/Substitutes';
import Loading from '../../components/Loading/Loading';
import {AcceptAllWrapper, TheTab} from './SubstituesModal.style';
import {AcceptButton} from '../../components/Substitutes/Substitutes.style';
import {getOosItemsSize} from '../../helpers/basket.helper';

export default class SubstitutesModal extends PureComponent {
  state = {
    oosItems: {},
  };
  componentDidMount() {
    const {getBasket} = this.props;
    const withDetails = true;
    getBasket(withDetails);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {loading} = this.props;
    if (
      loading &&
      prevProps.loading &&
      prevProps.loading.loadingSubstitutes &&
      loading.loadingSubstitutes === false
    ) {
      this.closeModalGoToCheckout();
    }
  }

  closeModalGoToCheckout = () => {
    const {toggleModal, goToCheckout} = this.props;
    toggleModal();
    goToCheckout();
  };

  goToCheckout = () => {
    const {setSubstituteItem} = this.props;
    const subItems = Object.keys(this.state.oosItems)
      .map(key => {
        return {uuid: key, ...this.state.oosItems[key]};
      })
      .filter(item => item && item.itemId);
    if (subItems.length > 0) {
      setSubstituteItem(subItems);
    } else {
      this.closeModalGoToCheckout();
    }
  };

  updateOosItems = (uuid, quantity, itemId) => {
    this.setState({
      oosItems: {
        ...this.state.oosItems,
        [uuid]: {
          quantity,
          itemId,
        },
      },
    });
  };
  removeOosItem = uuid => {
    const newState = Object.assign({}, this.state.oosItems);
    delete newState[uuid];
    this.setState({oosItems: newState});
  };

  onBasketItemClick = itemId => {
    const {toggleModal, onBasketItemClick} = this.props;
    toggleModal();
    onBasketItemClick(itemId);
  };

  render() {
    const {
      showModal,
      toggleModal,
      goToCheckout,
      updateBasket,
      loading,
      updateItem,
      removeItem,
      basketHashMap,
      substitutes,
      setSubstituteItem,
      currency,
      onBasketItemClick,
      basketItems,
      acceptAll,
      fulfillmentType,
    } = this.props;

    // TODO when BE ready for bulk substitutions
    const renderAcceptAll = (
      <AcceptAllWrapper>
        <AcceptButton right onClick={acceptAll}>
          Accept all
        </AcceptButton>
      </AcceptAllWrapper>
    );

    const oosItemsSize = basketItems && getOosItemsSize(basketItems.outOfStock);
    const renderOutOfStock = (oosItemsSize && (
      <OutOfStockGroup>
        {basketItems.outOfStock.map(item => {
          const pairs = [
            item,
            (substitutes && item && item.itemId && substitutes[item.itemId]) ||
              null,
          ];
          return (
            <Substitutes
              key={item.uuid}
              currency={currency}
              pairs={pairs}
              basketHashMap={basketHashMap}
              updateBasket={updateBasket}
              removeItem={removeItem}
              updateItem={updateItem}
              onItemClick={this.onBasketItemClick}
              loading={loading}
              setSubstituteItem={setSubstituteItem}
              showQtyBeforeAccept={true}
              updateOosItems={this.updateOosItems}
              removeOosItem={this.removeOosItem}
              showRemoveItemButton={false}
            />
          );
        })}

        {/*{renderAcceptAll} TODO when BE ready */}
      </OutOfStockGroup>
    )) || <Loading isLight={true} />;

    return (
      <ModalWrapper
        customStyles={defaultStyles}
        callback={goToCheckout}
        isOpen={showModal}
      >
        <StyledModalWrapper>
          <Tabs>
            <TheTab>
              Sorry, these items are not available on your {fulfillmentType}{' '}
              day.
            </TheTab>
          </Tabs>
          <FormContainer>
            <FormWrapper>
              <ItemsWrapper>{renderOutOfStock}</ItemsWrapper>
            </FormWrapper>
            <ButtonsWrapper>
              <Button left onClick={toggleModal}>
                Cancel
              </Button>
              <Button right onClick={this.goToCheckout}>
                Checkout
              </Button>
            </ButtonsWrapper>
          </FormContainer>
        </StyledModalWrapper>
      </ModalWrapper>
    );
  }
}
