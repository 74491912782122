import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {
  ALIGNMENT_FLEX,
  COLOR,
  DIVIDER,
  HEADING_LEVEL,
  HEADING_LEVEL_HEIGHT,
  WIX_SIZE,
} from '../../constants/wix.constants';

export const BlogText = styled.span`
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1rem 0;
  color: ${p => p.theme.textColor};
  ${({style}) => css(style)}
`;

export const ParagraphWrapper = styled.div`
  min-width: ${p => (p.isHeading ? '100%' : 'auto')};
  margin: 0.5rem 0;
  line-height: 2.7rem;
  ${({style}) => css(style)}
`;

export const BlogLink = styled.a`
  display: inline;
  padding: 0 0.2rem;
  margin: 1rem 0;
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;
  color: ${p => p.theme.primaryColor};
  ${({style}) => css(style)}
`;

export const ImageLink = styled.a`
  cursor: pointer;
`;

export const Caption = styled.div`
  text-align: center;
  width: 100%;
  line-height: 6rem;
  color: ${p => p.theme.textColor};
  height: 6rem;
  font-size: 1.4rem;
  ${media.small`
    line-height: 4rem;
    height: 4rem;
    font-size: 1.2rem;
  `};
`;
export const BlogHeading = styled.span`
  font-size: ${p => HEADING_LEVEL[p.level]};
  line-height: ${p => HEADING_LEVEL_HEIGHT[p.level]};
  font-weight: lighter;
  color: ${p => p.theme.secondaryColor};
  ${({style}) => css(style)}
  ${media.small`
    line-height: 3rem;
  `};
`;
export const BlogImageWrapper = styled.div`
  margin-bottom: 1rem;
  min-width: 100%;
  display: ${p => (p.isfloated ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: ${p => (p.isCenter ? 'center' : 'flex-start')};
  align-items: ${p => (p.isCenter ? 'center' : 'flex-start')};
`;
export const BlogImageBlock = styled.img`
  width: ${p => p.customWidth || p.maxWidth || '100%'};
  height: ${p => (p.maxHeight ? p.maxHeight : '100%')};
  max-height: ${p => (p.height ? p.height : '100%')};
  display: block;
  max-width: ${p =>
    p.floated ? '50%' : p.width && p.width > 910 ? '91rem' : p.width};
  padding: ${p =>
    p.floated ? (p.floated === 'left' ? '2rem 3rem 0 0' : '0 0 0 3rem') : 0};
  float: ${p => p.floated || 'none'};
  aspect-ratio: ${p => p.aspectRatio || 'auto'};
  ${media.smaller`
    padding: ${p =>
      p.floated
        ? p.floated === 'left'
          ? '2rem 3rem 2rem 0'
          : '0 0 0 3rem'
        : 0};
    max-width: ${p => (p.floated ? '100%' : p.width)};
  `};
`;

export const ShopBySectorImage = styled.div`
  width: 100%;
  height: 15rem;
  display: block;
  background-size: cover;
  cursor: pointer;
  aspect-ratio: ${p => p.aspectRatio || 'auto'};
  background-image: ${p => (!!p.imageUrl ? `url(${p.imageUrl})` : 'none')};
  ${media.smaller`
    height: 20rem;
  `};
`;

export const Li = styled.li`
  margin-bottom: 0.8rem;
`;

export const Ol = styled.ol`
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  color: ${p => p.theme.textColor};
`;
export const BlogGalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > div {
    min-width: ${p =>
      p.wrapperWidth && WIX_SIZE[p.wrapperWidth]
        ? WIX_SIZE[p.wrapperWidth]
        : '100%'};
  }
`;
export const BlogGalleryWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 3rem 0;
  min-width: 100%;
  ${({style}) => css(style)}
  gap: ${p => (p.spacing ? p.spacing : 0)};
`;

export const BlogVideoIframe = styled.iframe`
  max-width: 100%;
  max-height: ${p => (p.colNum > 0 ? 50 / p.colNum + 'rem' : '50rem')};
  border: 0;
  ${media.small`
    padding: 0;
    max-height: 35rem;
  `};
`;

export const LineBreak = styled.div`
  height: 0.5rem;
  min-height: 0.5rem;
`;

export const HTMLWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  ${media.small`
    ul {
      flex-direction: column;
    }
  `};
`;

export const BlogTableNode = styled.table`
  width: 100%;
  position: relative;
  table-layout: fixed;
  border-spacing: 0;
`;

export const BlogTR = styled.tr`
  width: 100%;
  position: relative;
`;

export const BlogTD = styled.td`
  vertical-align: ${p => p.verticalAlign || 'unset'};
  border-top-width: ${p =>
    p.borderColors && p.borderColors.top === COLOR.WHITE ? 0 : '1px'};
  border-left-width: ${p =>
    p.borderColors && p.borderColors.left === COLOR.WHITE ? 0 : '1px'};
  border-right-width: ${p =>
    p.borderColors && p.borderColors.right === COLOR.WHITE ? 0 : '1px'};
  border-bottom-width: ${p =>
    p.borderColors && p.borderColors.bottom === COLOR.WHITE ? 0 : '1px'};
  min-width: ${p => p.minWidth};
  padding: 1rem 2rem;
  border-left-color: ${p =>
    p.borderColors && p.borderColors.left ? p.borderColors.left : COLOR.WHITE};
  border-right-color: ${p =>
    p.borderColors && p.borderColors.right
      ? p.borderColors.right
      : COLOR.WHITE};
  border-top-color: ${p =>
    p.borderColors && p.borderColors.top ? p.borderColors.top : COLOR.WHITE};
  border-bottom-color: ${p =>
    p.borderColors && p.borderColors.bottom
      ? p.borderColors.bottom
      : COLOR.WHITE};
  background-color: ${p => p.backgroundColor || 'transparent'};
  border-style: solid;
  ${media.small`
    position: relative;
    display: block;
    min-width: inherit;
    width: ${p => (p.colNum === 1 ? '100%' : '340px')};
    padding-right: 0.5rem;
  `};
  ${media.smallest`
    width: 335px;
  `};
`;

export const DivTD = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;

export const BlogTableCol = styled.col`
  width: ${p => p.theWidth};
`;

export const BlogButtonNode = styled.a`
  max-width: max-content;
  font-size: 1.6rem;
  padding: 1.2rem 1.4rem;
  text-decoration: none !important;
  cursor: pointer;
  ${({style}) => css(style)}
`;

export const BlogButtonWrapper = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  display: flex;
  justify-content: ${p => ALIGNMENT_FLEX[p.alignment] || 'flex-start'};
`;

export const BlogHRWrapper = styled.div`
  width: 100%;
  text-align: ${p => p.align || 'center'};
  margin: 3rem 0;
`;
export const BlogHR = styled.hr`
  width: 100%;
  display: flex;
  max-width: ${p => DIVIDER[p.size] || DIVIDER.LARGE};
`;
export const DivTableNode = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  div[data-rw='empty-node'] {
    display: none;
  }
  div {
    text-align: left !important;
  }
  div:first-of-type > span:first-of-type {
    min-height: 3rem;
  }
  div div a {
    display: block;
    max-width: 10rem;
    font-size: 1.4rem !important;
    color: ${p => p.theme.primaryColor} !important;
  }
  ${media.medium`
    padding: 0 2rem;
  `};
  ${media.small`
    grid-template-columns: repeat(2, 1fr);
    div:nth-child(2) {
      min-height: 2.6rem;
    }
  `};
`;

export const DivTR = styled.div`
  width: 100%;
  position: relative;
`;

export const BlogBR = styled.div`
  height: 0.3rem;
  max-height: 0.3rem;
`;
