import React, {Fragment, PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  ErrorButton,
  OK,
  ButtonsWrapper,
  Cancel,
} from './PopupModal.style';
import Loading from '../Loading/Loading';

export default class PopupModal extends PureComponent {
  render() {
    const {
      isError,
      title,
      content,
      subContent,
      showModal,
      callback,
      loading,
      isLoadingModal,
      showOKButton,
      showCancelButton,
      okButtonText,
      cancelButtonText,
      closeModalCallback,
      cancelCallBack,
      modalId,
      wideButtons,
      isNotFullScreen,
      modalName,
      tag,
    } = this.props;
    const modalDataId = modalId || 'modal';
    const okText = okButtonText || 'Ok';
    const renderButtonContent = loading ? (
      <Loading isLight={false} />
    ) : showOKButton ? (
      'OK'
    ) : (
      'Dismiss'
    );
    const renderButton = isError ? (
      <ErrorButton onClick={callback} data-rw="modal--ok-error-button">
        {renderButtonContent}
      </ErrorButton>
    ) : (
      <OK
        onClick={callback}
        data-rw="modal--ok-button"
        wideButton={wideButtons}
      >
        {okText}
      </OK>
    );
    const cancelText = cancelButtonText || 'Cancel';
    const renderCancelButton =
      !!showCancelButton &&
      (isError ? (
        <ErrorButton
          onClick={cancelCallBack}
          data-rw="modal--cancel-button"
          wideButton={wideButtons}
        >
          {cancelText}
        </ErrorButton>
      ) : (
        <Cancel
          onClick={cancelCallBack}
          data-rw="modal--cancel-button"
          wideButton={wideButtons}
        >
          {cancelText}
        </Cancel>
      ));
    const rendering = isLoadingModal ? (
      <Fragment>
        <Loading isLight={false} />
        {renderCancelButton}
      </Fragment>
    ) : (
      <ButtonsWrapper wideButtons={wideButtons}>
        {renderButton}
        {renderCancelButton}
      </ButtonsWrapper>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={closeModalCallback}
        data-rw={modalDataId}
        data-modal-name={modalName}
      >
        <StyledWrapper isError={isError} isNotFullScreen={isNotFullScreen}>
          <Content>
            <TextWrapper>
              <Title isError={isError} data-rw={tag}>
                {title}
              </Title>
              <div>{content}</div>
              <SubContent>{subContent}</SubContent>
              {rendering}
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
