import styled from 'styled-components';

export const Pics = styled.div`
  display: flex;
  max-width: 100%;
  max-height: 24rem;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const Pic = styled.img`
  width: ${p => (p.isSmall ? '8rem' : '24rem')};
  height: ${p => (p.isSmall ? '6rem' : '18rem')};
  margin: 0 1rem;
`;
