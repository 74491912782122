import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Dropdown} from '../../styles/components/dropdown';
import {Button4} from '../../styles/components/buttons';
import storeLocator from '../../images/store_locator.jpg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(${storeLocator});
  background-size: cover;
  width: 100%;
  min-height: 10rem;
  ${media.small`
    background-size: cover;
    flex-direction: column;
    padding: 1rem 2rem;
  `};
`;

export const Content = styled.div`
  margin-left: 2.8rem;
  margin-right: 2.8rem;
  ${media.small`
    display: flex;
    flex-direction: column;
    min-height: 3rem;
  `};
  ${media.smaller`
    margin-left: 2rem;
    margin-right: 2rem;
  `};
`;

export const Title = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  min-width: 17rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.primaryTextColor};

  &::before {
    font-family: 'icomoon';
    content: '\\e90a';
    display: inline-block;
    font-size: 2.4rem;
    padding-right: 1rem;
  }

  ${media.smaller`
    margin-left: 2rem;
    display: block;
    &::before {
      position: absolute;
      left: 1rem;
    }
  `};
`;

export const By = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.primaryTextColor};
  font-weight: bold;
  padding-right: 1rem;
  ${media.smaller`
    display: none;
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-height: 5rem;
  justify-content: flex-end;
  padding-right: 3rem;
  ${media.smaller`
    width: 100%;
    justify-content: space-between;
  `};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-width: 20rem;
  margin-left: 1rem;

  &::after {
    font-family: 'icomoon';
    content: '\\e910';
    position: absolute;
    right: 0;
    display: inline-block;
    font-size: 2.4rem;
    color: ${props => props.theme.primaryTextColor};
    padding-right: 1rem;
    z-index: 1;
  }

  ${media.smaller`
    margin-left: 0;
    min-width: 100%;
    width: 100%;
  `};
`;

export const Filters = styled(Dropdown)`
  width: 100%;
  position: relative;
  z-index: 2;
`;

export const Option = styled.option`
  color: ${props => props.theme.textColor};
`;

export const ViewTypes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0;
  margin: 2rem 0 2rem 2rem;
  ${media.small`
    margin: 1rem;
  `};
`;

export const Grids = styled(Button4)`
  opacity: ${p => (p.active ? 1 : 0.7)};

  &::after {
    content: '\\e904';
    padding-top: 0.2rem;
  }
`;

export const Lists = styled(Button4)`
  opacity: ${p => (p.active ? 1 : 0.7)};

  &::after {
    content: '\\e905';
  }
`;
