import axios from 'axios';
import {getApiConfig} from '../config/configProvider';

export default class RecommendedApi {
  static get(params, fullList) {
    const config = getApiConfig();
    const searchUrl = `${config.apiUrl}/product/frequentlybought`;
    if (!fullList) {
      params.size = 4;
    }
    return axios.get(searchUrl, {params}).then(res => res.data);
  }
}
