import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Wrapper, Column, Advert, AdvertWrapper} from './Categories.style';
import Category from './Category';

export default class Categories extends PureComponent {
  static propTypes = {
    categories: PropTypes.array,
    nextLevel: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    activeLevel: PropTypes.number,
    categoryAd: PropTypes.array,
  };

  state = {
    level0: 0,
    level1: 0,
    level2: 0,
  };

  changeLevel0 = (index, title) => {
    this.props.setTitle(1, title);
    this.setState({
      level0: index,
      level1: 0,
      level2: 0,
    });
  };

  changeLevel1 = (index, title) => {
    this.props.setTitle(2, title);
    this.setState({
      level1: index,
      level2: 0,
    });
  };

  changeLevel2 = index => this.setState({level2: index});

  render() {
    const {
      categories,
      activeLevel,
      nextLevel,
      navigate,
      categoryAd,
    } = this.props;
    const {level0, level1, level2} = this.state;
    const level1Category = categories[level0] && categories[level0].counts;
    const level2Category =
      level1Category && level1Category[level1] && level1Category[level1].counts;
    const renderLevel0 = categories.map((link, index) => (
      <Category
        key={link.id + index}
        isSelected={level0 === index}
        hasSubCategory={link.counts && link.counts.length > 0}
        link={link}
        position={index}
        callback={this.changeLevel0}
        nextLevel={nextLevel}
        navigate={navigate}
      />
    ));
    const renderLevel1 =
      level1Category &&
      level1Category.map((link, index) => (
        <Category
          key={link.id + index}
          isSelected={level1 === index}
          hasSubCategory={link.counts && link.counts.length > 0}
          link={link}
          position={index}
          callback={this.changeLevel1}
          nextLevel={nextLevel}
          navigate={navigate}
        />
      ));
    const renderLevel2 =
      level2Category &&
      level2Category.map((link, index) => (
        <Category
          key={link.id + index}
          isSelected={level2 === index}
          hasSubCategory={link.counts && link.counts.length > 0}
          link={link}
          position={index}
          callback={this.changeLevel2}
          nextLevel={nextLevel}
          navigate={navigate}
        />
      ));
    const renderCategoryAd = categoryAd && categoryAd[0] && (
      <Advert src={categoryAd[0].mediaMobileUrl} alt={categoryAd[0].alt} />
    );
    return (
      <Wrapper>
        <Column active={activeLevel === 0}>{renderLevel0}</Column>
        <Column active={activeLevel === 1}>{renderLevel1}</Column>
        <Column active={activeLevel === 2}>{renderLevel2}</Column>
        <Column last>
          <AdvertWrapper>{renderCategoryAd}</AdvertWrapper>
        </Column>
      </Wrapper>
    );
  }
}
