import vegan from '../images/vegan.png';
import vegetarian from '../images/vegetarian.png';
import halal from '../images/halal.png';
import fresh from '../images/fresh.png';
import video from '../images/video.png';
import chilled from '../images/chilled.png';
import frozen from '../images/frozen.png';
import msc from '../images/msc.png';
import popular from '../images/popular.png';
import {
  BRAINTREE_PATH,
  PAYMENT_CALLBACK_PATH,
  SUCCESS_PATH,
} from './routePaths';
import React from 'react';

export const JJ_DOMAIN = {
  PROD: 'www.jjfoodservice.com',
  DEV: 'olympus-dev.jjfoodservice.com',
};
export const FILTERS = {
  origin: 'Origin',
  sizeOrCut: 'Size',
  brand: 'Brand',
  categoryList: 'Category',
  productFeatures: 'Features',
};

export const FILTERS_NAME = {
  categoryList: 'categoryList',
  productFeatures: 'productFeatures',
};

export const DOTS = '...';

export const DEFAULT_BRANCH = 'EN-MW';

export const SORT_TYPES = {
  SEARCH: 'search',
  CATEGORY: 'category',
  OFFER: 'offer',
};

export const SEARCH_PARAMS = {
  b: DEFAULT_BRANCH,
  size: 12,
  sortType: SORT_TYPES.SEARCH,
};

export const SEARCH_ADVANCED_PARAMS = {
  size: 12,
  q: '*',
  advanced: true,
};

export const OFFER_PARAMS = {
  size: 20,
  sortType: SORT_TYPES.OFFER,
};

export const SEARCH_SORT_TYPE = {
  RELEVANCE: 'relevance',
  PRICE_HIGH: 'pricehigh',
  PRICE_LOW: 'pricelow',
  CATEGORY: 'category',
  CATEGORIES: 'catalogue',
  BRAND: 'brand',
  NAME: 'name',
  NEW: 'newproducts',
  OFFER: 'offer',
};

export const PAGE_SIZE = {
  S: '12',
  M: '48',
  L: '96',
};

export const SERVICE_TYPE = {
  DELIVERY: 'DELIVERY',
  COLLECTION: 'COLLECTION',
  DELIVERED: 'Delivered',
  COLLECTED: 'Collected',
  UNSELECTED: 'UNSELECTED',
};

export const JJ_TEL = '01992 701 701';
export const JJ_MOBILE = '07870 176 015';
export const JJ_TEL_FULL = '+441992701701';
export const JJ_MOBILE_FULL = '+447870176015';
export const JJ_FORM = 'https://go.jjfoodservice.com/customerservice';

export const DEFAULT_DATE_FORMAT = 'D MMMM, YYYY';
export const OFFER_ENDS_DATE_FORMAT = 'DD/MM/YY';
export const OFFER_END_DATE_FULL_FORMAT = 'DD/MM/YYYY';
export const OFFER_ENDS_DATE_FORMAT_SHORT = 'DD/MM';
export const AVAILABLE_AT_DATE_FROM_FORMAT = 'YYYYMMDD';
export const AVAILABLE_AT_DATE_FORMAT = 'DD/MM';
export const ORDER_SERVICE_DATE_FORMAT = 'DD-MM-YYYY';
export const SLOT_DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const ORDER_HISTORY_DATE_FORMAT = 'DD/MM/YY, HH:mm';
export const ORDER_HISTORY_UNTIL_DATE_FORMAT = 'HH:mm on DD/MM';
export const ORDER_HISTORY_OUT_DATE_FORMAT = 'dddd DD MMMM';
export const ORDER_HISTORY_PDF_DATE_FORMAT = 'dddd DD MMMM, HH:mm';
export const H_MM_A_FORMAT = 'h:mma';
export const UTC_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const CUTOFF_TIME_FORMAT = 'HH:mm on Do MMMM';
export const CHECKOUT_BEFORE_TIME_FORMAT = 'HH:mm';
export const FULFILLMENT_SUCCESS_FORMAT = 'Do MMMM';
export const STANDARD_FORMAT = 'YYYY-MM-DD';
export const FULL_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export const GOOGLE_MAP = 'https://maps.googleapis.com/maps/api';
export const GOOGLE_STATIC_URL = 'https://www.google.com/maps/dir';

export const ROUTES = {
  PRODUCT: 'product',
  BASKET: 'basket',
  SEARCH: 'search',
  SEARCH_RESULTS: 'searchResults',
  HOME: 'home',
  CHECKOUT: 'checkout',
  OFFERS: 'offers',
  BOOK_SLOT: 'bookSlot',
  ORDERS: 'orders',
  ORDER: 'order',
  FAVOURITES: 'favourites',
  RECENT_PURCHASES: 'recentPurchases',
  RECOMMENDED: 'recommended',
  SETTINGS: 'settings',
  BRANCH_LOCATOR: 'branchLocator',
  CONFIRMATION: 'confirmation',
  TRACKING: 'Tracking',
  SHOPPING_LISTS: 'ShoppingLists',
  SHOPPING_LISTS_CONTENT: 'ShoppingListsContent',
  OWN_LABELS: 'ownLabels',
  BLOGS: 'blogs',
  JJCONTENT: 'jjcontent',
};

export const JJ_LOCAL_STORAGE = {
  TOKEN: '@JJFoodService.token',
  LOGIN_TOKEN: '@JJFoodService.loginToken',
  REFRESH_TOKEN: '@JJFoodService.refreshToken',
  LAST_AUTH_TIME: '@JJFoodService.lastAuthTime',
  ACCOUNTS: '@JJ.accounts',
  C_ACCOUNT: '@JJ.c_account',
  FAVOURITES: 'JJ.favourites',
  FULFILLMENT: 'JJ.fulfillment',
  TOKEN_VALID_UNTIL: 'JJ.token.valid_until',
  ORDER_ID: 'JJ.orderId',
  ORDER_AX_BASKETID: 'JJ.orderAxBasketId',
  DEVICE_ID: 'JJ.deviceId',
  EDIT_ORDER_TIME: 'JJ.edit_order.time',
  EDIT_ORDER_PAYMENT_METHOD: 'JJ.edit_order.payment_method',
  CATCH_WEIGHT_ITEMS: 'JJ.catch_weight_items',
};

export const JJ_SESSION_STORAGE = {
  VERIFIER: '@JJFoodService.verifier',
  CHALLENGE: '@JJFoodService.challenge',
  AZURE_STATE: '@JJFoodService.azState',
  REFERER: '@JJFoodService.referer',
  DEVICE_ID: '@JJFoodService.deviceId',
};

export const PRODUCT_TABS = [
  {
    name: 'Ingredients',
  },
  {
    name: 'Nutrition values',
  },
  {
    name: 'Allergy advice',
  },
  {
    name: 'Usage info',
  },
  {
    name: 'Storage & size',
  },
];

export const ANALYTICS_DIMENSIONS = {
  BRANCH_CODE_DIMENSION: 'dimension1',
  FULLFILLMENT_DIMENSION: 'dimension2',
};

export const ANALYTICS_EC = {
  EC_ADD_PRODUCT: 'add_to_cart',
  EC_REMOVE_ACTION: 'remove_from_cart',
  EC_PURCHASE: 'purchase',
  EC_CHECKOUT: 'begin_checkout',
  EC_ADD_PAYMENT_INFO: 'add_payment_info',
  EC_ADD_IMPRESSION: 'addImpression',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_PROMOTION: 'view_promotion',
  VIEW_SEARCH_RESULTS: 'view_search_results',
  EC_REFUND: 'refund',
};

export const ANALYTICS_EVENT_CATEGORIES = {
  MENU: 'MENU',
  SETTINGS: 'SETTINGS',
  SHOPPING_CART: 'ShoppingCart',
  JAVASCRIPT_ERROR: 'JavaScript Error',
  NODE_ERROR: 'Node Error',
  SUBSTITUTE: 'Substitute',
  HOMEPAGE_BANNERS: 'Home Banners',
};

export const ANALYTICS_SOURCES = {
  OFFERS: 'Offers',
  RECOMMENDED: 'Recommended',
  FAVOURITES: 'Favourites',
  HOMEPAGE_OFFERS: 'Homepage offers',
  PRODUCT_DETAIL: 'Product detail',
  SEARCH: 'Search',
};

export const ANALYTICS_EVENTS = {
  CHANGED_LANGUAGE: 'CHANGED_LANGUAGE',
  ADD_PRODUCT: 'Add Product',
  REMOVE_PRODUCT: 'Remove Product',
  PURCHASE: 'Purchase',
  PURCHASE_FAILED: 'Purchase Failed',
  CONFIRM_ORDER: 'Confirm Order',
  START_PAYMENT: 'Start Payment',
  LOGIN: 'Login',
  LOGIN_ERROR: 'Login error',
  LOGIN_SUCCESS: 'Login Success',
  LOGOUT: 'Logout',
  GENERIC_BANNER_CLICK: 'Generic homepage banner click',
  BANNER_CLICK: 'Home top',
  HOME_LOWER_SMALL: 'Marketing grid (small)',
  HOME_LOWER_BIG: 'Marketing grid (big)',
  BROWSE_MENU: 'Browse menu',
  REMOVE_FAVOURITE: 'Remove favourite',
  ADD_FAVOURITE: 'Add favourite',
  SET_SETTINGS: 'Set settings',
  REMOVE_BASKET: 'Remove basket',
  SET_FULFILLMENT: 'Set fulfilment',
  ACCEPT_SUBSTITUTE: 'Accept substitute',
  SEARCH: 'Search',
  SEARCH_RESULTS: 'Search results',
  SEARCH_ALTERNATIVES: 'Search alternatives',
  RATINGS_FEEDBACK: 'Ratings feedback',
  REPORT_TYPE_CHANGE: 'Report type change',
  TRANSACTION_TYPE_CHANGE: 'Transaction type change',
  CHECKOUT_SLOT_EXPIRED: 'Checkout slot expired',
  BRAINTREE_ERROR: 'Braintree error',
  AZURE_ERROR: 'Azure B2C error',
  OWN_LABELS: 'Own label products',
  SET_PAYMENT_TYPE: 'Set payment type',
  CHECKOUT_STATUS: 'Checkout status change',
  PAYMENT_CALLBACK: 'Payment callback',
  BRANCH_LOCATOR_SEARCH: 'Branch Locator Search',
  SHOP_BY_SECTORS: 'Shop by sectors',
  WIX_SYNC_ERROR: 'WIX Sync error',
  ADYEN_EVENTS: 'Adyen activities',
  USER_INTEREST_DELIVERY_AREA_CHANGE:
    'inform when JJ starts delivering to my area',
};

export const PAYMENT_CALLBACK_CODE = {
  SUCCESS: '1',
  FAIL: '0',
};

export const PRODUCTS_PER_LINE = 4;

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

export const PROTOCOL_REGEX = '^(?:[a-z]+:)?//';
export const DATE_PICKER_REGEX = /(\d{2})-(\d{2})-(\d{4})/;
export const REGISTER_TEL_REGEX = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
export const AX_MOBILE_REGEX = /^(\+44\s?07\d{3}|\(?7\d{3}\)?)\s?\d{3}\s?\d{3}$/;
export const FILE_NAME_REGEX = /(?=\w+\.\w{3,4}$).+/;
export const ONLY_SPACES_REGEX = /^\s+$/;
export const SKU_REGEX = /[A-Z]{3}[0-9]{3}/;
export const ONLY_NUMBER_REGEX = /^-?\d+$/;
export const FASTLY_FORMAT_REGEX = /w_[0-9]{1,4},h_\d[0-9]{1,4},c_scale\//;
export const IMAGE_PRODUCT_ID_REGEX = /v[0-9]+\/products/;
export const BASE64_PRE_REGEX = /^data:image\/[a-z]+;base64,/;
export const FILE_EXT_REGEX = /\.[0-9a-z]+$/i;
export const INTEGER_REGEX = /^-?[0-9]+$/;

export const HTTPS_REGEX = /^(http|https):\/\//i;

export const INVALID_SEARCH_SUGGESTION_KEYWORD = /\b(?:\w{3}\d{3}\b(?:,|\s*)){2,}/;
export const SEARCH_SUGGESTION_MAX_LENGTH = 30;

export const GET_LOGIN_TOKEN_FAILED = 'GET_LOGIN_TOKEN_FAILED';

export const FULFILLMENT_TYPES = {
  DELIVERY: 7,
  COLLECTION: 8,
};

export const UPDATING_TYPES = {
  BASKET: 'BASKET',
  ITEM: 'ITEM',
  REMOVE: 'REMOVE',
  FULFILLMENT: 'FULFILLMENT',
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  UNPROCESSABLE: 422,
  ACCEPTED: 202,
  NEED_RETRY: 423,
  SERVER_ERROR: 500,
  EXCEED_SIZE_LIMIT: 413,
};

export const ERRORS = {
  UNAUTHORIZED: 'unauthorized',
  EXPIRED: 'invalid_token',
  NEED_AUTH: 'authentication_required',
  HTTP_SERVER_ERROR: 'http_server_error',
  SERVER_ERROR: 'server_error',
  INTERNAL_SERVER_ERROR: 'internal_server_error',
  BASKET: 'basket',
  NULL: 'null_pointer',
  SLOT_EXPIRED: 'slot_expired',
  TOTAL_DISCREPANCY: 'total_discrepancy',
  BASKET_NO_ITEMS: 'basket_no_items',
  BASKET_ONLY_OOS_ITEMS: 'basket_only_oos_items',
  INVALID_BASKET_ITEM: 'invalid_basket_item',
  UPDATE_BASKET: 'update_basket',
  PRICE_NOT_FOUND: 'price_not_found',
  UPDATE_SUBSTITUTE_ITEM: 'update_substitute_item',
  BASKET_MIN_DELIVERY_ORDER: 'basket_min_order',
  ORDER_ERROR: 'order',
  ILLEGAL_ARGUMENT: 'illegal_argument',
  FATAL_SERVER_ERROR: 'Internal Server Error',
  REMOVE_ITEM: 'error_removing_item',
  NOT_FOUND: 'Not Found',
  ORDER_FAILED: 'get order failed',
  SET_VOUCHER_AX_BASKET: 'set_voucher_ax_basket',
  EXISTING_SUBMITTED: 'existing_submitted_order',
  NOT_FOUND_404: 'Request failed with status code 404',
  INVALID_TOKEN: 'Invalid Token',
  WEB_SOCKET: 'websocket error',
  PICKED: 'picked_order_not_editable',
  NOT_EDITABLE: 'order_not_editable',
  AX_BASKET_NOT_FOUND: 'ax_basket_not_found',
  TIMEOUT: 'timeout',
  NOT_AUTHORIZED: 'not authorized',
  NON_ERROR_PROMISE: 'Non-Error promise rejection captured',
  COMMERCIAL_CARD_ERROR: 'Business card not permitted.',
  CONSUMER_CARD_ERROR: 'Consumer card not permitted.',
};

export const BASKET_ERRORS_TYPE = {
  PAYMENT_TYPE: 'payment type',
  REMOVE: 'remove',
  UPDATE: 'update',
  SLOT_EXPIRED: 'slot expired',
  SLOT_EXPIRED_DURING_CHECKOUT: 'slot expired during checkout',
  FAILED_TO_EDIT_ORDER: 'Failed to edit order, please try again later',
};

export const AX_BASKET_ERRORS = {
  STARTED_PICKING:
    'Sorry, your order can no longer be edited as the warehouse has started picking it. Please create a new order instead',
  AX_BASKET_NOT_FOUND:
    'Sorry, your order can no longer be edited as it has been cancelled.',
  NOT_EDITABLE: 'Sorry, your order can no longer be edited.',
};

export const PAYMENT_ERROR_MESSAGE = {
  GENERAL: 'Something went wrong, please try again.',
  ANOTHER_METHOD: 'Please try another payment method.',
};

export const ERROR_HTTP_STATUS = {
  NOT_FOUND: 'NOT_FOUND',
};

export const PRODUCT_FEATURES = {
  Chilled: chilled,
  Fresh: fresh,
  Frozen: frozen,
  Vegan: vegan,
  Halal: halal,
  Video: video,
  Vegetarian: vegetarian,
  MSC: msc,
  Popular: popular,
};

export const MAIN_PRODUCT_TAGS = {
  Vegetarian: vegetarian,
  Frozen: frozen,
  MSC: msc,
  Popular: popular,
};

export const HTTP_ACCEPTED = 'Accepted';

export const AUTH_ORIGIN = {
  USER_PASS: 'USER_PASS',
  ENHANCED: 'ENHANCED',
  REFRESH: 'REFRESH',
};

export const UPSELLING_STEPS = {
  0: 'targetedItems',
  1: 'forgottenItems',
  2: 'seeThoseDeals',
  3: 'flashSales',
};

export const DAYS_FROM_TODAY = {
  TODAY: 0,
  TOMORROW: 1,
};

export const REPORT_TYPES = [
  'Summary Statement',
  'Consolidated Invoice',
  'Product Analysis',
  'Product Analysis By Account',
  'Sales Transaction',
];

export const CUSTOMER_TYPES = {
  ALL: 'All',
};

export const REGISTER_CUSTOMER_TYPES = {
  CNM: 'CNM',
  OVE: 'OVE',
};

export const REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US = [
  'Google search',
  'Word of mouth',
  'Facebook',
  'Instagram',
  'Twitter',
  'LinkedIn',
  'Press & media',
  'Sales representative',
  'Printed marketing',
  'Event or exhibition',
  'JJ Blog',
];
export const REGISTER_WHERE_DID_YOU_HEAR_ABOUT_US_NA = 'N/A';

export const TRANSACTION_TYPES = {
  ALL: 'All',
  OUTSTANDING: 'Outstanding',
};

export const CAREERS_IMAGES_URL =
  'https://jjimages.global.ssl.fastly.net/jjfoodservice/careers/';

export const AX_BASKET_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  FAILURE: 'FAILURE',
  SYNCED: 'SYNCED',
  EXPIRED: 'EXPIRED',
  PICKED: 'PICKED',
  PAYMENT_AUTH_REQUIRED: 'PAYMENT_AUTH_REQUIRED',
  PENDING: 'PENDING',
  IN_PROGRESS_REPLAY: 'IN_PROGRESS_REPLAY',
};

export const AX_ERRORS = {
  QUANTITY_UNAVAILABLE: 'product_quantity_not_available',
  UNAVAILABLE: 'not_available',
  DAILY_LIMIT: 'daily_limit',
  OUT_OF_STOCK: 'out_of_stock',
  BASKET: 'ax_basket',
};

export const FORM_ERRORS = {
  REQUIRED: 'required',
  INVALID: 'invalid',
};

export const AD_ERRORS = {
  LOGIN_CANCELLED: 'AADB2C90091',
};

export const STOCK_CHECK = {
  IN_STOCK: 'IN_STOCK',
  PARTIAL_STOCK: 'PARTIAL_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  DAILY_LIMIT: 'DAILY_LIMIT',
  DISCONTINUED: 'DISCONTINUED',
  NOT_ALLOWED: 'NOT_ALLOWED',
};

export const SETTINGS = {
  INSTRUCTIONS: 'instructions',
  BRANCH: 'branchId',
  FULFILLMENT: 'fulfillment',
  PAYMENT_TYPE: 'paymentType',
  BASKET_UUID: 'basketUuid',
  EDIT_ORDER_TIME: 'editOrderTime',
  USER_SEARCH_KEYWORDS: 'srchKeywords',
  NOCONTACTINFO: 'noContactInfo',
  DELIVERY_CONTACT_NUMBER: 'deliveryContactNumber',
  BRAINTREE: 'braintree',
  RATINGS: 'showRatings',
  APPLE_PAY: 'allowApplePay',
  GOOGLE_PAY: 'allowGooglePay',
  PAYPAL: 'allowPaypal',
  ADYEN: 'adyen',
};

export const MAILCHIMP_EMAIL = 'help@foodit.com';
export const AUTHORITIES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_STAFF: 'ROLE_STAFF',
  ROLE_VENDOR: 'ROLE_VENDOR',
};

export const PAYMENT_TYPES = {
  BAC: {
    mode: 'BAC',
    title: 'CREDIT FACILITY',
  },
  BCDC: {
    mode: 'BCDC',
    title: 'BUSINESS CARD',
  },
  CAS: {
    mode: 'CAS',
    title: 'CASH',
  },
  CDC: {
    mode: 'CDC',
    title: 'CREDIT / DEBIT CARD',
  },
  CHQ: {
    mode: 'CHQ',
    title: 'CHEQUE',
  },
  COB: {
    mode: 'COB',
    title: 'CREDIT FACILITY',
  },
  COC: {
    mode: 'COC',
    title: 'CASH OR CHEQUE',
  },
  PCP: {
    mode: 'PCP',
    title: 'PURCHASE CARD PAYMENT',
  },
  PBB: {
    mode: 'PBB',
    title: 'PAY BY BANK',
  },
};

export const DEFAULT_PAYMENT_TYPE = [
  {
    mode: 'CDC',
    name: 'Credit / Debit card',
  },
];

export const PAYMENT_TYPES_MAPPING = {
  CREDIT_CARD: 'CREDIT / DEBIT CARD',
  CASH: 'CASH',
  INVOICE: 'CREDIT FACILITY',
  BAC: 'CREDIT FACILITY',
  BCDC: 'BUSINESS CARD',
  CAS: 'CASH',
  CDC: 'CREDIT / DEBIT CARD',
  CHQ: 'CHEQUE',
  COB: 'CREDIT FACILITY',
  COC: 'CASH OR CHEQUE',
  PCP: 'PURCHASE CARD PAYMENT',
  PBB: 'PAY BY BANK',
};

export const MAX_RETRY = 3;
export const MAX_ORDER_RETRY = 5;
export const MAX_SOCKET_RETRY = 5;
export const RETRY_TIMEOUT = 3000;
export const GET_ORDER_RETRY_TIMEOUT = 10000;
export const BASKET_RETRY_TIMEOUT = 3000;
export const BASKET_RECHECK_TIMEOUT = 500;
export const REACH_MAX_TIMEOUT = 1500;
export const MESSAGE_TIMEOUT = 4000;
export const TIMEOUT_TIME = 10000;
export const MESSAGE_GAP_TIMEOUT = 4000;
export const BASKET_SYNC_TIMEOUT = 10000;
export const LAST_CHECKOUT_CHECK_TIMEOUT = 10000;
export const CHECKOUT_TAKING_LONGER_TIMEOUT = 8000;
export const CHECKOUT_TAKING_LONGEST_TIMEOUT = 20000;
export const A_HOUR = 60 * 60 * 1000;
export const A_DAY = 24 * 60 * 60 * 1000;
export const NEVER = 9999999999999;
export const CHECKOUT_UPSELL_TIMEOUT = 10 * 60 * 1000;
export const MAX_BASKET_SYNC_RETRIES = 5;
export const REDIRECT_DELAY_TIME = 500;
export const DEFAULT_DEBOUNCE_TIME = 500;
export const SEARCH_SUGGESTIONS_DEBOUNCE_TIME = 250;
export const STORE_DELAY_TIME = 300;
export const RE_GET_BASKET_TIME = 30 * 1000;
export const CLOSE_MODAL_DELAY_TIME = 1000;
export const ANALYTICS_API_DELAY_TIME = 1000;
export const APP_REDIRECT_TIMEOUT = 50;
export const HIDE_ZOHO_POPUP_TIMEOUT = 15 * 1000;

export const PAGE_DES = {
  TITLE:
    'JJ Foodservice | UK Wholesale Food & Catering Supplies, Cash & Carry and Online Orders for Delivery, Collection, Export',
  TITLE_CONTENT: 'Online | Wholesale | Cash & Carry | Delivery | Export',
  DESCRIPTION:
    'We sell wholesale food and catering supplies to consumers and businesses across the UK and for export. Register on our website to order with us today.',
};

export const SEARCH_TITLE = {
  NEW: 'New Products',
};

export const ORDER_STATUSES = {
  SUBMITTED: 'Submitted',
  CREATED: 'Received',
  APPROVED: 'Received',
  ONHOLD: 'Received',
  PICKED: 'Picked',
  DISPATCHED: 'Dispatched',
  INVOICED: 'Invoiced',
  CANCELLED: 'Cancelled',
  PAYMENT_AUTHORIZED: 'Received',
  PAYMENT_AUTHORIZE_FAILED: 'Payment failed - please contact Support',
  PAYMENT_SETTLED: 'Invoiced',
  PAYMENT_SETTLE_FAILED: 'Payment failed - please contact Support',
  PAYMENT_VOIDED: 'Payment refunded',
};

export const AX_ORDER_STATUS = {
  PAYMENT_VOIDED: 'PAYMENT_VOIDED',
  PAYMENT_AUTHORIZED: 'PAYMENT_AUTHORIZED',
  CANCELLED: 'CANCELLED',
  SUCCESS: 'SUCCESS',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  DISPATCHED: 'DISPATCHED',
  SLOT_EXPIRED: 'SLOT_EXPIRED',
  EDIT_EXPIRED: 'EDIT_EXPIRED',
  TOTAL_DISCREPANCY: 'TOTAL_DISCREPANCY',
};

export const SLOT_FULFILMENT_STATUSES = {
  OK: 'OK',
  DEFAULT_SLOT: 'DEFAULT_SLOT',
  SLOT_NOT_AVAILABLE: 'SLOT_NOT_AVAILABLE',
  SLOT_ADJUSTED: 'SLOT_ADJUSTED',
  TYPE_ADJUSTED: 'TYPE_ADJUSTED',
};

export const MIN_ORDER_AMOUNT = {
  OTHERS: 79,
  'MN-MW': 79,
};

export const IMAGE_SIZE = {
  LARGE: 'w_700,h_700',
  MEDIUM: 'w_304,h_304',
  MAIN_GRID: 'w_500,h_500',
  GRID: 'w_450,h_450',
  BROCHURE: 'w_600,h_852',
  BANNER: 'w_1000,h_380',
  THUMBNAIL: 'w_80,h_80',
};

export const IMAGE_UNAVAILABLE_SIZE = {
  S: 'small',
  M: 'medium',
  L: 'large',
};

export const PASSWORD_RESET_CODE = '#passwordresetcode=';
export const ACCOUNT_ACTIVATION_CODE = '#accountactivationcode=';
export const LOGIN_ERROR_HASH = '#error=';

export const PASSWORD_STATUS = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  WARNING: 'Warning',
};

export const VOUCHER_STATUS = {
  NOT_APPLIED: 'NOT_APPLIED',
  APPLIED: 'APPLIED',
  ERROR: 'ERROR',
};

export const PREVIOUSLY_PURCHASED_PARAMS = {page: 0, size: 20};
export const FAVOURITES_PARAMS = {page: 0, size: 20};

export const CONTACT_ADMIN = {
  LOW: 'LOW',
  CRITICAL: 'CRITICAL',
};

export const POSTCODE_REGEX = /^([A-Z]{1,2}[\dA-Z]{1,2})[ ]?(\d[A-Z]{2})$/i;
export const STRING_NUM_REGEX = /[^A-Za-z0-9]/g;
export const PRODUCT_DESCRIPTION_REGEX = /\((.*)\)-/;
export const STRING_ALL_REGEX = /\s+/g;

export const PAYMENT_URL_REGEX = '/payment/(.*)/';
export const ADDRESS_REGEX = /^((?![1-9]{1,2} )[1-9]|Flat|Unit)+.*/;

export const SYSTEM_MESSAGE = {
  ANNOUNCEMENT: 'announcement-message',
  MAINTENANCE: 'maintenance-message',
};

export const FILTERS_LIMIT = 55;

export const LOCATION_FILTERS = {
  ALL: 'All',
};

export const PROMO_TAGS = {
  ONLINE_CARD: 'OnlineCard',
  Pay_By_Bank: 'PayByBank',
  FRESH_PRODUCT: 'FreshProdu',
  ADHOC: 'Adhoc',
  MULTI_BUY: 'MultiBuy',
};

export const PROMO_TAGS_MAPPING = {
  OnlineCard: 'Pay online',
  MULTI_BUY: 'MultiBuy',
};

export const PROMO_TAGS_MAP = {
  OnlineCard: 'Pay online',
};

export const CLOSED_TIME = '00:00';

export const BRANCH_NAME_MAPPING = {
  'Birmingham-Aston': 'AS-MW',
  Birmingham: 'AS-MW',
  Aston: 'AS-MW',
  Basingstoke: 'BS-MW',
  Bristol: 'BR-MW',
  'London-Dagenham': 'DG-MW',
  Dagenham: 'DG-MW',
  Doncaster: 'DN-MW',
  Leeds: 'LD-MW',
  Leicester: 'LS-MW',
  'London-Enfield': 'EN-MW',
  Enfield: 'EN-MW',
  Manchester: 'MN-MW',
  Newcastle: 'NC-MW',
  'London-Sidcup': 'SD-MW',
  Sidcup: 'SD-MW',
  England: 'EN-MW',
  england: 'EN-MW',
  Wimbeldon: 'WB-MW',
  'London-Wimbledon': 'WB-MW',
};

export const BRANCH_MAPPING = {
  'AS-MW': 'Birmingham-Aston',
  'BS-MW': 'Basingstoke',
  'BR-MW': 'Bristol',
  'DG-MW': 'London-Dagenham',
  'DN-MW': 'Doncaster',
  'LD-MW': 'Leeds',
  'LS-MW': 'Leicester',
  'EN-MW': 'London-Enfield',
  'MN-MW': 'Manchester',
  'NC-MW': 'Newcastle',
  'SD-MW': 'London-Sidcup',
  'WB-MW': 'London-Wimbledon',
};

export const SCROLL_THRESHOLD = '70%';

export const CHECKOUT_RESULT = {
  PAYMENT_HOSTED_PAGE: 'PAYMENT_HOSTED_PAGE',
  PENDING_AUTHORIZE: 'PENDING_AUTHORIZE',
  PAYMENT_BRAINTREE: 'PAYMENT_BRAINTREE',
  PAYMENT_ECOSPEND: 'PAYMENT_ECOSPEND',
  PAYMENT_ADYEN: 'PAYMENT_ADYEN',
};

export const MARKETING_PERMISSION_IDS = {
  EMAIL: '14e9781613',
  PRICE_GUIDE: '3b92f67c01',
  SPECIAL_OFFERS: '42269cad7c',
  SMS: 'a3d79ce778',
};

export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
};

export const LEAD_TIME = 120 * 1000;
export const WEBP_FORMAT = '?format=pjpg&auto=webp';

export const SEARCH_META_KEYWORDS = [
  'jj',
  'jjfood',
  'jjfoodservice',
  'groceries',
  'food',
  'ordering',
  'Online groceries',
  'Next day food and drink',
  'Next day grocery delivery',
  'Next day food and drink delivery',
  'Bulk food delivery',
  'Food online',
  'Buy bulk food',
  'Bulk groceries',
  'Home food delivery',
  'Online ordering food',
  'online food shopping',
];

export const NO_CONTACT_OPTION = 'I am self-isolating';

export const SPECIAL_PRODUCT = {
  SERVICE_ITEM: 'SER',
};

export const CONTACT_FREE = {
  DELIVERY: 'Contactless delivery: ',
  TEL: ' - Tel.: ',
};
export const TIME_ZONE = 'Europe/London';

export const MARKETING_OPTIONS_STATUS = {
  PENDING: 'pending',
  UNSUBSCRIBED: 'unsubscribed',
  SUBSCRIBED: 'subscribed',
};

export const WS = {
  ORDER_UPDATED: 'order-updated',
  BASKET_SYNC_UPDATED: 'basket-sync-updated',
  ANNOUNCEMENT_MESSAGE: 'announcement-message',
  MAINTENANCE_MESSAGE: 'maintenance-message',
  ERROR: 'error',
  CONNECT_ERROR: 'connect_error',
  CONNECT_FAILED: 'connect_failed',
  CONNECT_TIMEOUT: 'connect_timeout',
  BASKET_DELETED: 'basket-deleted',
  FORCE_RELOAD: 'force-reload',
  PROFILE_RELOAD: 'profile-settings-updated',
};

export const MAX_DRIVER_INSTRUCTION_LENGTH = 400;
export const MAX_REFERENCE_LENGTH = 100;

export const LONG_CATEGORY_LIST_FILTER_LENGTH = 90;

export const CLOUDINARY_PARAM = {
  SCALE: ',c_scale/',
  SCALE_WEBP: ',c_scale,f_webp/',
  OVERLAY_TEXT: 'l_text:',
};

export const ORDER_SOURCE = {
  AX: 'AX',
  WEB: 'WEB',
};

export const JJ_COOKIE_CONSENT = 'JJCookieConsent';

export const JJ_COOKIE_CONSENT_EXCLUDED_URLS = [
  SUCCESS_PATH,
  PAYMENT_CALLBACK_PATH,
  BRAINTREE_PATH,
];

export const CHARGE_TYPES = {
  SDD: 'SDD',
  NDD: 'NDD',
};

export const PAYMENT_FAILED_BY_CODE = {
  101: 'Payment has been declined by your bank',
  110: 'Payment has been blocked due to failed 3D secure checks',
};

export const MANUALLY_ERRORS = {
  BASKET_ONLY_OOS_ITEMS_ERROR: 'BASKET_ONLY_OOS_ITEMS_ERROR',
  BASKET_NO_ITEMS_ERROR: 'BASKET_NO_ITEMS_ERROR',
  BASKET_MIN_DELIVERY_ORDER_ERROR: 'BASKET_MIN_DELIVERY_ORDER_ERROR',
  SET_ANNOUNCEMENT_MESSAGES_FAILED: 'SET_ANNOUNCEMENT_MESSAGES_FAILED',
  SET_ANNOUNCEMENT_MESSAGES_SUCCESS: 'SET_ANNOUNCEMENT_MESSAGES_SUCCESS',
  SET_MAINTENANCE_MESSAGES_FAILED: 'SET_MAINTENANCE_MESSAGES_FAILED',
  SET_MAINTENANCE_MESSAGES_SUCCESS: 'SET_MAINTENANCE_MESSAGES_SUCCESS',
  CHECKOUT_SLOT_EXPIRED: 'CHECKOUT_SLOT_EXPIRED',
  SESSION_STORAGE_UNSUPPORTED: 'SESSION_STORAGE_UNSUPPORTED',
  AUTHORIZE_BRAINTREE_PAYMENT_FAILED: 'AUTHORIZE_BRAINTREE_PAYMENT_FAILED',
};

export const RESERVED_WORD = {
  PRIVATE: 'Private',
};

export const PAYMENT_AUTH_RESULT = {
  AUTHORIZED: 'AUTHORIZED',
  AUTHORIZE_FAILED: 'AUTHORIZE_FAILED',
  VERIFIED: 'VERIFIED',
  THREED_SECURE_REQUIRED: 'THREED_SECURE_REQUIRED',
};

export const BRAINTREE_TYPES = {
  APPLE_PAY: 'applePay',
  GOOGLE_PAY: 'googlePay',
  APPLE_PAY_CARD: 'ApplePayCard',
  ANDROID_PAY_CARD: 'AndroidPayCard',
};

export const BUTTON_NAME = {
  PAY_NOW: 'Pay Now',
  PLACE_ORDER: 'Place Order',
};

export const ORDER_TRACKING = {
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  DELAYED: 'Delayed',
  DELIVERING: 'Out for delivery',
};

export const SPLITTER = {
  LOGIN_REDIRECT: '---',
};

export const AZURE_ACCOUNT_TYPES = {
  AD: 'AD',
  B2C: 'B2C',
};

export const JJ_APOLLO = {
  URI: 'jjfoodservice://',
  ROUTES: {
    LOGIN: '/login',
    RESET_PASSWORD: '/ResetPassword',
    order: '/AllOrdersNavigator/Order',
  },
};

export const RESERVED_WORDS = {
  NOT_AVAILABLE: 'not available',
};

export const WEB_MODE = {
  CLEAN: 'clean',
};

export const MAX_AVAILABLE_QUANTITY = 300;

export const POSTCODE_LOOKUP_SUCCESS_CODE = 2000;

export const BRANCH_LOCATION_ID = {
  'AS-MW': 'ChIJBfBdHGC7cEgRE3Dapw8at9c',
  'BS-MW': 'ChIJO0rZ3ZMhdEgRWy5_uw3Un_A',
  'BR-MW': 'ChIJp0fTub6acUgRolbAZ_bzh_A',
  'DG-MW': 'ChIJAS9X-Xal2EcRrr1YZaUZKwk',
  'DN-MW': 'ChIJDcZdS-wPeUgR3SU0mFMYSPs',
  'LD-MW': 'ChIJgwEMFNRdeUgRxA7mbWLoKgY',
  'LS-MW': 'ChIJzcfs6xded0gRjgxbLzVaK6k',
  'EN-MW': 'ChIJjzVYCfMfdkgRbBa__a1W7mQ',
  'MN-MW': 'ChIJrbaN0bave0gRgkE2JhQ718c',
  'NC-MW': 'ChIJCQFsc0N3fkgRzi8yRtoiBxg',
  'SD-MW': 'ChIJhzYCQzus2EcRwGbkxZjc8vU',
};

export const GOOGLE_RATING_URL =
  'http://search.google.com/local/writereview?placeid=';
export const TRUST_PILOT_URL =
  'https://uk.trustpilot.com/evaluate/www.jjfoodservice.com';
export const FEEDBACK_URL =
  'https://form.jotformeu.com/oliturner/jjs-foodservice-survey?orderid=';

export const CANONICAL_ROUTE_MAP = {
  '/aboutus': 'about-us',
  '/aboutUs': 'about-us',
  '/about-us': 'about-us',
  '/packaging': 'packaging',
  '/food-packaging': 'packaging',
  '/takeaway-packaging': 'packaging',
  '/own-brands': 'own-brands',
  '/takeaway-packaging-wholesales': 'packaging',
  '/branch/birmingham-aston': 'branch/aston',
  '/branch/london-dagenham': 'branch/dagenham',
  '/branch/london-enfield': 'branch/enfield',
  '/branch/london-sidcup': 'branch/sidcup',
};

export const MESSAGES = {
  GET_PRODUCT_DETAILS_FAILED: 'Failed to get product details, please try again',
  COMMERCIAL_CARD_FAILED:
    'We’re sorry but you cannot use a business card for this payment method.',
  CONSUMER_CARD_FAILED:
    'We’re sorry but you cannot use a consumer card for this payment method.',
  ADYEN_PAYMENT_FAILED: 'Payment failed, please try again or use another card',
};

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
};

export const GQL_ERROR_MESSAGE = {
  UNAUTHORIZED: '401: Unauthorized',
  SERVER: '500: Internal Server Error',
};

export const URL = {
  JJS_PROD: 'www.jjfoodservice.com',
  AZURE_AUTH_DEV:
    'https://login-dev.jjfoodservice.com/526e914a-b7c1-428e-9606-99dab4f1389c/b2c_1a_jjmigration_signup_signin/oauth2/v2.0',
  AZURE_AUTH_PROD:
    'https://login.jjfoodservice.com/725c69f1-034d-41ce-a2ff-d1976381e3fe/b2c_1a_jjmigration_signup_signin/oauth2/v2.0',
  AZURE_AD:
    'https://login.microsoftonline.com/c46924d0-d32d-4006-829b-d1d39bd0f672/oauth2/v2.0',
  JJS_REG_PROD: 'register.jjfoodservice.com',
  JJS_REG_DEV: 'register-dev.jjfoodservice.com',
};

export const ID = {
  AZURE_AD_CLIENT: '2bfe81fe-2afd-4c81-a6fc-31c808cea85c',
  AZURE_CLIENT_DEV: 'beee65ca-8eed-4dd7-b621-b1ddd61e114a',
  AZURE_CLIENT_PROD: 'a622e243-89ba-4c69-b679-3061d990aa59',
};

export const CAMERA_STATE = {
  LIVE: 'live',
  VIDEO: 'video',
};

export const MISSING_ITEM = 'Missing Item';
export const QUALITY_ISSUE = 'Quality issue';

export const REFUND_REASONS = [
  QUALITY_ISSUE,
  'Damaged product',
  'Wrong item',
  'Pricing issuing',
  'Short dated / expired',
  MISSING_ITEM,
];

export const SIZE_LIMIT = {
  REQUEST_REFUND_IMAGE: 8999,
};

export const DESCRIPTIONS = {
  'advance order':
    'JJ Advance Order is a new service which allows you to access a wider range of fresh meats, poultry, fruits, and vegetables. Save time with our restaurant grade, pre-cut and pre-marinated meat and poultry. Our products are sourced to order, directly from farms and producers, to provide maximum freshness.',
};

export const ECOSPEND_STATUS = {
  VERIFIED: 'Verified',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  FAILED: 'Failed',
  REJECTED: 'Rejected',
  ABANDONED: 'Abandoned',
};

export const ECOSPEND_ERROR = {
  USER_CANCELED: 'user_canceled',
  INVALID_BANK_ID: 'invalid bank id',
};

export const PRODUCT_ORIGIN = {
  UK: 'United Kingdom',
  GB: 'Great Britain',
};

export const THE_DATE = {
  REFUND_DEV: 180,
  REFUND_PROD: 28,
};

export const COMMERCIAL_CARD = 'COMMERCIAL_CARD';

export const WIX_ERROR = {
  INVALID_ID: 'invalid id',
};

export const ADYEN_ERROR = {
  REFUSED: 'REFUSED',
  THREE_DS_FAILED: 'THREE_DS_FAILED',
  CONSUMER_CARD: 'CONSUMER_CARD',
  COMMERCIAL_CARD: 'COMMERCIAL_CARD',
};

export const ADYEN_ENV = {
  DEV: 'test',
  PROD: 'prod',
};

export const ADYEN_ANALYTICS_STATUS = {
  THREEDS_REQUIRED: '3ds required',
  STORED_PAYMENT_ERROR: 'storedPaymentMethod error',
  AUTH_PAYMENT_FAILED: 'payment auth failed',
  PAYMENT_AUTHED: 'payment authorized',
  PAYMENT_ERROR: 'process payment error',
  THREEDS_FAILED: '3ds failed',
  OTHER_PAYMENT_FAILED: 'payment failed',
};

export const MAP_CENTER_LAT_LNG = {
  lat: 51.678417,
  lng: -0.020906,
};

export const STRAPI_CMS_URL = {
  DEV: 'https://cms-dev.jjfoodservice.com',
  PROD: 'https://cms2.jjfoodservice.com',
};
