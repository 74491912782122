import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {DOTS} from '../../constants/constants';
import {Link} from './PageLink.style';

export default class PageLink extends PureComponent {
  static propTypes = {
    page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    callback: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
  };

  updateParams = value => {
    const {page, callback} = this.props;
    if (value === DOTS) {
      return;
    }
    callback({page: page - 1});
  };

  render() {
    const {page, active} = this.props;
    return (
      <Link onClick={this.updateParams} isLink={page !== DOTS} active={active}>
        {page}
      </Link>
    );
  }
}
