import React, {PureComponent} from 'react';

import {PriceText, PriceType} from '../../components/Product/Product.style';
import {Price} from './ProductDetailPage.style';

export default class MultiPrices extends PureComponent {
  render() {
    const {prices} = this.props;
    return prices
      .sort((a, b) => a.min - b.min)
      .map(price => (
        <Price>
          <PriceType>{price.min && `Buy ${price.min}+:`}</PriceType>
          <PriceText>
            {price.priceInc && `£${price.priceInc.toFixed(2)}`}
          </PriceText>
        </Price>
      ));
  }
}
