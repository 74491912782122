import styled from 'styled-components';
import {Content} from '../../styles/pages/pages';
import {media} from '../../styles/components/media-queries';

export const PageContent = styled(Content)`
  width: 100%;
  min-height: 10rem;
`;

export const List = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.textColor};
  line-height: 3rem;
  cursor: pointer;
  border-bottom: ${p => `solid 0.1rem ${p.theme.secondaryBgColor}`};
  &:hover {
    background-color: ${p => p.theme.secondaryBgColorHover};
  }
`;
