import React, {PureComponent} from 'react';
import {
  PRODUCT_FEATURES,
  MAIN_PRODUCT_TAGS,
  PRODUCT_ORIGIN,
} from '../../constants/constants';
import ukFlag from '../../images/uk.png';
import {TagImage, Tags, TagTooltip, TagWrapper} from './ProductTags.style';

export default class ProductTags extends PureComponent {
  render() {
    const {productFeatures, showMainTags, showTagText, origin} = this.props;
    const filters = showMainTags ? MAIN_PRODUCT_TAGS : PRODUCT_FEATURES;
    const renderOriginTag = (origin === PRODUCT_ORIGIN.UK ||
      origin === PRODUCT_ORIGIN.GB) && (
      <TagWrapper>
        <TagImage src={ukFlag} alt={origin} />
        <TagTooltip showTagText={true}>{origin}</TagTooltip>
      </TagWrapper>
    );
    const renderTags =
      productFeatures &&
      productFeatures.length > 0 &&
      productFeatures.map(feature => {
        if (filters[feature]) {
          return (
            <TagWrapper key={feature}>
              <TagImage src={filters[feature]} alt={feature} />
              <TagTooltip showTagText={showTagText}>{feature}</TagTooltip>
            </TagWrapper>
          );
        }
      });
    return (
      <Tags>
        {renderTags}
        {renderOriginTag}
      </Tags>
    );
  }
}
