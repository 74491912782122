import React, {PureComponent} from 'react';
import {ListDetails, SubQuantityWrapper} from './DropdownList.style';
import SubQuantity from '../Quantity/SubQuantity';
export default class AList extends PureComponent {
  state = {
    qty: this.props.quantity,
  };

  onListClick = () => {
    const {callback, list, step} = this.props;
    if (this.state.qty > 0) {
      return;
    }
    const newQty = step || 1;
    this.setState({qty: newQty});
    callback(list.id, newQty);
  };
  onListAddClick = qty => {
    const {list, callback} = this.props;
    this.setState({qty});
    callback(list.id, qty);
  };
  onListRemoveClick = () => {
    const {list, callback} = this.props;
    callback(list.id, 0);
  };
  render() {
    const {list, quantity, loading, step} = this.props;
    const {qty} = this.state;
    const isInList = quantity && quantity > 0;
    const renderInList = isInList && (
      <SubQuantityWrapper>
        <SubQuantity
          quantity={qty}
          isReverted={true}
          isShown={true}
          loading={loading}
          remove={this.onListRemoveClick}
          update={this.onListAddClick}
          step={step}
        />
      </SubQuantityWrapper>
    );
    return (
      <ListDetails onClick={this.onListClick}>
        {list.name}
        {renderInList}
      </ListDetails>
    );
  }
}
