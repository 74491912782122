import React from 'react';

import {
  ExternalInfoContent,
  ExternalLink,
  ExternalLinkContent,
  HighLight,
  TheLink,
} from '../components/Footer/Footer.style';

export const getLink = (link, index, isTableLayout, isOpenChat) => {
  const openChat = () => {
    if (
      window &&
      window.$zoho &&
      window.$zoho.salesiq &&
      window.$zoho.salesiq.chat
    ) {
      $zoho.salesiq.chat.start();
    } else if (document) {
      const chatBot = document.getElementById('zsiq_agtpic');
      if (!chatBot) {
        return;
      }
      chatBot.click();
    }
  };
  if (link.external) {
    return (
      <ExternalLink
        info={!!link.info}
        key={index}
        istablelayout={isTableLayout ? 'true' : undefined}
      >
        {link.title && (
          <HighLight
            istablelayout={isTableLayout ? 'true' : undefined}
            linebreak={link.linebreak}
          >
            {link.title}
          </HighLight>
        )}
        <ExternalLinkContent
          plain={link.plain}
          info={!!link.info}
          href={link.url}
          target={link.blank ? '_blank' : '_self'}
        >
          {link.content}
        </ExternalLinkContent>
        {link.contentExtra && (
          <ExternalLinkContent
            href={link.urlExtra}
            info={!!link.info}
            plain={link.plain}
            target={link.blank ? '_blank' : '_self'}
          >
            {link.contentExtra}
          </ExternalLinkContent>
        )}
      </ExternalLink>
    );
  }
  if (link.isOpenChat) {
    return (
      <ExternalLink key={index} onClick={openChat}>
        Live Chat
      </ExternalLink>
    );
  }
  return (
    <TheLink
      to={link.url}
      key={index}
      info={link.info ? 'true' : undefined}
      istablelayout={isTableLayout ? 'true' : undefined}
    >
      {link.title && (
        <HighLight
          istablelayout={isTableLayout ? 'true' : undefined}
          linebreak={link.linebreak}
        >
          {link.title}
        </HighLight>
      )}
      <ExternalInfoContent info={!!link.info}>
        {link.content}
      </ExternalInfoContent>
    </TheLink>
  );
};
