import React from 'react';
import {Grid, MainGrid} from './MarketingGrids.style';
import {generateNewProductsLink} from '../../helpers/data.helper';
import ImageComponent from '../Image/Image';

export const SideGrid = props => {
  const {callback, grid, isMain, isMobile, extraPadding, bannerIndex} = props;
  const onGridClick = e => {
    e && e.preventDefault();
    callback(grid.linkUrl, bannerIndex, isMain);
  };
  const mediaUrl = isMobile
    ? grid && grid.mediaMobileUrl
    : grid && grid.mediaDesktopUrl;

  if (isMain) {
    return (
      <MainGrid onClick={onGridClick} extraPadding={extraPadding}>
        <ImageComponent
          data-src={mediaUrl}
          isLazyLoad={true}
          url={mediaUrl}
          alt={grid && (grid.alt || grid.tabTitle)}
        />
      </MainGrid>
    );
  }
  return (
    <Grid
      href={grid.linkUrl}
      grid={grid}
      onClick={onGridClick}
      alt={grid && (grid.alt || grid.tabTitle)}
    >
      <ImageComponent
        data-src={mediaUrl}
        isLazyLoad={true}
        url={mediaUrl}
        alt={grid && grid.tabTitle}
      />
    </Grid>
  );
};
