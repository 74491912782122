import React, {PureComponent} from 'react';
import {Suggestion, Text} from './SearchSuggestion.style';

export default class SuggestionItem extends PureComponent {
  setIndex = () => this.props.setSuggestionIndex(this.props.index);

  render() {
    const {selected, parts, keyword, search} = this.props;

    return (
      <Suggestion
        selected={selected}
        onMouseMove={this.setIndex}
        onClick={search}
      >
        {parts.map((part, i) => (
          <Text
            key={i}
            highlight={part.toLowerCase() === keyword.toLowerCase()}
          >
            {part}
          </Text>
        ))}
      </Suggestion>
    );
  }
}
