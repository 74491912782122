import React, {Fragment, useState} from 'react';
import {Details, Block, ArrowDown, ArrowUp, Img} from './VacanciesPage.style';
import {CAREERS_IMAGES_URL} from '../../constants/constants';
import {Job} from './Job';

export const VacanciesBlock = props => {
  const {title, items, imageUrl, videoUrl} = props;
  const [collapsed, toggleCollapsed] = useState(true);
  const toggle = () => toggleCollapsed(!collapsed);
  const renderImage = imageUrl && <Img src={CAREERS_IMAGES_URL + imageUrl} />;
  // prettier-ignore
  const renderVideo = videoUrl && (
    <iframe
      width="100%"
      height="400"
      src={`${videoUrl}?rel=0&amp;controls=0&amp;showinfo=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );

  const renderItems = items.map((item, index) => (
    <Job
      key={index}
      title={item.title}
      location={item['CM:Location']}
      type={item['CM:EmploymentType']}
      link={item.link}
    />
  ));

  return (
    <Fragment>
      <Block onClick={toggle}>
        {title}
        {collapsed ? <ArrowDown /> : <ArrowUp />}
      </Block>
      <Details collapsed={collapsed}>
        {renderImage}
        {renderVideo}
        {renderItems}
      </Details>
    </Fragment>
  );
};
