import {put, call, takeLatest, select, delay, retry} from 'redux-saga/effects';
import {captureException, withScope} from '@sentry/browser';

import checkoutApi from '../api/checkout.api';
import orderApi from '../api/order.api';
import * as ActionTypes from '../actions/types.action';
import {getBearerHeader} from '../helpers/api.helper';
import {
  AX_BASKET_ERRORS,
  AX_BASKET_STATUS,
  PAYMENT_AUTH_RESULT,
  ERRORS,
  HTTP_STATUS,
  JJ_LOCAL_STORAGE,
  PAYMENT_TYPES,
  RETRY_TIMEOUT,
  URL,
  SETTINGS,
  ADYEN_ANALYTICS_STATUS,
} from '../constants/constants';
import {persistDataToLocalStorage} from '../helpers/localStorage.helper';
import {getApiConfig} from '../config/configProvider';
import {GoogleAnalyticsHelper} from '../helpers/googleAnalytics.helper';

let checkoutCounter = 0;
function* syncOrder(action) {
  try {
    const getAuth = state => state.auth;
    const getUsePbbDefaultBank = state => state.isUseDefaultPbb;
    const storeToken = yield select(getAuth);
    const usePbbDefaultBank = yield select(getUsePbbDefaultBank);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const uuid = action.basketUuid;
    const config = getApiConfig();
    const origin =
      (window && window.location && window.location.origin) || URL.JJS_PROD;
    const isEcoSpend =
      action.isEcoSpend || action.paymentType === PAYMENT_TYPES.PBB.mode;
    const ecoSpendPaymentUrl = `${origin}/paymentCallback`;
    const redirectUrl = isEcoSpend
      ? ecoSpendPaymentUrl
      : `${config.paymentRedirectUrl}/?callback=1&basketId=${uuid}`;
    const noPaymentUrl = isEcoSpend
      ? ecoSpendPaymentUrl
      : `${config.paymentRedirectUrl}/?callback=0&basketId=${uuid}`;
    const data = {
      redirectUrl,
      noPaymentUrl,
      hostedFieldsPayment: false,
      comments: '',
      braintree: action.isBrainTree,
      orderSource: action.orderSource,
      usePbbDefaultBank,
    };
    const MAX_TRY_TIME = 10;
    const results = yield retry(
      MAX_TRY_TIME,
      RETRY_TIMEOUT,
      checkoutApi.processCheckout,
      uuid,
      data,
      headers
    );
    if (results && results.orderId) {
      persistDataToLocalStorage(JJ_LOCAL_STORAGE.ORDER_ID, results.orderId);
      let validateOrder;
      while (true) {
        yield delay(RETRY_TIMEOUT);
        if (checkoutCounter > 10) {
          yield put({type: ActionTypes.SERVER_ERROR});
          break;
        }
        checkoutCounter++;
        validateOrder = yield call(orderApi.getOrder, results.orderId, headers);
        if (validateOrder) {
          if (validateOrder.axSyncedStatus === AX_BASKET_STATUS.ERROR) {
            yield put({
              type: ActionTypes.PROCESS_CHECKOUT_VALIDATION_FAILED,
              messages: validateOrder.messages,
            });
            break;
          } else {
            yield put({
              type: ActionTypes.PROCESS_CHECKOUT_SUCCESS,
              checkout: results,
            });
            persistDataToLocalStorage(
              JJ_LOCAL_STORAGE.ORDER_AX_BASKETID,
              validateOrder.axBasketId
            );
            yield put({
              type: ActionTypes.SET_ORDER_AFTER_CHECKOUT,
              order: validateOrder,
            });
            break;
          }
        }
      }
    } else {
      if (results && results.result === AX_BASKET_STATUS.FAILURE) {
        yield put({
          type: ActionTypes.BASKET_ERROR,
          message: 'failed to validate basket',
        });
      }
    }
  } catch (e) {
    const {response} = e;
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else if (e.code === ERRORS.HTTP_SERVER_ERROR) {
      yield put({type: ActionTypes.SERVER_ERROR});
    } else if (e.code === ERRORS.BASKET || e.code === ERRORS.ILLEGAL_ARGUMENT) {
      yield put({type: ActionTypes.BASKET_ERROR, message: e.message});
    } else if (e.code === ERRORS.ORDER_ERROR) {
      yield put({type: ActionTypes.ORDER_ERROR, message: e.message});
    } else if (e.message === ERRORS.NOT_FOUND_404) {
      yield put({type: ActionTypes.BASKET_NOT_FOUND});
    } else if (
      response &&
      response.data &&
      response.data.code === ERRORS.AX_BASKET_NOT_FOUND
    ) {
      if (
        response &&
        response.data &&
        response.data.params &&
        response.data.params.expiredEdit
      ) {
        yield put({
          type: ActionTypes.AX_BASKET_ALREADY_PICKED,
          message: AX_BASKET_ERRORS.STARTED_PICKING,
        });
      } else {
        yield put({type: ActionTypes.AX_BASKET_NOT_FOUND});
      }
    } else if (
      response &&
      response.data &&
      response.data.code === ERRORS.PICKED
    ) {
      yield put({
        type: ActionTypes.AX_BASKET_ALREADY_PICKED,
        message: AX_BASKET_ERRORS.STARTED_PICKING,
      });
    }
    yield put({
      type: ActionTypes.PROCESS_CHECKOUT_FAILED,
      message: e.message,
    });
  }
}

function* nonCardPayment(action) {
  try {
    const getAuth = state => state.auth;
    const storeToken = yield select(getAuth);
    const headers = getBearerHeader(action.jjToken, storeToken);
    const validateOrderStatus = yield call(
      checkoutApi.processNonCardPayment,
      action.order.orderId,
      headers
    );
    if (validateOrderStatus !== HTTP_STATUS.ACCEPTED) {
      // todo: handle failed non card payment
      yield put({
        type: ActionTypes.AUTHORIZE_NON_CARD_PAYMENT_FAILED,
      });
      return;
    }
    yield put({
      type: ActionTypes.NAVIGATE_TO_ROUTE,
      nextRoute: `/confirmation/${action.order.orderId}`,
    });
  } catch (e) {
    if (e.error === ERRORS.UNAUTHORIZED) {
      yield put({type: ActionTypes.ERROR_LOGIN_EXPIRED});
    } else if (e.error === ERRORS.EXPIRED || e.code === ERRORS.NEED_AUTH) {
      yield put({type: ActionTypes.ERROR_REQUIRE_LOGIN});
    } else if (e.code === ERRORS.HTTP_SERVER_ERROR) {
      yield put({type: ActionTypes.SERVER_ERROR});
    } else if (e.code === ERRORS.BASKET) {
      yield put({type: ActionTypes.BASKET_ERROR, message: e.message});
    } else if (e.code === ERRORS.ORDER_ERROR) {
      yield put({type: ActionTypes.ORDER_ERROR, message: e.message});
    }
    yield put({
      type: ActionTypes.AUTHORIZE_NON_CARD_PAYMENT_FAILED,
      message: e.message,
    });
    withScope(scope => {
      scope.setExtra('action', action);
      captureException(e);
    });
  }
}
function* authBraintree(action) {
  try {
    const result = yield call(
      checkoutApi.processUrlPayment,
      action.url,
      action.paymentData
    );
    if (
      result &&
      (result.status === PAYMENT_AUTH_RESULT.AUTHORIZED ||
        result.status === PAYMENT_AUTH_RESULT.VERIFIED)
    ) {
      let nextRouteUrl = `/confirmation/${action.orderId}?callback=1`;
      nextRouteUrl = action.fromApp
        ? nextRouteUrl + '&fromApp=true'
        : nextRouteUrl;
      yield put({
        type: ActionTypes.NAVIGATE_TO_ROUTE,
        nextRoute: nextRouteUrl,
      });
      yield put({
        type: ActionTypes.AUTHORIZE_BRAINTREE_PAYMENT_SUCCESS,
      });
    } else {
      yield put({
        type: ActionTypes.AUTHORIZE_BRAINTREE_PAYMENT_FAILED,
        code: (result.errors && result.errors.code) || [{code: 'FAILED'}],
      });
    }
  } catch (e) {
    yield put({
      type: ActionTypes.AUTHORIZE_BRAINTREE_PAYMENT_FAILED,
      errors: (e.response && e.response.data && e.response.data.errors) || [
        {code: 'FAILED'},
      ],
    });
    withScope(scope => {
      scope.setExtra('action', action);
      captureException(e);
    });
  }
}

function* authAdyen(action) {
  const isAdditionalDetails = action.isAdditionalDetails;
  let extraData = {type: SETTINGS.ADYEN.toUpperCase()};
  if (action.storedPaymentMethod) {
    extraData = Object.assign({}, extraData, {
      storedPaymentMethodId: action.storedPaymentMethod.storedPaymentMethodId,
    });
  } else {
    if (isAdditionalDetails) {
      extraData = Object.assign({}, extraData, {
        threeDsDetails: action.stateData.details,
      });
    } else {
      extraData = Object.assign({}, extraData, action.stateData, {
        paymentMethod: JSON.stringify(action.stateData.paymentMethod),
      });
    }
  }
  try {
    const result = yield call(
      checkoutApi.processUrlPayment,
      action.url,
      extraData
    );
    if (result) {
      if (action.storedPaymentMethod) {
        action.storedPaymentMethod.resolve();
        return;
      }
      if (result.status === PAYMENT_AUTH_RESULT.THREED_SECURE_REQUIRED) {
        GoogleAnalyticsHelper.trackAdyenPayment({
          status: ADYEN_ANALYTICS_STATUS.THREEDS_REQUIRED,
          isAdditionalDetails,
          orderId: action.orderId,
          stateData: action.stateData,
          extraData,
        });
        yield put({
          type: isAdditionalDetails
            ? ActionTypes.AUTHORIZE_ADYEN_THREEDS_SUCCESS
            : ActionTypes.AUTHORIZE_ADYEN_PAYMENT_SUCCESS,
          adyen: JSON.parse(result.redirectUrl),
        });
      } else if (
        result.status === PAYMENT_AUTH_RESULT.AUTHORIZED ||
        result.status === PAYMENT_AUTH_RESULT.VERIFIED
      ) {
        let nextRouteUrl = `/confirmation/${action.orderId}?callback=1`;
        GoogleAnalyticsHelper.trackAdyenPayment({
          status: ADYEN_ANALYTICS_STATUS.PAYMENT_AUTHED,
          orderId: action.orderId,
          stateData: action.stateData,
          extraData,
        });
        nextRouteUrl = action.fromApp
          ? nextRouteUrl + '&fromApp=true'
          : nextRouteUrl;
        yield put({
          type: ActionTypes.NAVIGATE_TO_ROUTE,
          nextRoute: nextRouteUrl,
        });
      } else {
        GoogleAnalyticsHelper.trackAdyenPayment({
          status: ADYEN_ANALYTICS_STATUS.PAYMENT_ERROR,
          orderId: action.orderId,
          stateData: action.stateData,
          extraData,
        });
        yield put({
          type: isAdditionalDetails
            ? ActionTypes.AUTHORIZE_ADYEN_THREEDS_FAILED
            : ActionTypes.AUTHORIZE_ADYEN_PAYMENT_FAILED,
          code: (result.errors && result.errors.code) || [{code: 'FAILED'}],
        });
      }
    }
  } catch (e) {
    if (action.storedPaymentMethod) {
      GoogleAnalyticsHelper.trackAdyenPayment({
        status: ADYEN_ANALYTICS_STATUS.STORED_PAYMENT_ERROR,
        orderId: action.orderId,
        stateData: action.stateData,
      });
      action.storedPaymentMethod.reject();
      return;
    }
    if (e && e.response && e.response.data && e.response.data.status) {
      const {status, redirectUrl} = e.response.data;
      if (status === PAYMENT_AUTH_RESULT.AUTHORIZE_FAILED) {
        GoogleAnalyticsHelper.trackAdyenPayment({
          status: ADYEN_ANALYTICS_STATUS.AUTH_PAYMENT_FAILED,
          orderId: action.orderId,
          stateData: action.stateData,
          errors: e.response.data,
        });
        if (redirectUrl) {
          yield put({
            type: ActionTypes.NAVIGATE_TO_ROUTE,
            nextRoute: redirectUrl,
          });
        }
      }
    }
    const errorDetails = (e.response &&
      e.response.data &&
      e.response.data.errors) ||
      (e && e.error) || [{code: 'FAILED'}];
    yield put({
      type: isAdditionalDetails
        ? ActionTypes.AUTHORIZE_ADYEN_THREEDS_FAILED
        : ActionTypes.AUTHORIZE_ADYEN_PAYMENT_FAILED,
      errors: errorDetails,
    });
    GoogleAnalyticsHelper.trackAdyenPayment({
      status: isAdditionalDetails
        ? ADYEN_ANALYTICS_STATUS.AUTH_PAYMENT_FAILED
        : ADYEN_ANALYTICS_STATUS.OTHER_PAYMENT_FAILED,
      errors: errorDetails,
      orderId: action.orderId,
      stateData: action.stateData,
    });
    withScope(scope => {
      scope.setExtra('action', action);
      captureException(e);
    });
  } finally {
    if (isAdditionalDetails) {
      window.isSubmittingAdyenAdditonal = false;
    } else {
      window.isSubmittingAdyen = false;
    }
  }
}

export default function* checkoutYield() {
  yield takeLatest(ActionTypes.PROCESS_CHECKOUT_REQUESTED, syncOrder);
  yield takeLatest(
    ActionTypes.AUTHORIZE_NON_CARD_PAYMENT_REQUESTED,
    nonCardPayment
  );
  yield takeLatest(
    ActionTypes.AUTHORIZE_BRAINTREE_PAYMENT_REQUESTED,
    authBraintree
  );
  yield takeLatest(ActionTypes.AUTHORIZE_ADYEN_PAYMENT_REQUESTED, authAdyen);
}
