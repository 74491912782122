import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {bindActionCreators} from 'redux';

import ImageComponent from '../../components/Image/Image';
import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getCustomers} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class CustomersPage extends PureComponent {
  componentDidMount() {
    const {getCustomers} = this.props;
    getCustomers();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.customers &&
      (prevProps.cms && prevProps.cms.customers) !== cms.customers
    ) {
      setCmsHeadTags(cms.customers);
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const customers = cms && cms.customers;
    const renderMarkdown = customers && customers.content && (
      <ReactMarkdown
        children={markdownConvert(customers.content)}
        className="columns-1"
      />
    );
    const renderMedia = customers && customers.media && (
      <ImageComponent url={customers.media} alt={customers.heading} />
    );
    const renderLoading = loading && loading.customers && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.customers && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{customers && customers.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
        {renderMedia}
      </MarkdownWrapper>
    );
  }
}
const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getCustomers: bindActionCreators(getCustomers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
