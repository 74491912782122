export const getVacanciesData = (vacancies, departments) => {
  let locations = ['All'];
  let categories = {};

  vacancies &&
    Object.values(vacancies).map(item => {
      if (
        item['CM:Location'] &&
        locations.indexOf(item['CM:Location'].toLowerCase()) === -1
      ) {
        locations.push(item['CM:Location'].toLowerCase());
      }
      departments.map(department => {
        department.Keywords.map(keyword => {
          if (item && item.title && item.title.indexOf(keyword) > -1) {
            if (
              department &&
              department.Title &&
              categories &&
              categories[department.Title] &&
              categories[department.Title].jobs
            ) {
              if (
                categories[department.Title] &&
                categories[department.Title].jobs &&
                categories[department.Title].jobs.filter(
                  job => job['CM:JobRef'] === item['CM:JobRef']
                ).length === 0
              ) {
                categories[department.Title].jobs.push(item);
              }
            } else {
              categories[department.Title] = {jobs: [item]};
            }
            categories[department.Title].title = department.Title;
            categories[department.Title].imageUrl = department.Img;
            categories[department.Title].videoUrl = department.Video;
          }
        });
      });
    });

  return {
    categories,
    locations,
  };
};
