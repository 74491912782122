import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {bindActionCreators} from 'redux';

import {MarkdownWrapper, SectionWrapper} from '../../styles/pages/pages';
import Loading from '../../components/Loading/Loading';
import {Error} from '../AboutUsPage/AboutUsPage.style';
import {getPayByBankFAQ} from '../../actions/cms.action';
import {setCmsHeadTags} from '../../helpers/seo.helper';
import {markdownConvert} from '../../helpers/string.helper';

class PayByBankFAQPage extends PureComponent {
  componentDidMount() {
    this.props.getPayByBankFAQ();
  }

  componentDidUpdate(prevProps) {
    const {cms} = this.props;
    if (
      cms &&
      cms.payByBankFAQ &&
      (prevProps.cms && prevProps.cms.payByBankFAQ) !== cms.payByBankFAQ
    ) {
      setCmsHeadTags(cms.payByBankFAQ);
    }
  }

  render() {
    const {cms, loading, errors} = this.props;
    const payByBankFAQ = cms && cms.payByBankFAQ;
    const renderMarkdown = payByBankFAQ && payByBankFAQ.content && (
      <ReactMarkdown
        children={markdownConvert(payByBankFAQ.content)}
        className="columns-1"
      />
    );
    const renderLoading = loading && loading.payByBankFAQ && (
      <Loading isLight={false} />
    );
    const renderError = errors && errors.payByBankFAQ && (
      <Error>Failed to load content, please try again later.</Error>
    );
    return (
      <MarkdownWrapper>
        <h1>{payByBankFAQ && payByBankFAQ.heading}</h1>
        {renderLoading}
        {renderError}
        <SectionWrapper>{renderMarkdown}</SectionWrapper>
      </MarkdownWrapper>
    );
  }
}
const mapStateToProps = state => ({
  cms: state.cms,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  getPayByBankFAQ: bindActionCreators(getPayByBankFAQ, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayByBankFAQPage);
