import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {CrossIcon} from '../../styles/components/icons';

export const Wrapper = styled.div`
  width: ${p => (p.isLoginModal ? '60rem' : '80rem')};
  overflow-y: auto;
  background-color: ${p => p.theme.primaryColor};
  color: ${p => p.theme.primaryTextColor};
  padding: 2rem;
  font-size: 1.4rem;
  ${media.small`
    width: 100%;
  `};
  ${media.smaller`
    height: 100%;
  `};
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Tab = styled.div`
  justify-content: center;
  flex-grow: 1;
  padding: 1rem 3rem;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none;
  background-color: ${p =>
    p.isActive ? p.theme.secondaryColor : p.theme.primaryColorHover};
  ${media.small`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
  `};
`;

export const Close = styled(CrossIcon)`
  display: flex;
  width: 6rem;
  background-color: ${p =>
    p.isLoggedIn ? p.theme.secondaryColor : p.theme.primaryColor};
`;
