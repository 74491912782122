export const BRAND_PATH = '/brand/:branchName/:brandName/';
export const PRODUCT_DETAIL_PATH = [
  '/product/:branchName/:itemId/',
  '/productDetail/:itemId',
];
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register_old';
export const SIGNUP_PATH = '/register';
export const SEARCH_PATH = '/search';
export const RECOMMENDED_PATH = '/recommended';
export const CATEGORY_ROOT_PATH = '/category/:category1Name';
export const CATEGORY_LEVEL1_PATH = '/category/:branchName/:category1Name';
export const CATEGORY_LEVEL2_PATH =
  '/category/:branchName/:category1Name/:category2Name';
export const CATEGORY_LEVEL3_PATH =
  '/category/:branchName/:category1Name/:category2Name/:category3Name';
export const SUCCESS_PATH = '/success';
export const SUCCESS_REGISTER_PATH = '/success-register';
export const SUCCESS_REGISTER_WELCOME_PATH =
  '/content/new-customer-registration';
export const RELOGIN_SUCCESS_PATH = '/reloginSuccess';
export const PAYMENT_CALLBACK_PATH = '/payment/:orderId/';
export const ECOSPEND_PAYMENT_PATH = '/paymentCallback';
export const BRAINTREE_PATH =
  '/braintree/:orderId/:responseUrl/:amount/:threeDS/:clientToken/';
export const TRACKING_PATH = '/order/track/';
export const TRACKING_FULL_PATH = TRACKING_PATH + ':orderId';

export const BRANCH_LOCATOR_FOR_APP = '/branchLocatorForApp/';
export const NEW_PRODUCT = '/newproduct';
