import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom-color: ${p => p.theme.secondaryTextColor};
  border-bottom-style: solid;
  border-bottom-width: ${p => (p.isBottom ? 0 : '0.1rem')};
  cursor: pointer;
  align-items: center;
  :hover {
    background-color: ${p => p.theme.secondaryLightHover};
  }
`;

export const Item = styled.div`
  text-transform: capitalize;
  position: relative;
  flex-grow: 1;
  margin: 1rem 0;
  padding-left: 0.8rem;
  font-size: 1.4rem;
  text-align: left;
  line-height: 4rem;
  display: flex;
  align-items: center;
  color: ${p =>
    p.isDisabled
      ? p.theme.dimmerBlack
      : p.isSelected
      ? p.theme.secondaryColorHover
      : p.theme.tertiaryTextColor};
  ${media.smaller`
    padding-left: 1rem;
    font-size: 1.4rem;
  `};
`;

export const ItemImage = styled.img`
  line-height: 4rem;
  width: 6rem;
  height: 4rem;
  margin: 1rem 0;
`;

export const RadioWrapper = styled.div`
  margin-top: 0.1rem;
  padding-left: 1rem;
  border-radius: 10px;
`;

export const SubRadioWrapper = styled.div`
  margin-top: 0.1rem;
  padding-left: 0.4rem;
  border-radius: 10px;
`;

export const SubPaymentOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 4rem;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
  background-color: ${p => p.theme.quaternaryBgColor};
  border-bottom: ${p => `0.1rem solid ${p.theme.secondaryTextColor}`};
  ${media.smaller`
    padding-left: 3rem;
    font-size: 1.3rem;
  `};
`;

export const BankDetail = styled(Item)`
  line-height: 3rem;
  font-size: 1.3rem;
  white-space: nowrap;
  color: ${p =>
    p.isDisabled
      ? p.theme.quaternaryColor
      : p.isChecked
      ? p.theme.pbbColor
      : p.theme.tertiaryTextColor};
  ${media.smaller`
    font-size: 1.2rem;
  `};
`;

export const FeeApplied = styled.span`
  font-size: 1.1rem;
  padding-left: 0.4rem;
  margin-top: 0.1rem;
  ${media.smaller`
    font-size: 0.9rem;
  `};
`;

export const ExtraText = styled.span`
  font-size: 1.1rem;
  padding-left: 0.8rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.smaller`
    font-size: 1rem;
    padding: 0.5rem 0 0.3rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
  `};
`;

export const Question = styled.a`
  width: 1.5rem;
  height: 1.5rem;
  text-decoration: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 1rem 1rem 0.8rem 1rem;
  border: ${p => `0.1rem solid ${p.theme.primaryColor}`};
  z-index: 2;
  ::before {
    content: '!';
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    justify-items: center;
    align-items: center;
    color: ${p => p.theme.primaryColor};
  }
  ${media.smaller`
    margin-left: 0.6rem;
  `};
`;
