import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  background-color: ${p =>
    p.isLight ? p.theme.tertiaryBgColor : p.theme.secondaryBgColor};
  color: ${props => props.theme.tertiaryTextColor};
  ${p =>
    p.isWide &&
    `width: 15rem;
    margin-right: 2rem
  `}
  ${media.small`
    min-width: 9rem;
    min-height: 3rem;
    max-height: 3rem;
  `};
  position: relative;
`;

export const SubWrapper = styled(Wrapper)`
  min-height: 3rem;
  max-height: 3rem;
  height: 3rem;
`;

export const CompactWrapper = styled(Wrapper)`
  min-height: 3rem;
  max-height: 3rem;
  height: 3rem;
  max-width: 10rem;
  background-color: transparent;
`;

export const Minus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  background-color: ${p => p.theme.primaryColor};
  cursor: pointer;
  &::before {
    font-family: 'icomoon';
    content: '\\e90c';
    font-size: 2rem;
    font-weight: normal;
    color: ${props => props.theme.primaryTextColor};
  }
  &:hover {
    background-color: ${p => p.theme.primaryColorHover};
  }
  ${media.small`
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
  `};
`;

export const Add = styled(Minus)`
  background-color: ${p => p.theme.secondaryColor};
  &::before {
    content: '\\e90d';
  }
  &:hover {
    background-color: ${p => p.theme.secondaryColorHover};
  }
`;

export const SubMinus = styled(Minus)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
`;

export const SubRemove = styled(Minus)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  background-color: ${p => p.theme.tertiaryColor};
  &:before {
    content: '\\e90d';
    transform: rotate(45deg);
  }
`;

export const SubAdd = styled(Add)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
`;

export const CompactAdd = styled(Add)`
  background-color: ${p => p.theme.primaryColor};
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  &:hover {
    background-color: ${p => p.theme.primaryColorHover};
  }
`;

export const Cancel = styled(Minus)`
  background-color: ${p => p.theme.tertiaryTextColor};
  &::before {
    content: '\\e90d';
    transform: rotate(45deg);
  }
  &:hover {
    background-color: ${p => p.theme.borderDarkerColor};
  }
`;

export const SubCancel = styled(Cancel)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
`;

export const Update = styled(Minus)`
  background-color: ${p => p.theme.secondaryColor};
  &::before {
    content: '\\2713';
  }
  &:hover {
    background-color: ${p => p.theme.secondaryColorHover};
  }
`;

export const SubUpdate = styled(Update)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
`;

export const ValueInput = styled.input`
  border: ${p => `0.2rem solid ${p.theme.secondaryColor}`};
  border-left: none;
  border-right: none;
  width: 100%;
  height: 100%;
  outline: none;
  text-align: center;
  padding-left: 1rem;
  color: ${p => p.theme.secondaryColor};
  font-weight: bold;
  ${media.small`
    padding-left: 0;
  `};
`;

export const SubValueInput = styled(ValueInput)`
  min-width: 4rem;
  height: 3rem;
`;

export const ValueSelect = styled.select`
  width: 100%;
  text-align: center;
  border: 0;
  appearance: none;
  height: 100%;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      padding-left: 25%;
    }
  }
`;

export const QuantityValue = styled.div`
  width: 100%;
  height: 100%;
  min-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CompactValue = styled(QuantityValue)`
  min-width: 3rem;
  max-width: 3rem;
  margin: 0 0.5rem;
  background-color: ${p => p.theme.secondaryBgColor};
`;

export const LoadingWrapper = styled.div`
  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export const SubLoadingWrapper = styled.div`
  margin-top: 1rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const QuantityMessage = styled.span`
  color: ${p => p.theme.tertiaryColor};
  width: 100%;
  text-align: center;
  ${media.small`
    position: absolute;
    left: 0;
    right: 0;
    background-color: ${p => p.theme.disabledColor};
    height: 3rem;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  `};
`;
