import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 8rem;
  width: 26.6rem;
  background-color: white;
  border: 1px solid ${p => p.theme.borderColor};
  ${media.medium`
    top: 6rem;
    right: 2rem;
  `};
  ${media.small`
    top: 6rem;
    right: 0;
    width: 100%;
  `};
`;

export const Suggestion = styled.div`
  padding: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${p => (p.selected ? p.theme.secondaryColor : 'none')};
  color: ${p =>
    p.selected ? p.theme.primaryTextColor : p.theme.quaternaryColor};
`;

export const Text = styled.span`
  font-weight: ${p => (p.highlight ? 'bold' : 'normal')};
  font-size: 1.4rem;
`;
