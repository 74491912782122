import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ActionButton,
  CancelOrder,
  Date,
  EditOrder,
  PaymentOrder,
  Feedback,
  Items,
  OrderId,
  OrderRow,
  OrderWrapper,
  ReOrder,
  RowTitle,
  Total,
  ButtonWrapper,
  TrackOrder,
  FulFillmentInfo,
  CannotEditYet,
  EditUsingApp,
  MessageBox,
  ErrorMsg,
  RefundErrorMsg,
  Condition,
} from './OrdersPage.style';
import {
  AX_BASKET_STATUS,
  AX_ORDER_STATUS,
  ORDER_HISTORY_DATE_FORMAT,
  ORDER_HISTORY_UNTIL_DATE_FORMAT,
  ORDER_SOURCE,
  ORDER_STATUSES,
  PAYMENT_TYPES,
  SERVICE_TYPE,
} from '../../constants/constants';
import {
  arrayToObjectValue,
  extractOrderDateTime,
  extractInvoicedDateTime,
} from '../../helpers/array.helper';
import {isServiceItem} from '../../helpers/basket.helper';
import {Loading} from '../../components/Loading/Loading';
import {LoadingWrapper} from '../BookSlotPage/BookSlotPage.style';
import TheOrderItem from './TheOrderItem';

export default class Order extends PureComponent {
  static propTypes = {
    order: PropTypes.object,
    reOrder: PropTypes.func,
    cancelOrder: PropTypes.func,
    editOrder: PropTypes.func,
    doRefund: PropTypes.func,
    isPreviousOrder: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const {alwaysOpen} = props;
    this.state = {
      detailsOpen: !!alwaysOpen,
      statusChangedAtFormatted: null,
    };
  }

  componentDidMount() {
    const {statusChangedAt, items} = this.props.order;
    this.showCaptureButton =
      !!window &&
      window.self === window.top &&
      !!navigator &&
      !!navigator.mediaDevices &&
      !!navigator.mediaDevices.getUserMedia;
    let selectedStatus = null;
    if (items) {
      selectedStatus = arrayToObjectValue(items, 'itemId', false);
    }
    this.setState({
      statusChangedAtFormatted:
        statusChangedAt &&
        moment(statusChangedAt).format(ORDER_HISTORY_DATE_FORMAT),
      selectedStatus,
    });
  }

  componentDidUpdate(prevProps) {
    const {order} = this.props;
    if (prevProps.order !== order && order && order.items) {
      const selectedStatus = arrayToObjectValue(order.items, 'itemId', false);
      this.setState({selectedStatus});
    }
  }

  openClose = () => {
    this.setState({detailsOpen: !this.state.detailsOpen});
  };

  goToReAuthOrder = () => {
    const {order, goToOrder, isOrderPage} = this.props;
    const state = !isOrderPage ? {setReauthMode: true} : null;
    goToOrder(order.axBasketId, state);
  };

  goToRefundOrder = () => {
    const {order, goToOrder, isOrderPage, toggleRefundMode} = this.props;
    if (isOrderPage) {
      toggleRefundMode();
    } else {
      if (!goToOrder) {
        return;
      }
      goToOrder(order.axBasketId, {setRefundMode: true});
    }
  };

  getBranch = (orderBranch, branchList) => {
    const currentBranch = orderBranch.split('-')[0];
    let resultBranch = '';
    if (branchList) {
      Object.keys(branchList).map(branch => {
        if (branch.indexOf(currentBranch) > -1) {
          resultBranch = branchList[branch].name;
        }
      });
    }
    return resultBranch;
  };

  doReOrder = () => {
    const {order, reOrder} = this.props;
    reOrder(order.axBasketId);
  };

  doCancelOrder = () => {
    const {order, cancelOrder} = this.props;
    cancelOrder(order.orderId);
  };

  doEditOrder = () => {
    const {order, editOrder, editing, openStopEditModal} = this.props;
    if (editing) {
      openStopEditModal();
    } else {
      editOrder(order);
    }
  };

  doReAuthOrder = () => {
    const {order, reAuthOrderPayment} = this.props;
    reAuthOrderPayment(order.orderId);
  };

  render() {
    const {
      order,
      isPreviousOrder,
      editing,
      alwaysOpen,
      loading,
      isReAuthed,
      navToProduct,
      isOrderPage,
      refundItems,
      refundErrors,
      refundErrorsDetails,
      selectedStatus,
      isRefundActive,
      doRefund,
      toggleSelect,
      setItemPhoto,
      setItemQuantity,
      setItemRefundReason,
      isRefundMode,
      isReAuthMode,
      enableRefundButton,
      isInvoiced,
      hasImageSizeError,
      toggleImageSizeError,
    } = this.props;
    const {
      orderId,
      axBasketId,
      salesId,
      statusChangedAt,
      items,
      status,
      price,
      editable,
      cutOffDateTime,
      _links,
      fulfillmentType,
      fulfillSlot,
      source,
      paymentMethod,
    } = order;
    const {statusChangedAtFormatted, detailsOpen} = this.state;
    const isDelivery = fulfillmentType === SERVICE_TYPE.DELIVERY;
    const fulfillmentStatus = isDelivery
      ? SERVICE_TYPE.DELIVERED
      : SERVICE_TYPE.COLLECTED;
    const orderStatusText =
      isPreviousOrder && status !== AX_ORDER_STATUS.CANCELLED
        ? fulfillmentStatus
        : ORDER_STATUSES[status];
    const on = statusChangedAt ? ' on ' : '';
    const orderStatus = status && `${orderStatusText}${on}`;
    const orderTotal = (price && price.total) || (order && order.invoiceTotal);
    const invoiced = !editable && _links && _links.invoice;
    const isAxOrder =
      source === ORDER_SOURCE.AX &&
      (paymentMethod === PAYMENT_TYPES.CDC.mode ||
        paymentMethod === PAYMENT_TYPES.BCDC.mode);

    const renderItems =
      items &&
      items.length > 0 &&
      items.map((item, index) => {
        const isSameDayDelivery = isServiceItem(item);
        const refundItem = refundItems && refundItems[item.itemId];
        return (
          <TheOrderItem
            isSameDayDelivery={isSameDayDelivery}
            item={item}
            key={item.itemId + (item.name || index)}
            isSelected={!!selectedStatus && !!selectedStatus[item.itemId]}
            toggleSelect={toggleSelect}
            navToProduct={navToProduct}
            isRefundMode={isRefundMode}
            showCaptureButton={this.showCaptureButton}
            setItemPhoto={setItemPhoto}
            setItemQuantity={setItemQuantity}
            setItemRefundReason={setItemRefundReason}
            refundItem={refundItem}
            refundError={refundErrors && refundErrors[item.itemId]}
            refundErrorsDetail={
              refundErrorsDetails && refundErrorsDetails[item.itemId]
            }
            toggleImageSizeError={toggleImageSizeError}
          />
        );
      });
    const editOrderTitle = editing ? 'Stop edit' : 'Edit Order';
    const renderRefundErrorMessages =
      items &&
      items.length > 0 &&
      refundErrorsDetails &&
      items.map(item => {
        if (refundErrorsDetails[item.itemId] && selectedStatus[item.itemId]) {
          const requirePhoto =
            refundErrorsDetails[item.itemId].photo && ' photo';
          const requireAnd =
            !!refundErrorsDetails[item.itemId].photo &&
            !!refundErrorsDetails[item.itemId].reason &&
            ' and ';
          const requireReason =
            refundErrorsDetails[item.itemId].reason && 'reason';
          if (requirePhoto || requireAnd || requireReason) {
            return (
              <RefundErrorMsg key={item.itemId}>
                {item.itemId}: missing {requirePhoto} {requireAnd}{' '}
                {requireReason}
              </RefundErrorMsg>
            );
          } else {
            return null;
          }
        }
      });
    const renderImageError = !!hasImageSizeError && (
      <RefundErrorMsg>
        The size limit for images is 4 MB. Please reduce the image size.
      </RefundErrorMsg>
    );
    const renderTrackOrderButton = isDelivery &&
      !isPreviousOrder &&
      !alwaysOpen &&
      order &&
      status === AX_ORDER_STATUS.DISPATCHED && (
        <TrackOrder to={`order/track/${order.axBasketId}`}>
          Track Order
        </TrackOrder>
      );
    const isDispatched = status === AX_ORDER_STATUS.DISPATCHED;
    const renderFulfillmentInfo = !isPreviousOrder && (
      <FulFillmentInfo>
        {!!fulfillSlot &&
          extractOrderDateTime(fulfillSlot, fulfillmentType, isDispatched)}
      </FulFillmentInfo>
    );
    const renderInvoicedFulfillmentInfo = isInvoiced && (
      <FulFillmentInfo>
        {order &&
          order.salesId &&
          extractInvoicedDateTime(order.invoiceDateTime, order.fulfillmentType)}
      </FulFillmentInfo>
    );

    const orderInSync = status === AX_ORDER_STATUS.SUBMITTED; // order just been placed and cannot be edited yet

    const requireReAuth =
      !isReAuthed &&
      order &&
      order.status === AX_BASKET_STATUS.PAYMENT_AUTH_REQUIRED;
    const renderReAuthInfo = requireReAuth && isReAuthMode && (
      <MessageBox>
        The payment details for your order have expired. Please confirm your
        payment to prevent your order from being cancelled. This will be the
        first payment we are taking for this order.
      </MessageBox>
    );
    const renderLoading = loading &&
      (loading.orderReAuth || loading.requestRefund) && (
        <LoadingWrapper>
          <Loading isLight={false} />
        </LoadingWrapper>
      );
    const renderConfirmText =
      loading && !loading.orderReAuth && 'Confirm Payment';
    const renderUpdatePaymentButton = requireReAuth && isReAuthMode && (
      <PaymentOrder onClick={this.doReAuthOrder}>
        {renderLoading}
        {renderConfirmText}
      </PaymentOrder>
    );
    const renderRequestRefundText =
      loading && !loading.requestRefund && 'Submit Request';
    const renderRefundButton = !!isOrderPage &&
      isRefundMode &&
      enableRefundButton && (
        <PaymentOrder onClick={doRefund} disabled={!isRefundActive}>
          {renderLoading}
          {renderRequestRefundText}
        </PaymentOrder>
      );
    const renderRefundCondition = !!isOrderPage && isRefundMode && (
      <Condition>
        Requests for refunds of fresh or frozen foods must be made within
        24-hours of receiving the goods.
      </Condition>
    );
    const renderDetails = detailsOpen && (
      <Fragment>
        <Items>{renderItems}</Items>
        {renderReAuthInfo}
        {renderRefundErrorMessages}
        {renderImageError}
        <ButtonWrapper>
          {renderUpdatePaymentButton}
          {renderRefundButton}
          {editable && !isAxOrder && !orderInSync && !alwaysOpen && (
            <EditOrder onClick={this.doEditOrder}>{editOrderTitle}</EditOrder>
          )}
          {editable && !isAxOrder && !alwaysOpen && (
            <CancelOrder disabled={editing} onClick={this.doCancelOrder}>
              Cancel Order
            </CancelOrder>
          )}
        </ButtonWrapper>
        {renderRefundCondition}
      </Fragment>
    );

    const renderDetailsButton = detailsOpen
      ? 'hide'
      : editable && !isAxOrder
      ? 'view / edit'
      : 'view';

    const cannotEditOrderText = orderInSync ? (
      <CannotEditYet>
        Your order can be edited in a few minutes. Please refresh this page.
      </CannotEditYet>
    ) : (
      'This order can no longer be edited or cancelled'
    );
    const renderPreviousOrderDescription = !isPreviousOrder &&
      !invoiced &&
      !editable && <Fragment>{cannotEditOrderText}</Fragment>;
    const renderActiveOrderDescription =
      !invoiced &&
      editable &&
      (isAxOrder ? (
        <Fragment>
          <EditUsingApp>
            App order paid by card
            <div>
              Please contact Customer Services to edit or cancel this order
            </div>
          </EditUsingApp>
        </Fragment>
      ) : (
        <Fragment>
          You can edit or cancel this order until{' '}
          {moment(cutOffDateTime).format(ORDER_HISTORY_UNTIL_DATE_FORMAT)}
        </Fragment>
      ));
    const renderReOrderLink = isPreviousOrder && !alwaysOpen && (
      <ReOrder onClick={this.doReOrder}>Reorder these items</ReOrder>
    );

    const renderOrderStatus = isPreviousOrder && (
      <RowTitle>
        {orderStatus}
        {statusChangedAtFormatted}
      </RowTitle>
    );
    const renderStatusButton = !requireReAuth && !alwaysOpen && (
      <ActionButton onClick={this.openClose}>
        {renderDetailsButton}
      </ActionButton>
    );
    const renderConfirmButton = !isOrderPage && requireReAuth && !alwaysOpen && (
      <ActionButton onClick={this.goToReAuthOrder} isError={true}>
        View
      </ActionButton>
    );
    const renderRefundInView = !requireReAuth &&
      !isReAuthMode &&
      !orderInSync &&
      enableRefundButton &&
      order &&
      ((order.price && order.price > 0) ||
        (order.invoiceTotal && order.invoiceTotal > 0)) &&
      !editable && (
        <ActionButton isShort onClick={this.goToRefundOrder}>
          Request Refund
        </ActionButton>
      );
    const renderStatusText =
      requireReAuth && !alwaysOpen && isReAuthMode ? (
        <ErrorMsg>
          Payment for order failed. Action needed to avoid cancellation!
        </ErrorMsg>
      ) : (
        <Fragment>
          {renderActiveOrderDescription}
          {renderPreviousOrderDescription}
        </Fragment>
      );
    return (
      <OrderWrapper key={orderId}>
        <OrderRow>
          <OrderId>
            <RowTitle>Order</RowTitle> {axBasketId || salesId}
          </OrderId>
          <Total>£{orderTotal && orderTotal.toFixed(2)}</Total>
          {renderStatusButton}
          {renderConfirmButton}
          {renderRefundInView}
          {renderStatusText}
          <Date>
            {renderOrderStatus}
            {renderReOrderLink}
            {renderFulfillmentInfo}
            {renderInvoicedFulfillmentInfo}
          </Date>
          {renderTrackOrderButton}
        </OrderRow>
        {renderDetails}
      </OrderWrapper>
    );
  }
}
