import styled from 'styled-components';

export const ArrowSwitch = styled.div`
  &::before {
    font-family: 'icomoon';
    display: ${p => (p.selected ? 'none' : 'block')};
    content: '\\e910';
    font-size: 3rem;
    font-weight: normal;
  }
  &::after {
    font-family: 'icomoon';
    display: ${p => (p.selected ? 'block' : 'none')};
    content: '\\e90b';
    font-size: 3rem;
    font-weight: normal;
  }
`;
