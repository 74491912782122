import React, {Fragment} from 'react';
import {
  Container,
  Content,
  Details,
  Price,
  PhotoWrapper,
  ListViewContent,
  NameWrapper,
  ButtonStyled,
  Name,
  PriceWrapper,
  Description,
  SubWrapper,
  Larger,
  ListId,
  ListViewInfo,
  ListPriceWrapper,
  Small,
} from './ListProduct.style';
import {
  LoadingWrapper,
  AddButton,
  OutOfStockButtonText,
  OutOfStockText,
  AvailableFromButtonText,
} from './Product.style';
import Loading from '../Loading/Loading';
import Quantity from '../Quantity/Quantity';
import ImageComponent from '../Image/Image';
import {getPriceUnavailableText} from '../../helpers/product.helper';
import {getAvailableAtDateString} from '../../helpers/string.helper';
import {STOCK_CHECK} from '../../constants/constants';

export const ListProduct = props => {
  const {
    product,
    currency,
    updateItem,
    deliveryPrice,
    collectionPrice,
    stockCheck,
    unitPrice,
    loading,
    loadingProps,
    addToBasket,
    goToProductDetailPage,
    remove,
    step,
    max,
    renderOfferTab,
    isOOs,
    oosText,
    isSubstituteAvailable,
    gotoAlternatives,
    renderDesireQtyButton,
    renderPromotion,
  } = props;
  const renderButtonContent = loading ? (
    <LoadingWrapper>
      <Loading isLight={true} />
    </LoadingWrapper>
  ) : (
    'Add item'
  );
  const renderAvailableButton = product.basketQuantity ? (
    <Quantity
      quantity={product.basketQuantity}
      update={updateItem}
      uuid={product.basketItemUuid}
      remove={remove}
      step={step}
      max={max}
      loading={loadingProps}
      stockCheck={stockCheck}
      unitPrice={unitPrice}
    />
  ) : (
    <AddButton
      onClick={addToBasket}
      isLoading={loading}
      data-rw="product--add-button"
    >
      {renderButtonContent}
    </AddButton>
  );

  const renderAddButton =
    !isOOs &&
    (stockCheck && stockCheck.result === STOCK_CHECK.OUT_OF_STOCK ? (
      <AddButton disabled={true}>
        !!stockCheck.availableAt ?
        {getAvailableAtDateString(stockCheck.availableAt)} : oosText
      </AddButton>
    ) : (
      renderAvailableButton
    ));

  const renderAlternativesButton = isOOs && isSubstituteAvailable && (
    <AddButton
      onClick={gotoAlternatives}
      isLoading={loading}
      data-rw="product--alternatives-button"
    >
      Alternatives
    </AddButton>
  );
  const renderOOsText = isOOs &&
    !isSubstituteAvailable &&
    !stockCheck.availableAt && (
      <OutOfStockButtonText>{oosText}</OutOfStockButtonText>
    );

  const renderAdvancedText = isOOs &&
    !isSubstituteAvailable &&
    !!stockCheck.availableAt && (
      <AvailableFromButtonText>
        {getAvailableAtDateString(stockCheck.availableAt)}
      </AvailableFromButtonText>
    );
  const renderCollectionPrice = collectionPrice && (
    <Fragment>
      <Small>Col: {currency}</Small>
      <Larger>{collectionPrice}</Larger>
    </Fragment>
  );
  const renderDeliveryPrice = deliveryPrice && (
    <Fragment>
      <Small>Del: {currency}</Small>
      <Larger>{deliveryPrice}</Larger>
    </Fragment>
  );
  const renderPrice = !(isOOs && isSubstituteAvailable) &&
    ((collectionPrice && collectionPrice > 0) ||
      (deliveryPrice && deliveryPrice > 0)) && (
      <ListPriceWrapper
        onlyOnePrice={
          !!product.offer || !product.collectionMulti || !product.deliveryMulti
        }
      >
        <Price>{renderCollectionPrice}</Price>
        <Price>{renderDeliveryPrice}</Price>
      </ListPriceWrapper>
    );
  const renderPriceMulti = !(isOOs && isSubstituteAvailable) &&
    product &&
    !product.offer &&
    (product.collectionMulti || product.deliveryMulti) && (
      <ListPriceWrapper>
        <Price>
          <Small>
            {product.collectionMulti &&
              product.collectionMulti.min &&
              `Buy ${product.collectionMulti.min}+: ${currency}`}
          </Small>
          <Larger>
            {product.collectionMulti &&
              product.collectionMulti.priceInc &&
              `${product.collectionMulti.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
        <Price>
          <Small>
            {product.deliveryMulti &&
              product.deliveryMulti.min &&
              `Buy ${product.deliveryMulti.min}+: ${currency}`}
          </Small>
          <Larger>
            {product.deliveryMulti &&
              product.deliveryMulti.priceInc &&
              `${product.deliveryMulti.priceInc.toFixed(2)}`}
          </Larger>
        </Price>
      </ListPriceWrapper>
    );
  const renderPriceUnavailable =
    !collectionPrice && !deliveryPrice && getPriceUnavailableText();
  const renderOOsPrice = isOOs &&
    isSubstituteAvailable &&
    !stockCheck.availableAt && (
      <OutOfStockText isSmallView={true}>{oosText}</OutOfStockText>
    );
  const renderAdvancePrice = isOOs &&
    isSubstituteAvailable &&
    !!stockCheck.availableAt && (
      <AvailableFromButtonText>
        {getAvailableAtDateString(stockCheck.availableAt)}
      </AvailableFromButtonText>
    );
  return (
    <Container image={product.image} href={product.url} key={product.id}>
      {renderOfferTab}
      <ListViewContent>
        <PhotoWrapper onClick={goToProductDetailPage}>
          {renderDesireQtyButton}
          <ImageComponent
            url={product.imageLinks && product.imageLinks[0]}
            alt={product.itemName}
          />
        </PhotoWrapper>
        <Content>
          <ListViewInfo onClick={goToProductDetailPage}>
            <ListId>{product && product.itemId}</ListId>
          </ListViewInfo>
          <NameWrapper onClick={goToProductDetailPage}>
            <Name>{product.itemName}</Name>
          </NameWrapper>
          <Description onClick={goToProductDetailPage}>
            {product.productDescription}
          </Description>
          <SubWrapper onClick={goToProductDetailPage}>
            {renderPromotion}
          </SubWrapper>
          <SubWrapper>
            <Details>
              <PriceWrapper>
                {renderPrice}
                {renderPriceMulti}
                {renderOOsPrice}
                {renderAdvancePrice}
                {renderPriceUnavailable}
              </PriceWrapper>
              <ButtonStyled>
                {renderAddButton}
                {renderAlternativesButton}
                {renderOOsText}
                {renderAdvancedText}
              </ButtonStyled>
            </Details>
          </SubWrapper>
        </Content>
      </ListViewContent>
    </Container>
  );
};
