import React, {PureComponent} from 'react';
import {Pic} from './Gallery.style';

export default class GalleryPic extends PureComponent {
  removePic = () => {
    const {idx, remove} = this.props;
    if (!remove) {
      return;
    }
    remove(idx);
  };
  render() {
    const {source, isSmall} = this.props;
    return <Pic src={source} onClick={this.removePic} isSmall={isSmall} />;
  }
}
