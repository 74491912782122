import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Wrapper} from '../../styles/pages/modal';
import {Button3b, Button3c} from '../../styles/components/buttons';

export const StyledWrapper = styled(Wrapper)`
  background-color: ${p =>
    p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
  width: 60rem;
`;

export const Content = styled.div`
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  align-items: center;
  display: flex;
  height: 100%;
`;

export const TextWrapper = styled.div`
  padding: 2rem;
  text-align: center;
  line-height: 2.4rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${media.smaller`
    line-height: 2rem;
    padding: 0;
  `};
`;

export const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => (p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor)};
`;

export const ErrorButton = styled(Button3c)`
  margin: 1rem;
  width: 14rem;
`;

export const OK = styled(Button3b)`
  margin: 1rem;
  width: 14rem;
  color: ${p => (p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor)};
  border-color: ${p =>
    p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: ${p => p.theme.primaryColor};
`;
