import styled from 'styled-components';

export const BlockWithArrow = styled.h3`
  margin: 0;
  background-color: ${props =>
    props.selected ? props.theme.primaryColor : props.theme.secondaryColor};
  flex-basis: content;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  color: ${props => props.theme.primaryTextColor};
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -0.8rem;
    left: 48%;
    border-top-width: 0;
  }
`;
