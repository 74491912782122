import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';
import {Wrapper} from '../../styles/pages/modal';

export const StyledWrapper = styled(Wrapper)`
  background-color: ${p =>
    p.isError ? p.theme.tertiaryAltColor : p.theme.primaryColor};
  width: 60rem;
`;

export const Content = styled.div`
  background-color: ${p => p.theme.tertiaryBgColor};
  color: ${p => p.theme.textColor};
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  text-align: center;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => p.theme.primaryColor};
  height: 4rem;
`;

export const PaymentButtonsWrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const BottomWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
`;

export const PbbLockedText = styled.div`
  padding: 0 3rem 2rem;
  font-size: 12px;
  color: ${p => p.theme.tertiaryAltColor};
`;
