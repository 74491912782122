import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  SubContent,
  Title,
  TextWrapper,
  ButtonsWrapper,
  OK,
} from './PopupModal.style';
import {FormInput} from '../../styles/components/input';

export default class RenameListModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listName: props.listName,
      error: null,
    };
  }
  handleNameChange = e => {
    this.setState({listName: e.target.value});
  };

  updateTheName = () => this.props.callback(this.state.listName);

  render() {
    const {callback, closeModalCallback} = this.props;
    const {error, listName} = this.state;

    const renderErrorMessage = !!error && (
      <SubContent>Please enter valid name'</SubContent>
    );
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={true}
        closeModal={closeModalCallback}
        data-rw="Rename list Modal"
      >
        <StyledWrapper id="rename-list" isNotFullScreen={true}>
          <Content>
            <TextWrapper>
              <Title>RENAME THE LIST</Title>
              <SubContent>Change the name of the list</SubContent>
              <FormInput
                value={listName}
                onChange={this.handleNameChange}
                maxlength="50"
              />
              <br />
              {renderErrorMessage}
              <ButtonsWrapper>
                <OK onClick={this.updateTheName} data-rw="modal--ok-button">
                  OK
                </OK>
                <OK onClick={closeModalCallback} data-rw="modal--cancel-button">
                  Cancel
                </OK>
              </ButtonsWrapper>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
