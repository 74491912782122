import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  ButtonWrapper,
  Title,
  By,
  Filters,
  Option,
  FiltersWrapper,
  ViewTypes,
  Grids,
  Lists,
} from './SearchBar.style';
import {SEARCH_SORT_TYPE} from '../../constants/constants';

const SearchBar = props => {
  const {
    numberOfProducts,
    updateSearchParams,
    sortType,
    gridView,
    toggleGridView,
    deviceInfo,
  } = props;
  const renderNumber =
    !!numberOfProducts &&
    (numberOfProducts > 0
      ? `${numberOfProducts} Products found`
      : `No products found`);

  const onSortChange = e => {
    const resetPageNumber = true;
    updateSearchParams({sortType: e.target.value}, resetPageNumber);
  };
  const viewTypes = !(deviceInfo && deviceInfo.isPhone) && (
    <ViewTypes>
      <By>View</By>
      <Grids active={gridView} onClick={toggleGridView} />
      <Lists active={!gridView} onClick={toggleGridView} />
    </ViewTypes>
  );
  return (
    <Wrapper>
      <Content>
        <Title>{renderNumber}</Title>
      </Content>
      <ButtonWrapper>
        <FiltersWrapper>
          <By>Sort</By>
          <Filters onChange={onSortChange} value={sortType}>
            <Option value={SEARCH_SORT_TYPE.RELEVANCE}>Relevance</Option>
            <Option value={SEARCH_SORT_TYPE.CATEGORIES}>Categories</Option>
            <Option value={SEARCH_SORT_TYPE.NAME}>Name</Option>
            <Option value={SEARCH_SORT_TYPE.PRICE_LOW}>
              Price: Low to High
            </Option>
            <Option value={SEARCH_SORT_TYPE.PRICE_HIGH}>
              Price: High to Low
            </Option>
          </Filters>
        </FiltersWrapper>
        {/*{viewTypes}*/}
      </ButtonWrapper>
    </Wrapper>
  );
};

SearchBar.propTypes = {
  numberOfProducts: PropTypes.number,
  size: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  gridView: PropTypes.bool.isRequired,
};

export default SearchBar;
