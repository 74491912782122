import React, {PureComponent} from 'react';
import {Star, FeedbackStar} from './Rating.style';

export default class RatingStar extends PureComponent {
  onRatingClick = () => {
    const {changeRating, index} = this.props;
    changeRating(index + 1);
  };
  render() {
    const {rated, index, isFeedback} = this.props;
    if (isFeedback) {
      return (
        <FeedbackStar
          key={index}
          index={index}
          rated={rated}
          onClick={this.onRatingClick}
        />
      );
    }
    return (
      <Star
        key={index}
        index={index}
        rated={rated}
        onClick={this.onRatingClick}
      />
    );
  }
}
