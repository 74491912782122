import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

import {media} from '../../styles/components/media-queries';
import {Button3Inverted} from '../../styles/components/buttons';
import {LoadingContainer} from '../../styles/components/layout';

export const Container = styled.div`
  width: 100%;
  min-height: 40rem;
  display: flex;
  background-color: ${props => props.theme.secondaryBgColor};
  align-items: flex-start;
  ${media.medium`
    margin-top: 0;
  `};
`;

export const BoughtTogether = styled.h4`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: normal;
  padding: 1.4rem;
  text-align: center;
  margin: 0.8rem;
  color: ${p => p.theme.primaryColor};
  background-color: ${p => p.theme.tertiaryBgColor};
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.theme.secondaryBgColor};
  justify-content: space-evenly;
  align-items: flex-start;
  ${media.smaller`
    > div {
      margin: 0.5rem 0.7rem;
    }
  `};
`;

export const UnavailableText = styled.div`
  color: ${props => props.theme.quaternaryColor};
`;

export const Back = styled.span`
  cursor: pointer;
  color: ${props => props.theme.secondaryColor};
  margin-right: 0.4rem;
`;

export const BreadCrumb = styled.div`
  margin-top: 2.4rem;
  display: flex;
  width: 100%;
  height: 4rem;
  background-color: ${props => props.theme.secondaryBgColor};
  font-size: 1.2rem;
  padding: 2rem;
  color: ${props => props.theme.primaryColor};
  ${media.small`
    height: auto;
    padding: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  `};
`;

export const BreadCrumbLinks = styled(Link)`
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding-right: 0.4rem;
  line-height: 2rem;
  color: ${props => props.theme.primaryColor};

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`;

export const CurrentPage = styled.span`
  font-weight: bold;
`;

export const LoadingWrapper = styled(LoadingContainer)`
  min-height: 20rem;
`;

export const LoadingButtonWrapper = styled.div`
  width: 100%;
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.4rem;
  padding: 0;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.secondaryBgColor};
`;

export const Details = styled.div`
  display: flex;
  padding: 1rem;
  ${media.small`
    display: block;
  `};
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${media.small`
    padding: 0;
    align-items: center;
  `};
`;

export const SubMediaWrapper = styled.div`
  display: flex;
  max-width: 36rem;
  flex-wrap: wrap;
  margin: 1rem 0;
`;

export const SubImage = styled.div`
  width: 8rem;
  height: 8rem;
  max-width: 8rem;
  margin: 0 1rem 1rem 0;
  ${media.smaller`
    width: 7.8rem;
    height: 7.8rem;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  flex-grow: 1;
  ${media.small`
    padding: 1rem;
  `};
`;

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: normal;
  margin: 0;
  color: ${p => p.theme.secondaryColor};
  padding-bottom: 1rem;
`;

export const Id = styled.h2`
  font-weight: bold;
  text-transform: uppercase;
  color: ${p => p.theme.primaryColor};
`;

export const Description = styled.dd`
  font-size: 1.4rem;
  margin: 0;
  color: ${p => p.theme.tertiaryTextColor};
`;

export const PricesWrapper = styled.div`
  padding-top: 2rem;
  font-size: 1.4rem;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.smaller`
    padding: 0;
  `};
`;

export const PriceWrapper = styled.div`
  display: flex;
  ${media.smaller`
    justify-content: space-around;
  `};
`;

export const PriceWholeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const VAT = styled.div`
  font-size: 1.4rem;
  margin-top: 0.5rem;
`;

export const Price = styled.span`
  display: flex;
  flex-direction: column;
  min-width: 13rem;
  padding-bottom: 1.5rem;
`;

export const UnitPrice = styled.div`
  text-align: right;
  padding: 1rem 0;
  color: ${p => (p.hasOffer ? p.theme.tertiaryColor : p.theme.textColor)};
`;

export const Figure = styled.span`
  font-weight: bold;
  padding-left: 0.4rem;
`;

export const AddButton = styled(Button3Inverted)`
  width: 18rem;
  margin-top: 1rem;
  ${media.small`
     width: 100%
  `};
`;

export const InfoWrapper = styled.div`
  width: 100%;
  padding: 2rem 1.8rem;
  ${media.small`
    padding: 0;
  `};
`;

export const PromotionWrapper = styled.div`
  ${media.small`
    padding-bottom: 2rem;
  `};
`;

export const InfoContent = styled.div`
  padding: 2rem;
  margin: 0;
`;

export const BlockWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  ${media.small`
     flex-direction: column;
  `};
`;

export const QuantityStyled = styled.div`
  max-width: 18rem;
  margin-top: 1rem;
  ${media.small`
    max-width: 100%;
    height: 4rem;
    > div {
      height: 4rem;
      min-height: 4rem;
      > div {
        height: 4rem;
        min-width: 4rem;
        min-height: 4rem;
      }
    }
  `};
`;

export const Disclaimer = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.secondaryBgColor};
`;

export const SmallPrint = styled.p`
  padding: 1rem 1rem 0;
`;

export const SmallPrintTitle = styled.h2`
  margin: 0 1rem;
  padding: 1rem 0;
  font-size: 1.2rem;
`;

export const Email = styled.a`
  padding-left: 0.2rem;
  text-decoration: none;
  color: ${props => props.theme.primaryColor};
`;

export const InfoColumn = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const InfoColumnBold = styled(InfoColumn)`
  font-weight: bold;
`;

export const InfoTitle = styled.dd`
  display: flex;
  text-transform: capitalize;
  padding: 0.5rem;
  margin: 0;
  font-size: 1.4rem;
  flex-basis: 50%;
  line-height: 2.4rem;
`;

export const NotFound = styled.div`
  padding: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.textColor};
`;

export const InfoText = styled(InfoTitle)`
  display: block;
`;

export const InfoDescription = styled(InfoTitle)`
  flex-grow: 1;
  text-transform: none;
`;

export const TagsWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
`;

const mediaCss = css`
  width: 35rem;
  min-width: 35rem;
  height: 35rem;
  background-size: cover;
  cursor: ${p => (p.hasSubImages ? 'pointer' : 'initial')};
  ${media.small`
    max-width: 100%;
  `};
  ${media.smallest`
    width: 100%;
  `};
`;

export const MediaWrapper = styled.div`
  img {
    ${mediaCss}
  }
`;

export const VideoWrapper = styled.div`
  ${mediaCss}
  .ytp-upnext .ytp-player-content .ytp-suggestion-set {
    display: none;
  }
`;

export const BrandLink = styled(Link)`
  color: ${p => p.theme.primaryColor};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.medium`
      flex-direction: column;
  `};
`;

export const MultiPriceColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
