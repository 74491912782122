import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {setCurrentRoute} from '../../actions';
import {setPrevRoute} from '../../actions/prevRoute.action';
import {getShoppingList} from '../../actions/shoppingList.action';
import {ROUTES} from '../../constants/constants';
import {
  BasketButton,
  BasketItemsWrapper,
  BasketPageContent,
  LoadingWrapper,
} from '../BasketPage/BasketPage.style';
import {ExtraWrapper} from '../../styles/components/wrapper';
import {
  Wrapper,
  Title,
  ButtonWrapper,
  LinkButton,
  Head,
  ButtonSubWrapper,
} from '../../styles/pages/pages';
import {PageContent} from './ShoppingListsPage.style';
import Loading from '../../components/Loading/Loading';
import ListBar from './ListBar';

class ShoppingListsPage extends PureComponent {
  componentDidMount() {
    const {auth, shoppingLists, getShoppingList, setCurrentRoute} = this.props;
    setCurrentRoute(ROUTES.SHOPPING_LISTS);
    if (!this.gotShoppingLists && !!auth && !shoppingLists) {
      this.gotShoppingLists = true;
      getShoppingList();
    }
  }
  componentDidUpdate(prevProps) {
    const {auth, prevRoute, shoppingLists, getShoppingList} = this.props;
    if (
      !this.gotShoppingLists &&
      auth &&
      (!shoppingLists ||
        (prevRoute !== ROUTES.SHOPPING_LISTS_CONTENT && shoppingLists))
    ) {
      this.gotShoppingLists = true;
      getShoppingList();
    }
  }

  navToList = (listId, listName) => {
    this.props.history.push({
      pathname: `/shoppingList/${listId}/${encodeURIComponent(listName)}/`,
    });
  };

  render() {
    const {shoppingLists, loading} = this.props;
    const renderLoading = loading && loading.getShoppingLists && (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderShoppingLists =
      shoppingLists &&
      shoppingLists.length > 0 &&
      shoppingLists.map(list => (
        <ListBar key={list.id} list={list} navToList={this.navToList} />
      ));
    return (
      <Wrapper>
        <Head>
          <Title>My Shopping Lists</Title>
          <ButtonWrapper>
            <ButtonSubWrapper>
              <BasketButton to="/">Continue shopping</BasketButton>
            </ButtonSubWrapper>
          </ButtonWrapper>
        </Head>
        <PageContent>
          {renderLoading}
          {renderShoppingLists}
        </PageContent>
        <ExtraWrapper>
          <BasketButton to="/">Continue shopping</BasketButton>
        </ExtraWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  shoppingLists: state.shoppingLists,
  auth: state.auth,
  loading: state.loading,
  branch: state.branch,
  prevRoute: state.prevRoute,
});

const mapDispatchToProps = dispatch => ({
  getShoppingList: bindActionCreators(getShoppingList, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListsPage);
