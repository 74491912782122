import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  Title,
  CameraFrame,
  NarrowButton,
  CanvasFrame,
  StandardWrapper,
  ButtonsWrapper,
  BottomWrapper,
  TopWrapper,
} from './PopupModal.style';
import {CAMERA_STATE} from '../../constants/constants';
import theme from '../../styles/theme';

export default class CaptureModal extends PureComponent {
  constructor(props) {
    super(props);
    this.streaming = false;
    this.width = 320;
    this.height = 0;
    this.cameraRef = null;
    this.canvasRef = null;
    this.photoRef = null;
    this.state = {
      gallery: [],
      capturedPhoto: null,
    };
  }
  async componentDidMount() {
    await this.getMedia();
  }

  stopCamera = () => {
    if (
      this.cameraRef &&
      this.cameraRef.srcObject &&
      this.cameraRef.srcObject.getTracks
    ) {
      this.cameraRef.srcObject.getTracks().forEach(track => {
        if (
          track.readyState === CAMERA_STATE.LIVE &&
          track.kind === CAMERA_STATE.VIDEO
        ) {
          track.stop();
        }
      });
    }
  };

  closeModal = () => {
    this.stopCamera();
    this.props.closeModalCallback();
  };

  getMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      if (stream) {
        this.cameraRef.srcObject = stream;
        this.cameraRef.play();
      }
    } catch (err) {
      this.stopCamera();
    }
  };
  setRef = node => {
    this.cameraRef = node;
    if (!this.cameraRef) {
      return;
    }
    this.cameraRef.addEventListener(
      'canplay',
      ev => {
        if (!this.streaming) {
          this.height =
            this.cameraRef.videoHeight /
            (this.cameraRef.videoWidth / this.width);
          if (isNaN(this.height)) {
            this.height = this.width / (4 / 3);
          }
          this.cameraRef.setAttribute('width', this.width);
          this.cameraRef.setAttribute('height', this.height);
          this.canvasRef.setAttribute('width', this.width);
          this.canvasRef.setAttribute('height', this.height);
          this.streaming = true;
        }
      },
      false
    );
  };
  setCanvasRef = node => (this.canvasRef = node);
  setPhotoRef = node => (this.photoRef = node);
  clearGallery = () => {
    const context = this.canvasRef.getContext('2d');
    context.fillStyle = theme.borderColor;
    context.fillRect(0, 0, this.canvasRef.width, this.canvasRef.height);
    const data = this.canvasRef.toDataURL('image/png');
    this.photoRef.setAttribute('src', data);
    this.setState({capturedPhoto: null});
  };
  capturePhoto = e => {
    e.preventDefault();
    const context = this.canvasRef.getContext('2d');
    if (this.width && this.height) {
      this.canvasRef.width = this.width;
      this.canvasRef.height = this.height;
      context.drawImage(this.cameraRef, 0, 0, this.width, this.height);

      const data = this.canvasRef.toDataURL('image/png');
      this.photoRef.setAttribute('src', data);
      this.setState({capturedPhoto: data});
    } else {
      this.clearGallery();
    }
  };

  submitPhoto = () => {
    this.props.setSavePhoto(this.state.capturedPhoto);
    this.closeModal();
  };

  render() {
    const {showModal} = this.props;
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={this.closeModal}
        data-rw={'capture-modal'}
      >
        <StyledWrapper>
          <Content maxHeight={true}>
            <StandardWrapper>
              <TopWrapper>
                <Title>Take photo before refund</Title>
                <CameraFrame ref={this.setRef}>
                  Video stream not available.
                </CameraFrame>
                <CanvasFrame ref={this.setCanvasRef}>
                  <img
                    ref={this.setPhotoRef}
                    alt="The captured photo will appear here."
                  />
                </CanvasFrame>
              </TopWrapper>
              <BottomWrapper>
                <ButtonsWrapper>
                  <NarrowButton onClick={this.capturePhoto}>
                    Capture
                  </NarrowButton>
                  <NarrowButton onClick={this.clearGallery}>Reset</NarrowButton>
                  <NarrowButton onClick={this.submitPhoto}>Submit</NarrowButton>
                  <NarrowButton onClick={this.closeModal}>Cancel</NarrowButton>
                </ButtonsWrapper>
              </BottomWrapper>
            </StandardWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
