import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {media} from '../../styles/components/media-queries';
import {Content} from '../../styles/pages/pages';

export const BlogCategoryBlock = styled(Link)`
  display: flex;
  width: 28rem;
  height: 28rem;
  min-width: 28rem;
  background-image: ${p => (!!p.bgImg ? `url(${p.bgImg})` : 'none')};
  background-size: cover;
  margin: 1rem;
  position: relative;
  cursor: pointer;
`;

export const Head = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${media.smaller`
    flex-direction: column;
  `};
`;

export const CategoryBlocks = styled(Content)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  max-width: 104rem;
  padding: 2rem 0;
  position: relative;
  border: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 104rem;
  padding: 2rem 0;
  position: relative;
  ${media.smaller`
    margin: 0;
  `};
`;

export const PostNode = styled.div`
  padding: 2rem 4rem;
  max-width: 100%;
  ${media.smaller`
    padding: 0 2rem;
  `};
`;

export const MainTitle = styled.div`
  font-size: 3.6rem;
  font-weight: lighter;
  margin: 2rem 0;
  line-height: 4rem;
  color: ${p => p.theme.secondaryColor};
`;

export const CategoryTitle = styled.div`
  width: 100%;
  height: 3.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 2rem;
  padding: 1rem;
  text-align: center;
`;

export const TitleText = styled.span`
  color: ${p => p.theme.primaryTextColor};
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  text-transform: uppercase;
  z-index: 99;
  position: relative;
`;

export const BackgroundBox = styled.div`
  background-color: ${p => p.theme.secondaryColor};
  width: 100%;
  height: 100%;
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PostBlock = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  width: 28rem;
  min-width: 28rem;
  margin: 2rem 0;
`;

export const PostBlockImg = styled.div`
  display: flex;
  width: 28rem;
  height: 16rem;
  min-width: 28rem;
  background-image: ${p => (!!p.bgImg ? `url(${p.bgImg})` : 'none')};
  background-size: cover;
  position: relative;
  cursor: pointer;
`;

export const PostBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  color: ${props => props.theme.textColor};
`;

export const LastModify = styled.div`
  padding: 1rem 1rem 0 2rem;
  font-size: 1.1rem;
  color: ${props => props.theme.textColor};
`;

export const BlogError = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.tertiaryAltColor};
`;
export const PostTitle = styled.span`
  max-width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  vertical-align: top;
  color: ${props => props.theme.quaternaryColor};
  min-height: 4.8rem;
`;

export const BreadCrumb = styled.nav`
  width: 100%;
  min-height: 4rem;
  font-size: 1.2rem;
  padding: 2rem 4rem 0;
  color: ${props => props.theme.primaryColor};
  ${media.smaller`
    margin-top: 1rem;
    padding: 1rem 2rem 1rem;
    line-height: 1.6rem;
  `};
`;

export const LatestPost = styled(Link)`
  max-width: 100%;
  padding: 2rem 4rem;
  text-align: center;
`;

export const PostImage = styled.img`
  max-width: 100%;
  cursor: pointer;
`;

export const BlogTitle = styled.div`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: lighter;
  color: ${p => p.theme.secondaryColor};
  padding: 1rem 2rem;
  text-align: left;
  cursor: pointer;
`;

export const Excerpt = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0 2rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

export const BlogSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin: 6rem 0 0 0;
`;
export const BlogSearch = styled.input`
  font-size: 1.4rem;
  color: ${props => props.theme.tertiaryTextColor};
  padding: 2rem;
  background-color: transparent;
  border: solid 0.1rem ${props => props.theme.borderColor};
  outline: none;
  height: 3.8rem;
  width: 100%;
`;
export const BlogSearchButton = styled.button`
  font-size: 1.4rem;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.primaryTextColor};
  padding: 1rem 2rem;
  border: 0;
  width: 100px;
  height: 42px;
`;

export const Comments = styled.div`
  margin: 2rem auto;
`;
export const CommentWrapper = styled.div`
  padding: 2rem 3rem;
  margin: 0 0 1rem 0;
`;
export const CommentUser = styled.div`
  font-size: 1.6rem;
  line-height: 3.2rem;
  font-weight: lighter;
  color: ${p => p.theme.secondaryColor};
  text-align: left;
`;
export const CommentDate = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 1rem;
  line-height: 2.4rem;
  width: 100%;
  text-align: left;
`;
export const CommentText = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  text-align: left;
`;

export const RelatedPosts = styled.div``;
export const RelatedPostWrapper = styled.div`
  padding: 2rem 4rem;
`;
export const RelatedPostTitle = styled(Link)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: lighter;
  color: ${p => p.theme.secondaryColor};
  text-align: left;
  cursor: pointer;
`;
export const RelatedPostExcerpt = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;
