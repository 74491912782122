import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Wrapper, Text, Previous, Next} from './Pagination.style';
import {generatePagination} from '../../helpers/pagination.helper';
import PageLink from './PageLink';

export default class Pagination extends PureComponent {
  static propTypes = {
    pages: PropTypes.object.isRequired,
    updateSearchParams: PropTypes.func.isRequired,
  };

  goToPreviousPage = () => {
    const {updateSearchParams, pages} = this.props;
    if (pages.number >= 1) {
      updateSearchParams({page: pages.number - 1});
    }
  };

  goToNextPage = () => {
    const {updateSearchParams, pages} = this.props;
    const {totalPages, number} = pages;
    if (number + 1 < totalPages) {
      updateSearchParams({page: number + 1});
    }
  };

  render() {
    const {pages, updateSearchParams} = this.props;
    const {totalPages, number} = pages;
    if (totalPages <= 1) {
      return null;
    }
    const pagesArray = generatePagination(totalPages, number + 1);

    const renderPages =
      pagesArray &&
      pagesArray.map(page => (
        <PageLink
          key={page}
          active={number === page - 1}
          page={page}
          callback={updateSearchParams}
        />
      ));
    return (
      <Wrapper>
        <Text>Page</Text>
        <Previous disabled={number === 0} onClick={this.goToPreviousPage} />
        {renderPages}
        <PageLink
          active={number === totalPages - 1}
          page={totalPages}
          callback={updateSearchParams}
        />
        <Next
          disabled={number === totalPages - 1}
          onClick={this.goToNextPage}
        />
      </Wrapper>
    );
  }
}
