import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Wrapper, Head, Title, Container} from '../../styles/pages/pages';
import {getFavourites} from '../../actions/favourite.action';
import Product from '../../components/Product/Product';
import Loading from '../../components/Loading/Loading';
import {
  NoItemsAvailable,
  NoFavouritesText,
  FavIconWrapper,
  FavoritedIcon,
} from './FavouritesPage.style';
import {syncBasketQuantityWithProducts} from '../../helpers/product.helper';
import {
  ANALYTICS_SOURCES,
  FAVOURITES_PARAMS,
  ROUTES,
  SCROLL_THRESHOLD,
} from '../../constants/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import {setCurrentRoute} from '../../actions';
import ToTheTop from '../../components/ToTheTop/ToTheTop';
import {setPrevRoute} from '../../actions/prevRoute.action';

class FavouritesPage extends PureComponent {
  state = {
    params: FAVOURITES_PARAMS,
  };
  componentDidMount() {
    const {getFavourites, setCurrentRoute, prevRoute, history} = this.props;
    const isNewSearch =
      history.location &&
      history.location.state &&
      history.location.state.newSearch;
    setCurrentRoute(ROUTES.FAVOURITES);
    if (prevRoute === ROUTES.PRODUCT && !isNewSearch) {
      return;
    }
    getFavourites(this.state.params.page, this.state.params);
  }

  componentDidUpdate(prevProps) {
    const {auth, getFavourites, branch} = this.props;
    if (auth && auth !== prevProps.auth) {
      getFavourites(this.state.params.page, this.state.params);
    }
  }

  loadMore = () => {
    const {getFavourites, favourites} = this.props;
    const {params} = this.state;
    getFavourites(favourites.page.number + 1, params);
  };

  render() {
    const {
      basket,
      favourites,
      loading,
      branch,
      updateBasket,
      updateItem,
      removeItem,
      basketHashMap,
      deviceInfo,
      auth,
      onProductClick,
      keywordSearch,
      setPrevRoute,
    } = this.props;
    const navToProduct = itemId => {
      setPrevRoute(ROUTES.FAVOURITES);
      onProductClick(itemId);
    };
    const favouritedItems =
      (favourites && favourites._embedded && favourites._embedded.products) ||
      [];
    const favouriteItems = syncBasketQuantityWithProducts(
      basketHashMap,
      favouritedItems
    );
    const notLoginText =
      !auth &&
      loading &&
      !loading.gettingProducts &&
      'Log in to select and view favourites';
    const noFavouritesText = auth && loading && !loading.gettingProducts && (
      <NoFavouritesText>
        To add products to your Favourites list just click on the heart symbol
        <FavIconWrapper>
          <FavoritedIcon />
        </FavIconWrapper>
        on any products
      </NoFavouritesText>
    );
    const renderFavourites =
      favouriteItems && favouriteItems.length > 0 ? (
        favouriteItems.map((product, index) => (
          <Product
            product={product}
            branch={branch}
            key={product.itemId + index}
            onProductClick={navToProduct}
            loading={loading}
            basketHashMap={basketHashMap}
            updateBasket={updateBasket}
            updateItem={updateItem}
            removeItem={removeItem}
            fulfillmentType={basket && basket.fulfillmentType}
            deviceInfo={deviceInfo}
            source={ANALYTICS_SOURCES.FAVOURITES}
            keywordSearch={keywordSearch}
          />
        ))
      ) : (
        <NoItemsAvailable>
          {notLoginText}
          {noFavouritesText}
        </NoItemsAvailable>
      );
    const hasMore =
      favourites &&
      favourites.page &&
      favourites.page.number < favourites.page.totalPages - 1;

    const renderLoading = loading.gettingProducts &&
      favouriteItems.length === 0 && <Loading isLight={false} />;

    const renderGoTop = favourites &&
      favourites.page &&
      favourites.page.number > 0 && <ToTheTop />;

    const renderContent = (
      <InfiniteScroll
        next={this.loadMore}
        hasMore={hasMore}
        dataLength={favouriteItems.length}
        scrollThreshold={SCROLL_THRESHOLD}
        loader={<Loading isLight={false} />}
      >
        {renderFavourites}
      </InfiniteScroll>
    );

    return (
      <Wrapper>
        <Head>
          <Title>My Favourites</Title>
        </Head>
        <Container>
          {renderContent}
          {renderLoading}
          {renderGoTop}
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  favourites: state.favourites,
  auth: state.auth,
  loading: state.loading,
  branch: state.branch,
  prevRoute: state.prevRoute,
});

const mapDispatchToProps = dispatch => ({
  getFavourites: bindActionCreators(getFavourites, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setPrevRoute: bindActionCreators(setPrevRoute, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesPage);
