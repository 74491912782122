import styled from 'styled-components';
import {media} from '../../styles/components/media-queries';

export const PromoText = styled.span`
  font-size: 1.4rem;
  display: ${p => (p.isOneLine ? 'inline' : 'block')};
  text-align: left;
  text-transform: ${p => (p.isOneLine ? 'inherit' : 'uppercase')};
  min-height: 1.4rem;
  font-weight: bold;
  color: ${p => p.theme.tertiaryColor};
  ${media.medium`
    font-size: 1.1rem;
  `};
  ${media.small`
    font-size: 1rem;
  `};
`;

export const TextWrapper = styled.div`
  min-width: ${p => (p.isOneLine ? 'auto' : '50%')};
  color: ${p => p.theme.tertiaryColor};
  text-align: center;
  display: ${p => (p.isListMode ? 'flex' : 'block')};
  padding-left: 1rem;

  &:first-child {
    padding-left: 0;
  }

  flex-direction: column;
  ${media.medium`
    padding-left: 0.5rem;
  `};
`;

export const PromoWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: ${p => (p.isOneLine ? 'auto' : '5rem')};
  align-items: center;
  justify-content: ${p =>
    p.isListMode || p.alignLeft ? 'flex-start' : 'center'};
  ${media.medium`
    font-size: 1rem;
  `};
`;

export const PromoSmallText = styled.div`
  padding-top: 0.3rem;
  color: ${p =>
    p?.hasOffer ? p.theme.tertiaryColor : p.theme.tertiaryTextColor};
  font-size: 1.2rem;
  text-align: left;
  ${media.medium`
    font-size: 1.1rem;
  `};
`;
export const PromoInfoText = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.4rem;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 1.4rem;
  color: ${p => p.theme.tertiaryColor};
  ${media.small`
    font-size: 1.2rem;
  `};
`;

export const PbbImg = styled.img`
  width: 4rem;
  height: 2.2rem;
  margin-top: 0.1rem;
  margin-right: 0.2rem;
`;

export const BubbleOffer = styled.div`
  min-width: 5rem;
  max-width: 5rem;
  min-height: 5rem;
  max-height: 5rem;
  border-radius: 50%;
  font-size: 1.2rem;
  background-color: ${p =>
    p.hasOffer ? p.theme.tertiaryColor : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.primaryTextColor};
  font-weight: bold;
  ${media.medium`
    font-size: 1.1rem;
  `};
`;

export const MultiBuy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  margin-top: -0.5rem;
`;

export const MultiBuyText = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.2rem 0;
`;

export const MultiBuyPrice = styled.div`
  font-size: 1.1rem;
  margin-top: -0.1rem;
`;

export const PromoMin = styled.span`
  font-size: 1.4rem;
  padding-left: 0.2rem;
`;

export const PromoPlus = styled.span`
  font-size: 1.3rem;
  padding-left: 0.1rem;
  line-height: 1.6rem;
`;

export const Collection = styled.div`
  font-size: 0.6rem;
  text-transform: uppercase;
`;
